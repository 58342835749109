<template>
  <div>
    <div v-if="!listPrint" v-bind:class="pageClass">
      <div :key="index" v-for="(item, index) in arr">
        <div :class="[calculateTransition(index), 'subPage']">
          <div class="topHeading">
            <h3>COMPARE & SAVE</h3>
          </div>
          <div class="articlesDiv">
            <div class="spencersArea">
              <div class="descriptionSpencers">
                {{ item.description }}
              </div>
              <div class="mrpSpencers">
                <span class="priceSpencers">MRP</span>
                <span class="priceSpencers">&#x20B9;{{ item.mrp }}</span>
              </div>
              <div class="saveSpencers">
                <span class="offerPriceSpencers">Offer Price &#x20B9;{{ item.body ? item.body : 'No Offer' }}</span>
              </div>
            </div>
            <div class="othersArea">
              <div class="descriptionOthers">
                <!-- {{ item.descriptionCompare }} -->
                LEADING NATIONAL BRAND
              </div>
              <div class="mrpOthers">
                <span class="priceOthers">MRP</span>
                <span class="priceOthers">&#x20B9;{{ item.mrpCompare }}</span>
              </div>

              <div class="saveOthers">
                <span class="offerPriceOthers"
                  >Offer Price &#x20B9;{{ item.bodyCompare ? item.bodyCompare : 'No Offer' }}</span
                >
              </div>
            </div>
          </div>
          <div class="bottomHeading">
            <p class="saveText">Save &#x20B9;{{ item.save.toFixed(0) }}</p>
            <p class="tempText">Over Other Brand </p>
          </div>
        </div>
      </div>
    </div>
    <!--List Print-->
    <div v-if="listPrint" v-bind:class="pageClass">
      <div :key="index" v-for="(item, index) in listArr">
        <div :class="[calculateTransition(index), 'subPage']">
          <div class="topHeading">
            <h3>COMPARE & SAVE</h3>
          </div>
          <div class="articlesDiv">
            <div class="spencersArea">
              <div class="descriptionSpencers">
                {{ item.spencersArticleDescription }}
              </div>
              <div class="mrpSpencers">
                <span class="priceSpencers">MRP</span>
                <span class="priceSpencers">&#x20B9;{{ item.mrpSpencers }}</span>
              </div>
              <div class="saveSpencers">
                <span class="offerPriceSpencers"
                  >Offer Price &#x20B9;{{ item.offerSpencers ? item.offerSpencers : 'No Offer' }}</span
                >
              </div>
            </div>
            <div class="othersArea">
              <div class="descriptionOthers">
                <!-- {{ item.descriptionCompare }} -->
                LEADING NATIONAL BRAND
              </div>
              <div class="mrpOthers">
                <span class="priceOthers">MRP</span>
                <span class="priceOthers">&#x20B9;{{ item.mrpLeadingBrand }}</span>
              </div>

              <div class="saveOthers">
                <span class="offerPriceOthers"
                  >Offer Price &#x20B9;{{ item.offerLeadingBrand ? item.offerLeadingBrand : 'No Offer' }}</span
                >
              </div>
            </div>
          </div>
          <div class="bottomHeading">
            <p class="saveText">Save &#x20B9;{{ item.saveList.toFixed(0) }}</p>
            <p class="tempText">Over Other Brand </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// For Reference: http://jsfiddle.net/mturjak/2wk6Q/1949/
export default {
  name: 'PromoCompareTemplateA6',
  props: {
    dataA6: Array,
    listPrint: Boolean
  },
  computed: {
    pageClass() {
      return this.dataA6.length > 1 ? 'page' : 'page-without-break';
    }
  },
  data() {
    return {
      arr: [],
      listArr: []
    };
  },
  watch: {
    dataA6(newValue) {
      if (newValue !== null && newValue !== undefined) {
        if (this.listPrint === true) {
          this.listArr = newValue.flat().map(item => {
            let mrpSpencers = '';
            let spencersArticleDescription = '';
            let offerSpencers = '';
            let mrpLeadingBrand = '';
            let leadingBrandArticleDescription = '';
            let offerLeadingBrand = '';
            let saveList = '';
            mrpSpencers = item.mrpSpencers;
            spencersArticleDescription = item.spencersArticleDescription;
            offerSpencers = item.offerSpencers;
            mrpLeadingBrand = item.mrpLeadingBrand;
            leadingBrandArticleDescription = item.leadingBrandArticleDescription;
            offerLeadingBrand = item.offerLeadingBrand;
            saveList = Number(item.offerLeadingBrand) - Number(item.offerSpencers);
            return {
              mrpSpencers,
              spencersArticleDescription,
              offerSpencers,
              mrpLeadingBrand,
              leadingBrandArticleDescription,
              offerLeadingBrand,
              saveList
            };
          });
        } else {
          this.arr = newValue.flat().map(item => {
            let body = '';
            let save = '';
            let mrp = '';
            let description = '';
            let bodyCompare = '';
            const saveCompare = '';
            let mrpCompare = '';
            let descriptionCompare = '';
            mrp = item.mrp;
            description = item.articleDescription;
            mrpCompare = item.mrpCompare;
            descriptionCompare = item.articleDescriptionCompare;
            body = item.finalPriceSpencer;
            bodyCompare = item.finalPriceBrand;
            save = Number(item.finalPriceBrand) - Number(item.finalPriceSpencer);
            return {
              body,
              save,
              mrp,
              description,
              bodyCompare,
              saveCompare,
              mrpCompare,
              descriptionCompare
            };
          });
        }
      }
    }
  },
  methods: {
    calculateTransition(index) {
      for (let i = 0; i <= index; i++) {
        const abc = (index + 1) % 2;
        if (abc === 0) {
          return 'transition8';
        }
        return `transition${abc}`;
      }
    }
  }
};
</script>
