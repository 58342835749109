<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-row>
          <b-col>
            <h4 class="page-title">Personal Details</h4>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-name" label-for="input-name">
                  <template v-slot:label>
                    Name
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-name"
                    type="text"
                    v-model="form.name"
                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                    placeholder="Enter Name"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-name-invalid">Please enter name.</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="group-mobile" label-for="input-mobile">
                  <template v-slot:label>
                    Mobile
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-mobile"
                    v-model="form.mobile"
                    type="number"
                    autocomplete="new-password"
                    placeholder="Enter Mobile Number"
                    :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
                  ></b-form-input>

                  <b-form-text id="input-mobile-help">Mobile Number must be a valid 10 digit long number.</b-form-text>

                  <b-form-invalid-feedback id="input-email-invalid"
                    >Please enter valid mobile number.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-email" label-for="input-email">
                  <template v-slot:label>
                    E-mail
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-email"
                    type="email"
                    v-model="form.email"
                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                    placeholder="Enter E-mail"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-email-invalid">Please enter email.</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="group-password" label-for="input-password">
                  <template v-slot:label>
                    Password
                    <span class="text-danger" v-if="formType === 'new'">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-password"
                    type="password"
                    v-model="form.password"
                    autocomplete="new-password"
                    :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                    placeholder="Enter Password"
                  ></b-form-input>

                  <b-form-text id="input-password-help">Password must be more than 6 characters.</b-form-text>

                  <b-form-invalid-feedback id="input-password-invalid"
                    >Please enter valid password.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-3">
            <h4 class="page-title">Company Details</h4>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group v-if="ifAdmin()" id="group-company" label-for="input-company">
                  <template v-slot:label> Company </template>

                  <multiselect
                    :class="{
                      'box-shadow3': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                    id="input-frequency"
                    :options="['SRL', 'NBL']"
                    v-model="form.company"
                    placeholder="Select Company"
                  >
                  </multiselect>
                </b-form-group>
                <b-form-group v-else id="group-company" label-for="input-company">
                  <template v-slot:label> Company </template>

                  <multiselect
                    :class="{
                      'box-shadow3': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                    id="input-frequency"
                    :options="[]"
                    v-model="form.company"
                    placeholder="Select Company"
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="group-products" label-for="input-products">
                  <template v-slot:label> Regions </template>
                  <multiselect
                    :class="{
                      'box-shadow3': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                    v-model="regions"
                    ref="multiselect"
                    tag-placeholder="Add this as new tag"
                    placeholder="Select Regions"
                    label="text"
                    track-by="text"
                    :options="regionIds"
                    :multiple="true"
                    :taggable="true"
                    @tag="addTag"
                  ></multiselect>
                  <b-form-text id="input-role-help"
                    >Please select the region(s) where the user is operational.</b-form-text
                  >
                </b-form-group></b-col
              >
            </b-row>

            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label> Roles <span class="text-danger">*</span> </template>
              <multiselect
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                v-model="roles"
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                placeholder="Select Roles"
                label="text"
                track-by="text"
                :options="userRoles"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              >
              </multiselect>
              <b-form-text id="input-role-help"
                >Role field is configuring whether the user is administrator or staff.</b-form-text
              >
            </b-form-group>

            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label> Clusters </template>
              <multiselect
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                v-model="clusters"
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                placeholder="Select Clusters"
                label="text"
                track-by="text"
                :options="clusterIds"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
              <b-form-text id="input-role-help"
                >Please select the cluster(s) where the user is operational.</b-form-text
              >
            </b-form-group>
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-group id="group-empId" label-for="input-empid">
                  <template v-slot:label>
                    Employee Id
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-empid"
                    type="text"
                    v-model="form.empid"
                    :state="$v.form.empid.$dirty ? !$v.form.empid.$error : null"
                    placeholder="Enter Employee Id"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-empid-invalid">Please enter Employee Id.</b-form-invalid-feedback>
                  <b-form-text id="input-empid-help">Please enter the id of employee</b-form-text>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="group-designation" label-for="input-designation">
                  <template v-slot:label>
                    Designation
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    id="input-designation"
                    type="text"
                    v-model="form.designation"
                    :state="$v.form.designation.$dirty ? !$v.form.designation.$error : null"
                    placeholder="Enter Designation"
                  ></b-form-input>
                  <b-form-text id="input-designation-help">Please enter the designation of employee</b-form-text>
                  <b-form-invalid-feedback id="input-designation-invalid"
                    >Please enter employee designation.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group v-if="form.role === 99">
              <template v-slot:label>
                Store Id
                <span class="text-danger">*</span>
              </template>
              <model-select class="box-shadow2" :options="options" v-model="storeid" placeholder="Select Store Id">
              </model-select>
              <b-form-text id="input-role-help"
                >Please select a store id you want to associate with this user.</b-form-text
              >
            </b-form-group>

            <b-form-group v-else id="group-products" label-for="input-products">
              <template v-slot:label> Store Ids </template>
              <multiselect
                v-model="storeids"
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                placeholder="Select Store Ids"
                label="text"
                track-by="value"
                :options="options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>
              <b-form-text id="input-role-help"
                >Note: The first selected store id will be the primary store id of user for all operations.</b-form-text
              >
              <b-button
                class="box-shadow2"
                v-if="ifAdmin()"
                @click="uploadStores"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                ><font-awesome-icon :icon="['fas', 'upload']" class="mr-1" /> Upload Stores</b-button
              >
            </b-form-group>

            <b-form-group id="group-status" label-for="input-status">
              <template v-slot:label>
                Status
                <span class="text-danger">*</span>
              </template>

              <b-form-select
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-status"
                v-model="form.status"
                :options="userEnabled"
                :state="$v.form.status.$dirty ? !$v.form.status.$error : null"
              ></b-form-select>

              <b-form-text id="input-status-help"
                >Status field is configuring whether the user is active or disabled.</b-form-text
              >
              <br />
              <b-form-group id="group-products">
                <template v-slot:label>
                  Do you want to send notification to the user?
                  <span class="text-danger">*</span>
                </template>
                <b-form-radio v-model="form.shouldBeNotified" name="some-radios" :value="true">Yes</b-form-radio>
                <b-form-radio v-model="form.shouldBeNotified" name="some-radios" :value="false">No</b-form-radio>
              </b-form-group>
            </b-form-group>

            <template v-if="errorMessages">
              <b-row class="mb-2">
                <b-col class="text-danger message-col">{{ errorMessages }}</b-col>
              </b-row>
            </template>

            <template v-if="storeIdError">
              <b-row class="mb-2">
                <b-col class="text-danger message-col">{{ storeIdError }}</b-col>
              </b-row>
            </template>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-button
              class="box-shadow2"
              type="submit"
              size="sm"
              :variant="isDarkMode ? 'outline-success' : 'success'"
              :disabled="loading"
            >
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="box-shadow2"
              size="sm"
              :variant="isDarkMode ? 'outline-warning' : 'warning'"
              :to="{ path: `${listUrl}` }"
            >
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson';
import Vue from 'vue';
import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';
import { mapGetters, mapState, mapActions } from 'vuex';
import _ from 'lodash';
import User from '@/model/user';
import configService from '@/services/configService';
import router from '@/router';
import { ModelSelect } from 'vue-search-select';
import Multiselect from 'vue-multiselect';

export default {
  name: 'UserFormBox',
  components: {
    ModelSelect,
    Multiselect
  },
  props: {
    listUrl: String,
    userType: String,
    formType: String,
    userId: {
      type: Number,
      required: false
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      options: [],
      storeids: [],
      storeid: {
        value: '',
        text: ''
      },
      roles: [],
      clusters: [],
      regions: [],
      clusterIds: [],
      regionIds: [],
      formLoaded: false,
      form: {
        mobile: null,
        name: null,
        email: null,
        password: null,
        status: null,
        empid: null,
        designation: null,
        shouldBeNotified: null,
        company: 'SRL'
      },
      storeIdError: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      staffRoles: _.reduce(
        _.pick(User.userRole, ['administrator', 'staff', 'caller']),
        (result, value, key) => {
          result.push({ value, text: User.nameUserRole[User.userRole[key]] });
          return result;
        },
        []
      ),
      userRoles: [],
      userEnabled: _.reduce(
        User.userEnabled,
        (result, value, key) => {
          result.push({ value, text: _.capitalize(key) });
          return result;
        },
        []
      )
    };
  },
  validations() {
    const formValidation = {
      mobile: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10)
      },
      name: {
        required
      },
      empid: {
        required
      },
      email: {
        required
      },
      designation: {
        required
      },
      password: {
        minLength: minLength(6)
      },
      company: {
        required
      },

      status: {
        validateStatus: value => {
          return _.some(User.userEnabled, enabled => value === enabled);
        }
      },
      shouldBeNotified: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.password.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.getRoles();
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.formType === 'new') {
        if (this.userType === 'staff') {
          this.form.role = User.userRole.staff;
        }
        this.form.status = User.userEnabled.active;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new user' : 'Update user';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('user', ['loading', 'user', 'availableRoles']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList']),
    ...mapState('auth', {
      authUser: 'user'
    }),
    showPermissions() {
      return this.userType === 'staff' && this.form.role === User.userRole.staff;
    }
  },
  methods: {
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster']),
    ...mapActions('user', ['getRoles']),
    ifAdmin() {
      const newUser = this.authUser.roles.filter(role => role.name === 'ADMIN');
      if (newUser.length > 0) {
        return true;
      }
      return false;
    },

    ifFoodSafetyAdmin() {
      const newUser = this.authUser.roles.filter(role => role.name === 'QUALITY_AUDIT_HEAD');
      if (newUser.length > 0) {
        return true;
      }
      return false;
    },
    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const storeids = [];
      this.storeids.map(({ value }) => storeids.push(value));

      const roles = [];
      this.roles.map(role => roles.push(role.value));

      const clusters = [];
      this.clusters.map(cluster => clusters.push(cluster.value));

      const regions = [];
      this.regions.map(region => regions.push(region.value));

      const user = {
        mobile: parseInt(this.form.mobile, 10),
        name: this.form.name,
        email: this.form.email.toLowerCase(),
        password: this.form.password,
        status: this.form.status,
        empid: this.form.empid,
        designation: this.form.designation,
        shouldBeNotified: this.form.shouldBeNotified,
        company: this.form.company,
        storeids,
        roles,
        clusters: clusters || '',
        regions: regions || ''
      };
      if (this.formType === 'new') {
        this.$emit('add', { user });
      } else {
        this.$emit('edit', { user });
      }
      return false;
    },
    createCsv() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${
                        item[0].charAt(0).toUpperCase() + item[0].slice(1)
                      } is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.storeids.filter(store => newAddingStore.includes(store.value));
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.storeids = [...this.storeids, ...this.abc];
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    storeList(_newValue) {
      let comparingStores = [];
      comparingStores = _newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
      this.options = _newValue.map(store => ({ text: store.storeid, value: store.storeid }));

      if (this.authUser.role !== 99) {
        this.staffRoles = [
          { text: 'Staff', value: 50 },
          { text: 'Outbound Caller', value: 25 }
        ];
      }
    },
    clusterList(newValue) {
      this.clusterIds = newValue;
    },
    regionList(newValue) {
      this.regionIds = newValue;
    },

    user(_newValue, _oldValue) {
      if (!this.user.id) {
        return;
      }
      // Loaded user, assign to form
      this.form.mobile = _newValue.mobile;
      this.form.name = _newValue.name;
      this.form.email = _newValue.email;
      this.form.company = _newValue.company;
      this.form.status = _newValue.status;
      this.form.empid = _newValue.empid;

      this.form.designation = _newValue.designation;
      this.form.shouldBeNotified = _newValue.shouldBeNotified;
      this.formLoaded = true;

      if (this.user.storeids.length) {
        this.storeid = { text: this.user.storeids[0], value: this.user.storeids[0] };
        this.storeids = this.user.storeids.map(id => ({ text: id, value: id }));
      }

      if (this.user.roles.length) {
        this.roles = this.user.roles.map(({ name, _id }) => ({ text: name, value: _id }));
      }

      if (this.user.clusters.length) {
        this.clusters = this.user.clusters.map(text => ({ text, value: text }));
      }

      if (this.user.regions.length) {
        this.regions = this.user.regions.map(text => ({ text, value: text }));
      }

      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    availableRoles(newValue) {
      if (this.ifFoodSafetyAdmin()) {
        this.userRoles = newValue
          .filter(item =>
            ['QUALITY_AUDIT_HEAD', 'QUALITY_AUDIT_MANAGER', 'QUALITY_AUDIT_EXECUTIVE'].includes(item.name)
          )
          .map(item => ({ text: item.name, value: item.id }));
      } else {
        this.userRoles = newValue.map(item => ({ text: item.name, value: item.id }));
      }
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
