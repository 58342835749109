<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <store-box
      :columns="columns"
      :rows="filteredStoreList.map((i, index) => ({ ...i, index: index + 1 }))"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showToolbar="true"
      :showPagination="false"
      :pagination="pagination"
      :show-download="true"
      downloadText="Download List"
      addText="Create new Store"
      emptyText="No Store found. Please add new Store."
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
      @download="onExportCSV"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </store-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StoreBox from '@/components/StoreBox.vue';
import router from '@/router';
import Vue from 'vue';

export default {
  name: 'Store',
  components: { StoreBox },
  metaInfo() {
    return {
      title: 'Store',
      meta: [
        {
          name: 'description',
          content: `Manage Store`
        }
      ]
    };
  },
  mounted() {
    this.listStore({ router });
  },
  data() {
    return {
      title: 'Store List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'StoreId',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Company',
          class: { 'text-center': true },
          textKey: 'company',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '16%'
        },
        {
          type: 'string',
          headerText: 'Format',
          class: { 'text-center': true },
          textKey: 'format',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'cluster',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'region',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'RSM',
          class: { 'text-center': true },
          textKey: 'rsmName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'ASM',
          class: { 'text-center': true },
          textKey: 'asmName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'SSM',
          class: { 'text-center': true },
          textKey: 'storeManagerName',
          width: '8%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '20%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('store', ['loading', 'baseUrl', 'roles', 'pagination', 'storeList', 'regionList', 'clusterList']),
    ...mapState('auth', ['user']), // Ensure you have the user state available
    ...mapState('darkMode', ['isDarkMode']),
    filteredStoreList() {
      const nblCompany = 'NBL';
      if (this.ifFinanceAdmin()) {
        return this.storeList.filter(store => store.company === nblCompany);
      }
      return this.storeList;
    }
  },
  methods: {
    ...mapActions('store', ['listStore', 'deleteOne']),
    ifFinanceAdmin() {
      return this.user.roles.some(role => role.name === 'FINANCE_ADMIN');
    },
    onAdd() {
      router.push('/store/new');
    },
    onEdit({ row }) {
      /* eslint no-underscore-dangle: 0 */
      router.push(`/store/${row._id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            type: 'store',
            id: row._id
          });
        }
      });
    },
    onExportCSV() {
      if (this.storeList.length > 0) {
        let csv =
          'StoreId,Store Name,Format,Company Code,Cost Center,Petty Cash Store Gl,Cluster,Region,Company,ASM Name,ASM Mobile,RSM Name,RSM Mobile,Store Manager Name,Store Manager Mobile,Store Manager Email,City,Trading Area,Patty Cash Company Name,ASM Email,Business Manager Email,Regional Auditor Email,Regional Quality Manager Email,Regional LP Manager Email\n';
        this.storeList.forEach(row => {
          csv += `${row.storeid},${row.storeName.replaceAll(',', '')},${row.format},${row.companyCode},${
            row.costCenter
          },${row.pattyCashStoreGl},${row.cluster},${row.region},${row.company},${row.asmName},${row.asmMobile},${
            row.rsmName
          },${row.rsmMobile},${row.storeManagerName},${row.storeManagerMobile},${row.storeManagerEmail},${row.city},${
            row.tradingArea
          },${row.pettyCashCompanyName},${row.asmEmail},${row.businessManagerEmail},${row.regionalAuditorEmail},${
            row.regionalQualityManagerEmail
          },${row.regionalLpManagerEmail}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'StoresList.csv';
        anchor.click();
      } else {
        Vue.swal({
          title: 'No data to export',
          text: 'Please go to another page and come back to this page to export data',
          type: 'warning',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      }
    }
  }
};
</script>
