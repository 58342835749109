var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.arr),function(item,index){return _c('div',{key:index,staticClass:"page"},[_c('div',{class:[item.symbol === '' ? 'noDisplay' : 'header-area']},[_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"center-area"},[_c('div',{staticClass:"offer-text"},[(item.symbol === 'RUPEES')?_c('span',{staticClass:"rupeeSymbol"},[_vm._v("₹")]):_vm._e(),_c('span',{class:[item.symbol === '' ? 'noDisplay' : 'offer-text-rupee']},[_vm._v(" "+_vm._s(_vm.beforeDecimalFix(item.body))),_c('sub',{staticStyle:{"font-size":"90pt","vertical-align":"baseline"}},[_vm._v(_vm._s(_vm.afterDecimalFix(item.body)))])]),(item.symbol === 'PERCENT')?_c('span',{staticClass:"percentageSymbol"},[_vm._v(" %")]):_vm._e()])]),_c('span',{class:[
        item.isSave3Family === true
          ? 'ClassSave3'
          : item.body.length > 25
          ? 'longText'
          : item.symbol === ''
          ? 'buyClass'
          : 'noDisplay'
      ]},[_vm._v(_vm._s(item.body))]),_c('div',{class:[item.symbol === '' || item.isDifferentFamily === true ? 'noDisplay' : 'save-container']},[_c('span',{staticClass:"price-label"},[_vm._v(" "+_vm._s(item.subTitle)+":₹ ")]),_c('span',{staticClass:"price-label"},[_vm._v(_vm._s(_vm.fixDigits(item.save)))])]),_c('div',{class:[item.isDifferentFamily === true ? 'noDisplay' : 'mrp-container']},[_c('span',{staticClass:"price-label"},[_vm._v("MRP:₹")]),_c('span',{staticClass:"price-label"},[_vm._v(_vm._s(_vm.fixDigits(item.mrp)))])]),_c('div',{class:[
        item.isDifferentFamily === true || item.isSelectedbrick === true ? 'familyDescription' : 'description'
      ]},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"validTo"},[_vm._v("(ValidTo:"+_vm._s(item.validTo)+")")]),_c('div',{staticClass:"footer-date"},[_vm._v(" (PPD:"+_vm._s(_vm.currentDate())+") ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }