<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <pop-box
      :columns="columns"
      :rows="popLists"
      :loading="loading"
      emptyText="No data found. Please wait ."
      :showToolbar="true"
      :downloadLoading="downloadLoading"
      :pagination="pagination"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      :filterOptionsForSoh="filterOptionsForSoh"
      :filterOptionsForType="filterOptionsForType"
      :filterOptionsForFamily="filterOptionsForFamily"
      :showPrint="true"
      :showPromo="true"
      :showPagination="true"
      printTextA4="Print A4"
      printTextA7="Print A7"
      manualText="Custom Print"
      downloadText="Download"
      downloadPromoText="Download Promo Report"
      @add="onAdd"
      @download="onExportCSV"
      @download-promo="ondownload"
    >
    </pop-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PopBox from '@/components/PopBox.vue';
import router from '@/router';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'POP',
  components: { PopBox },
  metaInfo() {
    return {
      title: 'POP',
      meta: [
        {
          name: 'description',
          content: 'View Reports'
        }
      ]
    };
  },

  methods: {
    onAdd() {
      router.push('/pop/new');
    },
    ...mapActions('popPrint', ['listPop', 'report', 'download', 'downloadPromo']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1
          }
        });
      }
    },
    ondownload(date) {
      this.fromDate = date.startdate;
      this.toDate = date.enddate;
      this.downloadPromo({ query: { ...date } });
    }
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listPop({
        query: {
          ...this.$route.query
        }
      });
      this.report({ router, query: { ...this.$route.query } });
    } else {
      this.listPop({ query: this.$route.query });
      this.report({ query: { ...this.$route.query } });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listPop({ query: to.query });
    this.report({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'POP',
      fromDate: '',
      toDate: '',
      searchItems: ['Store Id', 'Article Code'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'NEW PROMO', text: 'New Promo' },
        { value: 'DEACTIVATED PROMO', text: 'DEACTIVATED PROMO' },
        { value: 'EXPIRED PROMO', text: 'EXPIRED PROMO' }
      ],
      filterOptionsForSoh: [
        { value: null, text: 'All Articles' },
        { value: 'STOCK AVAILABLE', text: 'Available Articles Only' },
        { value: 'STOCK NOT AVAILABLE', text: 'Stock Not Available' }
      ],
      filterOptionsForType: [
        { value: null, text: 'Select Type' },
        { value: 'SAVE', text: 'Save' },
        { value: 'SPENCERS', text: 'Price' },
        { value: 'SAVE1', text: 'Save1' },
        { value: 'SAVE2', text: 'Save2' },
        { value: 'SAVE3', text: 'Save3' },
        { value: 'SPENCERS1', text: 'Price1' },
        { value: 'SPENCERS2', text: 'Price2' },
        { value: 'SPENCERS3', text: 'Price3' }
      ],
      filterOptionsForFamily: [
        'BAKERY & FOOD SERVICE',
        'BEAUTY AIDS',
        'BEVERAGES',
        'CB ACCESSORIES',
        'CB BASIC WEAR',
        'CB FOOT WEAR',
        'CB LADIES WEAR',
        'CB KIDS WEAR',
        'CB MENS WEAR',
        'CEREALS & MINOR CEREALS',
        'CHILLED & FROZEN',
        'DRY FRUIT & NUTS',
        'EDIBLE OIL & FATS',
        'EGG',
        'ELECTRICALS',
        'ELECTRONICS',
        'FISH & MEAT',
        'FLOUR & READY MIX',
        'FRESH FRUIT',
        'FRESH VEGETABLE',
        'FURNITURE',
        'HEALTH & BEAUTY',
        'HOME',
        'HOUSE HOLD NEED',
        'ICE CREAM',
        'LINEN',
        'LIQUOR',
        'MARKETING',
        'MASALA SPICES & HERBS',
        'ORGANIC STAPLES',
        'PLAY',
        'PROCESSED FOOD',
        'PULSES & MINOR PULSES',
        'SALT & FORTIFIED SALT',
        'SWEETNER & SUGAR',
        'WORK'
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '2%'
        },
        {
          type: 'row_num',
          headerText: 'Select',
          class: { 'text-center': true },
          textKey: 'select',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Store ID',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Article code',
          textKey: 'articleCode',
          class: { 'text-center': true },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Status',
          textKey: 'delta',
          class: { 'text-center': true },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Family',
          textKey: 'family',
          class: { 'text-center': true },
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Description',
          textKey: 'articleDescription',
          class: { 'text-center': true },
          width: '10%'
        },
        // {
        //   type: 'string',
        //   headerText: 'Brick',
        //   textKey: 'brick',
        //   class: { 'text-center': true },
        //   width: '5%'
        // },
        {
          type: 'string',
          headerText: 'Offer',
          textKey: 'pop1',
          class: { 'text-center': true },
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'SOH',
          textKey: 'stockInHand',
          class: { 'text-center': true },
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'MRP',
          textKey: 'mrp',
          class: { 'text-center': true },
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Offer Price',
          textKey: 'offerPrice',
          class: { 'text-center': true },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Percentage Off',
          textKey: 'percentageOff',
          class: { 'text-center': true },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Rupee Off',
          textKey: 'rupeesOff',
          class: { 'text-center': true },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Valid from',
          textKey: 'validFrom',
          class: { 'text-center': true },
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Valid to',
          textKey: 'validTo',
          class: { 'text-center': true },
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Type',
          textKey: 'popPaperType',
          class: { 'text-center': true },
          width: '6%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '12%',
          functions: {
            edit: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('popPrint', [
      'loading',
      'baseUrl',
      'popLists',
      'pagination',
      'downloadPopList',
      'downloadPromoList',
      'downloadLoading'
    ]),
    ...mapState('auth', {
      authUser: 'user'
    })
  },
  watch: {
    downloadPopList(newValue) {
      if (newValue.length) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate && enddate) {
          csv += `,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv +=
          'Row Number,Store Id,Article Code,Status,Family,Description,Offer,Mrp,Offer price,PercentageOff,RupeeOff,Type,Vaild from,Vaild to,\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'DownloadList.csv';
        anchor.click();
      }
    },
    downloadPromoList(newValue) {
      if (newValue.length) {
        const groupedByRegion = {};
        const regionSums = {};
        newValue.forEach(item => {
          if (item.region) {
            if (!groupedByRegion[item.region]) {
              groupedByRegion[item.region] = [];
              regionSums[item.region] = {
                newPromoCount: 0,
                deactivePromo: 0,
                expiredPromo: 0,
                stockAvailable: 0,
                printed: 0
              };
            }
            groupedByRegion[item.region].push(item);
            regionSums[item.region].newPromoCount += item.newPromoCount;
            regionSums[item.region].deactivePromo += item.deactivePromo;
            regionSums[item.region].expiredPromo += item.expiredPromo;
            regionSums[item.region].stockAvailable += item.stockAvailable;

            regionSums[item.region].printed += item.isPrinted;
          }
        });
        let csv = '';
        csv += `,Date From ${this.fromDate} to ${this.toDate},\n`;
        csv += `\n`;
        csv += 'Region,NewPromo Count,Deactive Promo,Expired Promo,Available Articles,Printed\n';
        Object.keys(groupedByRegion).forEach(region => {
          csv += `${region},${regionSums[region].newPromoCount},${regionSums[region].deactivePromo},${regionSums[region].expiredPromo},${regionSums[region].stockAvailable},${regionSums[region].printed}\n`;
        });
        csv += `\n,,,Store Wise\n`;
        csv += 'Store Id,Store Name,NewPromo Count,Deactive Promo,Expired Promo,Available Articles,Printed\n';
        newValue.forEach(item => {
          csv += `${item._id},${item.storeName},${item.newPromoCount},${item.deactivePromo},${item.expiredPromo},${item.stockAvailable},${item.isPrinted}\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Promo_Report.csv';
        anchor.click();
      }
    }
  }
};
</script>
<input type="button" class="no-print" onclick="printChecked()" value="Print Selected Items" />
