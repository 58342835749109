<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-name"
            :formatter="formatter"
            type="text"
            v-model="form.name"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            placeholder="Enter Name"
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-invalid">Please enter name.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-description" label-for="input-description">
          <template v-slot:label> Description </template>
          <b-form-textarea
            id="input-description"
            type="text"
            rows="1"
            v-model="form.description"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            placeholder="Enter Description If Any"
          ></b-form-textarea>
          <b-form-text id="input-description-help">Description must be more than 1 characters.</b-form-text>
          <b-form-invalid-feedback id="input-description-invalid"
            >Please enter valid description.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-row>
          <b-col>
            <b-button type="submit" size="sm" variant="success" :disabled="loading">
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="warning" :to="{ path: `${listUrl}` }">
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
// import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  name: 'PermissionFormBox',
  props: {
    listUrl: String,
    formType: String,
    name: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      formLoaded: false,
      form: {
        name: null,
        description: null
      },
      isLoading: false
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      description: {}
    };
    return { form: formValidation };
  },
  mounted() {
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.formType === 'new') {
        this.form.name = this.name;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add New Permission' : 'Update Permission';
    },
    ...mapState('permission', ['loading', 'permission'])
  },
  methods: {
    formatter(name) {
      return name.toUpperCase();
    },

    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const permission = {
        name: this.form.name,
        description: this.form.description
      };

      if (this.formType === 'new') {
        this.$emit('add', { permission });
      } else {
        this.$emit('edit', { permission });
      }
      return false;
    }
  },
  watch: {
    name(_newValue) {
      this.form.name = _newValue;
    },
    permission(_newValue, _oldValue) {
      const { id } = this.permission;

      if (!id) {
        return;
      }
      // Loaded order, assign to form

      this.form.name = this.permission.name;
      this.form.description = this.permission.description;

      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
