<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <store-form-box list-url="/" :id="id" checklist-type="store" :form-type="formType" @add="onAdd" @edit="onEdit">
      </store-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import StoreFormBox from '../../components/StoreFormBox.vue';

export default {
  name: 'StoreForm',
  components: {
    StoreFormBox
  },
  async mounted() {
    if (this.$route.name === 'store-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'store',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Store' : 'Update Store';
    },
    ...mapState('store', ['loading'])
  },
  methods: {
    ...mapActions('store', ['getOne', 'postOne', 'patchOne']),
    onAdd({ store }) {
      this.postOne({
        store,
        router,
        redirectUrl: '/stores'
      });
    },
    onEdit({ store }) {
      this.patchOne({
        type: 'store',
        id: this.id,
        store,
        router,
        redirectUrl: '/stores'
      });
    }
  }
};
</script>
