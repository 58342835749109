<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Form Name
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            :class="{
              'box-shadow3 bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            id="input-name"
            :formatter="formatter"
            type="text"
            v-model="name"
            placeholder="Enter Name of Form"
          ></b-form-input>
        </b-form-group>

        <div class="alert" v-if="showAlert" @click="handleAlert">
          <b-form-text id="FormName">Note: Please use alphabets or numbers only</b-form-text>
        </div>
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Region Ids
                <span class="text-danger">*</span>
              </template>

              <multiselect
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="regionids"
                placeholder="Select region Ids"
                label="text"
                :options="regions"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Cluster Ids
                <span class="text-danger">*</span>
              </template>
              <multiselect
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="clusterids"
                placeholder="Select Cluster Ids"
                label="text"
                :options="clusters"
              >
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            :class="{
              'box-shadow3': !isDarkMode,
              darkMode: isDarkMode
            }"
            v-model="storeids"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Ids"
            label="text"
            track-by="value"
            :options="stores"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          ></multiselect>
          <b-form-text id="input-role-help"
            >Note: The first selected store id will be the primary store id of CheckList for all
            operations.</b-form-text
          >
          <b-button class="box-shadow2" @click="uploadStores" :variant="isDarkMode ? 'outline-success' : 'success'">
            <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload Stores</b-button
          >
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Role
            <span class="text-danger">*</span>
          </template>

          <multiselect
            :class="{
              'box-shadow3': !isDarkMode,
              darkMode: isDarkMode
            }"
            id="input-role"
            v-model="roleids"
            placeholder="Select role"
            label="text"
            ref="multiselect"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            :options="role"
          ></multiselect>
        </b-form-group>

        <!-- <div>
          <template> Managed Input Fields </template>
          <h6>Do you want Store code field?</h6>
          <input type="checkbox" v-model="checked" class="toggle" id="toggle" />
          <label for="toggle">
            <span class="on">Yes</span>
            <span class="off">No</span>
          </label>
        </div> -->
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            <h4 class="page-title text-center">Add Input Fields</h4>
            <span class="text-danger">*</span>
          </template>

          <!-- <b-form-text id="input-role-help">Note: The field of store id will be created by default.</b-form-text> -->
          <h6>Note: The field of store id will be created by default.</h6>

          <b-form-group>
            <b-row class="mb-9">
              <b-col>
                <b-row>
                  <b-col sm="12" lg="6">
                    <!-- <h7>Name of Input Field</h7> -->
                    <b-form-input
                      :class="{
                        'box-shadow3 mt-2 bg-light text-dark': !isDarkMode,
                        'bg-dark mt-2 text-light placeholder-light': isDarkMode
                      }"
                      disabled
                      id="input-name"
                      type="text"
                      v-model="inputName"
                      placeholder="Enter Name of Input Field"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <!-- <h7>Input Field Type</h7> -->
                    <b-form-select
                      :class="{
                        'box-shadow3 mt-2 bg-light text-dark': !isDarkMode,
                        'bg-dark mt-2 text-light placeholder-light': isDarkMode
                      }"
                      disabled
                      v-model="selected"
                      :options="optionForStore"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group>
            <div class="input wrapper items-center" v-for="(input, inputField) in inputs" :key="inputField">
              <b-row class="mb-9">
                <b-col>
                  <b-row>
                    <b-col class="mt-3" sm="12" lg="6">
                      <template labelfor="input-category"> Name of Input Field </template>

                      <b-form-input
                        :class="{
                          'box-shadow3 bg-light text-dark': !isDarkMode,
                          'bg-dark text-light placeholder-light': isDarkMode
                        }"
                        id="input-name"
                        v-model="input.label"
                        type="text"
                        :formatter="formatter"
                        placeholder="Enter Name of Input Field"
                      ></b-form-input>
                    </b-col>

                    <b-col class="mt-3">
                      <template labelfor="input-subcategory"> Input Field Type </template>
                      <Multiselect
                        :class="{
                          'box-shadow3': !isDarkMode,
                          darkMode: isDarkMode
                        }"
                        id="input-type"
                        placeholder="Select Input Field Type (Ex: Text, Numbers, etc)"
                        v-model="input.type"
                        :options="['MOBILE', 'TEXT', 'NUMBER', 'CHECKBOX', 'SELECT', 'FILE', 'IMAGES', 'DATE', 'TIME']"
                      >
                      </Multiselect>
                    </b-col>

                    <b-col sm="0">
                      <b-button
                        class="box-shadow2 mt-5"
                        @click="remove(inputField, input._id)"
                        v-show="inputField || (!inputField && inputs.length > 1)"
                        variant="danger"
                        >Delete</b-button
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div v-if="input.type === 'SELECT'" style="margin-left: 16px">
                <template class="mt-2"> Type Options: </template>
                <b-row class="mb-3">
                  <b-col>
                    <b-form-input
                      id="option-name"
                      v-model="input.options"
                      type="text"
                      placeholder="Type Options separated by Comma(,)"
                    >
                    </b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div v-if="showAlert">
                <b-form-text id="InputField">Note: Please fill form name with alphabats numbers </b-form-text>
              </div>
              <b-button
                class="mt-3 box-shadow2"
                @click="add(inputField)"
                v-show="inputField == inputs.length - 1"
                :variant="isDarkMode ? 'outline-primary' : 'primary'"
              >
                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add New Input Field</b-button
              >
            </div>

            <b-row class="mt-2">
              <b-col class="text-right">
                <b-button
                  class="box-shadow2"
                  type="submit"
                  size="sm"
                  :variant="isDarkMode ? 'outline-success' : 'success'"
                  :disabled="loading"
                >
                  <span class="spinner spinner-white" v-if="loading"></span>
                  <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
                </b-button>
                <b-button
                  class="ml-2 box-shadow2"
                  size="sm"
                  :variant="isDarkMode ? 'outline-warning' : 'warning'"
                  :to="{ path: `/form` }"
                >
                  <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import router from '@/router';
import Vue from 'vue';
import csv from 'csvtojson';

const TYPEMAP = {
  text: 'String',
  number: 'Number',
  checkbox: 'Boolean',
  select: 'String',
  store_id: 'String',
  file: 'String',
  images: ['String'],
  mobile: 'String',
  date: 'Date',
  time: 'Date'
};

export default {
  name: 'NewFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    roleType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          label: '',
          type: '',
          model: '',
          options: ''
        }
      ],
      showAlert: false,
      inputName: 'Store ID',
      inputField: {},
      formLoaded: false,
      name: null,
      config: [],
      model: '',
      regions: [],
      clusters: [],
      stores: [],
      role: [],
      storeids: [],
      clusterids: [],
      regionids: [],
      formatids: [],
      roleids: [],
      selected: null,
      optionForStore: [
        {
          text: 'STORE_ID',
          value: null
        }
      ]
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      permission: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.permission.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.formLoaded = true;
    this.listRole({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Create new form' : 'Update form';
    },
    ...mapState('form', ['loading']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('role', ['roles']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList'])
  },
  methods: {
    ...mapActions('role', ['listRole']),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),

    handleAlert() {
      this.showAlert = false;
    },
    formatter(text) {
      const regex = /^[A-Za-z0-9_ ]+$/;
      if (regex.test(text)) {
        return text;
      } else {
        this.showAlert = true;
        return text.substring(0, text.length - 1);
      }
    },
    onSubmit() {
      const storeids = [];
      this.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.clusterids.map(({ value }) => clusters.push(value));

      const roles = [];
      this.roleids.map(({ value }) => roles.push(value));

      let configFields = [];
      configFields = this.inputs.map(i => {
        let model = i.label.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
        model = model.substr(0, 1).toLowerCase() + model.substr(1);
        let options = [];
        if (i.options) {
          options = i.options.split(',').map(item => item.trim());
        }
        const type = i.type.toLowerCase();
        return { ...i, model, options, type };
      });
      var labels = configFields.map(i => i.label.toLowerCase());
      var uniqueFieldName = labels.every((val, i, arr) => arr.indexOf(val) === i);
      this.config = this.inputs;
      const target = this.name.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
      configFields.push({
        label: 'Store ID',
        type: 'store_id',
        model: 'storeid',
        options: []
      });
      const form = {
        name: this.name,
        target: target.toLowerCase(),
        config: configFields,
        model: JSON.stringify(
          configFields.reduce((acc, curr) => {
            return { ...acc, [curr.model]: TYPEMAP[curr.type] };
          }, {})
        ),
        storeids: storeids || [],
        regions: regions || [],
        clusters: clusters || [],
        roles: roles || []
      };
      if (this.formType === 'new') {
        if (uniqueFieldName) {
          this.$emit('add', { form });
        } else {
          alert('Input Field Name must be different');
        }
      } else {
        this.$emit('edit', { form });
      }
      return false;
    },
    add() {
      this.inputs.push({ type: '', label: '', model: '', options: '' });
    },
    remove(inputField) {
      this.inputs.splice(inputField, 1);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    createCsv() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${
                        item[0].charAt(0).toUpperCase() + item[0].slice(1)
                      } is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.storeids.filter(store => newAddingStore.includes(store.value));
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.storeids = [...this.storeids, ...this.abc];
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  },
  watch: {
    clusterList(newValue) {
      this.clusters = newValue;
    },
    regionList(newValue) {
      this.regions = newValue;
    },
    roles(newValue) {
      this.role = newValue;
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
// @import 'compass/css3';
.alert {
  cursor: pointer;
  padding: 0;
}
input[type='checkbox'] {
  &.toggle {
    opacity: 0;
    position: absolute;
    left: -99999px;

    & + label {
      height: 40px;
      line-height: 40px;
      background-color: #ccc;
      padding: 0px 16px;
      border-radius: 16px;
      margin-bottom: 15px;
      margin-top: 5px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      transition: all 0.25s ease-in;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);

      &:before,
      &:hover:before {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 46px;
        height: 36px;
        background: #fff;
        z-index: 2;
        transition: all 0.25s ease-in;
        border-radius: 14px;
      }

      .off,
      .on {
        color: #fff;
      }

      .off {
        margin-left: 46px;
        display: inline-block;
      }

      .on {
        display: none;
      }
    }

    &:checked + label {
      .off {
        display: none;
      }

      .on {
        margin-right: 46px;
        display: inline-block;
      }
    }

    &:checked + label,
    &:focus:checked + label {
      background-color: #33cc148e;

      &:before,
      &:hover:before {
        background-position: 0 0;
        top: 2px;
        left: 100%;
        margin-left: -48px;
      }
    }
  }
}
</style>
