const getTemplatePrintCss = printType => {
  if (printType === 'A6compare') {
    return `<style> body {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
  }
  * {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
  }
  .page {
    width: 100%;
    min-height: 100%;
    position: relative;
  }
  .page-without-break {
    width: 100%;
    min-height: 100%;
    margin: auto auto;
}
  .subPage{
    float:left;
    width:148.5mm;
    height: 105mm;
    margin:0px;
    padding:0px;
  }
      .topHeading{
      position:relative;
      height:2cm;
      background:yellow;
      text-align:center;
      font:25pt bolder;
      }
      .articlesDiv{
      display:flex;
      justify-content:center;
      width:100%;
      }
      .spencersArea{
      width:50%;
      padding-left:20px;
      font:25pt bolder;

      }
      .othersArea{
      width:50%;
      border-left:1px solid black;
      padding-left:20px;
      font:25pt bolder;
      }
      .descriptionSpencers   {
        font:20pt bolder;
        font-weight:900;
      }
      .descriptionOthers{
        font:20pt bolder;
        font-weight:900;
      }
      .offerPriceSpencers{
        font:25px bolder;
        font-weight:900;
      }
      .offerPriceOthers{
        font:25px bolder;
        font-weight:900;
      }
      .bottomHeading{
        color:red;
      font:25pt bolder;
      text-align:center;
      line-height:0.1;
  }
  @page {
    size: landscape;
    margin: 0;
  }
  @media print {
      html, body {
          width:297mm;
          height: 210mm;
     }
      .page {
          margin: 0;
          border: initial;
          border-radius: initial;
          width: initial;
          min-height: initial;
          box-shadow: initial;
          page-break-after: always;
     }
  }
  </style>`;
  }
  if (printType === 'A7compare') {
    return `<style> body {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: #FAFAFA;
          font: 12pt "Arial";
     }
      * {
          box-sizing: border-box;
          -moz-box-sizing: border-box;
     }
      .topHeading{
        align-items:center;
          background-color:#FFFF00;
          position:relative;
          font-size: 15pt;
          text-align:center;
          width:100%;
          height:1cm;
     }
      .page {
          width: 100%;
          min-height: 100%;
          // border-radius: 5px;
          background: white;
          // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
     }
      .page-without-break {
          width: 100%;
          min-height: 100%;
          margin: auto auto;
          // border-radius: 5px;
          background: white;
          // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
     }
      .subPage {
          float: left;
          margin: 0;
          padding:0;
          width: 105mm;
          height: 74.25mm;
          position: relative;
     }
      .articlesDiv{
          display:flex;
          justify-content:space-between;
          margin-top:5px;
     }
      .spencersArea{
          border-right:2px solid black;
          position:fixed;
          top:25%;
          width:50%;
          left:1%;
          padding-bottom:5%;
     }
      .descriptionSpencers{
        padding-left:10px;
          font: 15pt bolder Arial;
          text-align:left;
          font-weight:900;
          margin-bottom:10px;
     }

    .priceSpencers{
          font: 13pt bolder Arial;
          padding-left:10px;
     }
     .offerPriceSpencers{
        font: 13pt bolder Arial;
        font-weight:900;
        padding-left:10px;
   }
      .othersArea {
        position:fixed;
        top:25%;
        left:51%;
        padding-bottom:5%;
     }
      .descriptionOthers{
        padding-left:10px;
          font: 15pt bolder Arial;
          font-weight:900;
          text-align:left;
     }
     .mrpOthers{
   }
      .priceOthers{
          font: 13pt Arial;
          padding-left:5px;
     }
     .offerPriceOthers{
        font: 13pt bolder Arial;
        font-weight:900;
        padding-left:5px;
   }
   .saveText{
    position:fixed;
    top:65%;
    left:34%;
    color:red;
    font:25pt bolder Arial;
    font-weight:900;
   }
.tempText{
    position:fixed;
    top:85%;
    left:33%;
    color:red;
    font:15pt bolder Arial;
}
     .transition1{
      transform: translate(-8px,0px)
    }
    .transition2{
      transform: translate(55px,0px)
    }
    .transition3{
      transform: translate(-8px,30px)
    }
    .transition4{
      transform: translate(55px,30px)
    }
    .transition5{
      transform: translate(-8px,100px)
    }
    .transition6{
      transform: translate(55px,100px)
    }
    .transition7{
      transform: translate(-8px,135px)
    }
    .transition8{
      transform: translate(55px,135px)
    }
      @page {
          size: A4;
          margin-top: 2mm;
     }
      @media print {
          html, body {
              width: 210mm;
              height: 297mm;
         }
          div {
              break-inside: avoid;
         }
          .page {
              border: initial;
              border-radius: initial;
              width: initial;
              margin: 5mm auto;
              min-height: initial;
              box-shadow: initial;
              background: initial;
              page-break-after: always;
         }
          .page-without-break {
              margin: 5mm auto;
              border: initial;
              border-radius: initial;
              width: initial;
              min-height: initial;
              box-shadow: initial;
              background: initial;
         }
     }
      </style>`;
  }
  return `<style> body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-top:10px solid blue;
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page {
    width:29.7cm;
    height: 21cm;
    position: relative;
}
    .topHeading{
    position:relative;
    height:4cm;
    background:yellow;
    text-align:center;
    font:30pt bolder;
    }
    .articlesDiv{
    display:flex;
    justify-content:center;
    width:100%;
    }
    .spencersArea{
    width:50%;
    padding-left:20px;
    font:40pt bolder;

    }
    .othersArea{
    width:50%;
    border-left:1px solid black;
    padding-left:20px;
    font:40pt bolder;
    }
    .descriptionSpencers   {
      font:40pt bolder;
      font-weight:900;
    }
    .descriptionOthers{
      font:40pt bolder;
      font-weight:900;
    }
    .offerPriceSpencers{
      font:40pt bolder;
      font-weight:900;
    }
    .offerPriceOthers{
      font:40pt bolder;
      font-weight:900;
    }
    .bottomHeading{
      color:red;
    font:60px bolder;
    text-align:center;
    line-height:0.5;
}
@page {
    size: landscape;
    margin: 0;
}
@media print {
    html, body {
        width:29.7cm;
        height: 21cm;
   }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        page-break-after: always;
   }
}
</style>`;
};
const divideArrInSubArr = data => {
  const divideInSubpartOf = 8;
  let subArr = [];
  const arr = [];
  if (data.length > 0) {
    for (let index = 0; index < data.length; index += 1) {
      const element = data[index];
      if ((index + 1) % divideInSubpartOf === 0) {
        subArr.push(element);
        arr.push(subArr);
        subArr = [];
      } else {
        subArr.push(element);
      }
    }
    if (subArr.length > 0) {
      arr.push(subArr);
    }
    return arr;
  }
  return [];
};
export default {
  getTemplatePrintCss,
  divideArrInSubArr
};
