<template>
  <div>
    <h1 class="page-title text-center">{{ title }}</h1>
    <b-form v-if="inputFields" @submit.stop.prevent="onSubmit">
      <div v-for="config in inputFields.config" :key="config.name">
        <b-form-group
          v-if="(config.type == 'Select' || config.type == 'select') && config.options.length >= 1"
          id="group-name"
          label-for="input-name"
        >
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-select type="select" :options="config.options" v-model="form[config.model]"></b-form-select>
        </b-form-group>
        <!-- <b-form-group v-if="config.type == 'file' || config.type == 'File'" id="group-name" label-for="input-name">
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-file
            v-model="form[config.model]"
            placeholder="Choose an file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group> -->
        <b-form-group
          v-if="config.type == 'checkbox' || config.type == 'Checkbox'"
          id="group-name"
          label-for="input-name"
        >
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-radio v-model="form[config.model]" :name="config.model" value="true">True</b-form-radio>
          <b-form-radio v-model="form[config.model]" :name="config.model" value="false">False</b-form-radio>
        </b-form-group>
        <!-- <b-form-group
          v-if="config.type == 'STORE_ID' || config.type == 'store_id'"
          id="group-name"
          label-for="input-name"
        >
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-input type="text" v-model="form[config.model]"></b-form-input>
        </b-form-group> -->
        <b-form-group v-if="config.type == 'MOBILE' || config.type == 'mobile'" id="group-name" label-for="input-name">
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-input type="number" v-model="form[config.model]"></b-form-input>
        </b-form-group>
        <b-form-group v-if="config.type == 'Number' || config.type == 'number'" id="group-name" label-for="input-name">
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-input type="number" v-model="form[config.model]"></b-form-input>
        </b-form-group>
        <b-form-group v-if="config.type == 'TEXT' || config.type == 'text'" id="group-name" label-for="input-name">
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>

          <b-form-input type="text" v-model="form[config.model]"></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="config.type == 'STORE_ID' || config.type == 'store_id'"
          id="group-products"
          label-for="input-products"
        >
          <template v-slot:label>
            Store ID
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="form[config.model]"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Id"
            track-by="value"
            :options="stores"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="config.type == 'DATE' || config.type == 'date'" id="group-name" label-for="input-name">
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>
          <div style="margin-left: -9px">
            <b-form-datepicker
              id="datepicker"
              placeholder="Select Date"
              :min="minDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="form[config.model]"
              class="ml-2 mb-2"
            ></b-form-datepicker>
          </div>
        </b-form-group>
        <b-form-group v-if="config.type == 'TIME' || config.type == 'time'" id="group-name" label-for="input-name">
          <template v-slot:label>
            {{ config.label }}
            <span class="text-danger">*</span>
          </template>
          <div>
            <b-form-timepicker
              v-model="form[config.model]"
              id="timepicker-buttons"
              now-button
              label-now-button="Select Current Time"
              reset-button
              locale="en"
            ></b-form-timepicker>
          </div>
        </b-form-group>
      </div>
      <template v-if="errorMessages">
        <b-row class="mb-2">
          <b-col class="text-danger message-col">{{ errorMessages }}</b-col>
        </b-row>
      </template>
      <div>
        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button type="submit" size="sm" variant="success" :disabled="loading">
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
            <b-button
              class="ml-2"
              size="sm"
              variant="warning"
              :to="{ path: `/form/operations`, query: { formTarget: inputFields._id, formName: inputFields.name } }"
            >
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';
import Vue from 'vue';
import moment from 'moment';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  async mounted() {
    this.getStoreByUser();
    await this.getInputFields({ id: this.$route.params.id });
  },
  data() {
    return {
      form: {},
      stores: [],
      minDate: moment().format('DD-MM-YYYY')
    };
  },
  computed: {
    ...mapState('form', ['loading', 'inputFields']),
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('cstSubmission', ['store']),
    title() {
      if (this.inputFields) {
        return `Create new entry for ${this.inputFields.name}`;
      }
      return null;
    }
  },
  methods: {
    ...mapActions('form', ['getInputFields', 'saveFormData']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    createBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          resolve(e.target.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async onSubmit() {
      const fieldToValidate = this.inputFields.config
        .filter(field => ['file'].includes(field.type))
        .map(field => ({
          model: field.model,
          type: field.type
        }));
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.form)) {
        if (fieldToValidate.map(f => f.model).includes(key)) {
          const { type } = fieldToValidate.filter(f => f.model === key)[0];
          if (type === 'file') {
            // eslint-disable-next-line no-await-in-loop
            this.form[key] = await this.createBase64(value);
          }
        }
      }
      const storeField = this.inputFields.config.filter(field => field.type === 'store_id');
      const imageField = this.inputFields.config.filter(field => field.type === 'file');
      const dateField = this.inputFields.config.filter(field => field.type === 'date');
      const timeField = this.inputFields.config.filter(field => field.type === 'time');
      if (storeField.length > 0) {
        const storeId = storeField.map(field => field.model);
        const foundInForm = this.form.hasOwnProperty(storeId);
        if (foundInForm === false) {
          Vue.swal({
            title: 'Error',
            text: 'Please select store id',
            type: 'error',
            confirmButtonText: 'OK',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      }

      if (imageField.length > 0) {
        const image = imageField.map(field => field.model);
        const foundInForm = this.form.hasOwnProperty(image);
        if (foundInForm === false) {
          Vue.swal({
            title: 'Error',
            text: 'You can not fill the form without image, Please use Disha App to fill this form',
            type: 'error',
            confirmButtonText: 'OK',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      }
      if (dateField.length > 0) {
        const date = dateField.map(field => field.model);
        const foundInForm = this.form.hasOwnProperty(date);
        if (foundInForm === false) {
          Vue.swal({
            title: 'Error',
            text: 'Please select date',
            type: 'error',
            confirmButtonText: 'OK',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      }
      if (timeField.length > 0) {
        const time = timeField.map(field => field.model);
        const foundInForm = this.form.hasOwnProperty(time);
        if (foundInForm === false) {
          Vue.swal({
            title: 'Error',
            text: 'Please select time',
            type: 'error',
            confirmButtonText: 'OK',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      }
      const formData = {
        formTarget: this.inputFields._id /* eslint no-underscore-dangle: 0 */,
        data: this.form
      };
      this.saveFormData({
        type: 'form',
        router,
        formData,
        redirectUrl: `/form/operations?formTarget=${this.$route.params.id}&formName=${this.inputFields.name}`
      });
    }
  },
  watch: {
    store(newValue) {
      this.stores = newValue.map(store => store.storeid);
    }
  }
};
</script>

<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
