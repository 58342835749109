const getTemplatePrintCss = printType => {
  if (printType === 'A5') {
    return `<style>
    body {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      background-color: #FAFAFA;
      font: 25pt "Arial";
    }
    * {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
    }

    .page {
      width: 100%;
      min-height: 100%;
      border-radius: 5px;
      background: white;

      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .page-without-break {
      width: 100%;
      min-height: 100%;
      margin: auto auto;
      border-radius: 5px;
      background: white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .noDisplay{
      display: none;
    }
    .subPage {
      float: left;
      margin: 0;
      padding-top: 5px;
      width: 210mm;
      height: 148.5mm;
      border: 1px solid black;
      position: relative;
    }
    // .transition1{
    //   transform: translate(0px,0px)
    // }
    // .transition2{
    //   transform: translate(0px,0px)
    // }

    .header-area {
      width: 100%;
      position:absolute;
      top:9%;
      color: black;
      font-size: 27pt;
      font-style: arial;
      font-weight: bold;
      text-align: center;
    }

    .header-text {
      color: red;
      font-size: 24pt;
      font-style: arial;
      padding-bottom: 100px;
      border:1px solid black;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }

    .center-area {
      width: 100%;
      position:absolute;
      top:25%;
      display: flex;
      flex-direction: column;
    }
    .offer-text {
      justify-content: center;
       margin: 0;
       width: 100%;
       display:flex;
     }

    .price-container {
      position:absolute;
      top:64%;
      width:90%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      display: flex;
      justify-content: space-between;
    }
    .price-label {
      font-style: arial;
      font-size: 16pt;
      font-weight: bold;
    }
    .rupeeSymbol{
      font-weight: bold;
      font-size: 36pt;
      font-family: Arial;
      padding-top: 8px;
    }
    .percentageSymbol{
      font-weight: bold;
      font-size: 36pt;
      font-family: Arial;
      padding-top: 20px;
    }

    .buyClass{
      width: 40%;
      text-align: center;
      font-weight: bold;
      font-size: 35pt;
      font-family: Arial;
      position : absolute;
      top:35%;
      left:40%;
      border: 1px solid black;
      transform: translateY(-50%) translateX(-50%);
    }
    // .ClassSave3{
    //   width: 75%;
    //   text-align: center;
    //   font-weight: bold;
    //   font-size: 20pt;
    //   font-family: Arial;
    //   position : absolute;
    //   color: blue;
    //   top:35%;
    //   left:40%;
    //   transform: translateY(-50%) translateX(-50%);
    // }

    .savefooter{

    position:fixed;
    left:0;
    bottom:0;
    width:100%;
    color:red;
    text-align:right;
    left-margin: 1px;
    padding: 10px;

  }

    .offer-text-rupee {
      font-weight: bold;
      font-size: 54pt;
      font-family: Arial;
      vertical-align: super;
      position: relative;
    }
    .mrp-container {
      position: absolute;
      right:4%;
      top:59%;
       border: 1px solid black;
    }
    .save-container {
      position: absolute;
      left:4%;
      top:59%
    }
   .description {
      position: absolute;
      padding:0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      top:81%;
      width: 65%;
      text-align: center;
       padding:0;
       color: #000000;
       font-weight: bold;
       font-size: 13pt;
       font-family: Arial;
    }
    .familyDescription{
      position: absolute;
      padding:0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      top:75%;
      width: 65%;
      text-align: center;
       padding:0;
       color: #000000;
       font-weight: bold;
       font-size: 11pt;
       font-family: Arial;
    }

    .description-text {
       text-align: center;
       padding:0;
       color: #000000;
       font-weight: bold;
       font-size: 11pt;
       font-family: Arial;
       width: 100%;
     }

    .footer-date {
      position: absolute;
      bottom: 1%;
      font-weight: bold;
      font-size: 5pt;
      font-family: Arial;
      right: 2%
    }
    .validTo {
      position: absolute;
      bottom: 1%;
      font-weight: bold;
      font-size: 5pt;
      font-family: Arial;
      left:2%;
    }
    @page {
      size: A4;
      margin-top: 2mm;
    }
    @media print {
        html, body {
          width: 210mm;
          height: 297mm;
        }
        div {
          break-inside: avoid;
        }
      .page {
        border: initial;
        border-radius: initial;
        width: initial;
        margin: 5mm auto;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
      }
      .page-without-break {
        margin: 5mm auto;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
      }

    }

    </style>`;
  }
  if (printType === 'A7') {
    return `<style>
    body {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      background-color: #FAFAFA;
      font: 12pt "Arial";
    }
    * {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
    }

    .page {
      width: 100%;
      min-height: 100%;
      // border-radius: 5px;
      background: white;

      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .page-without-break {
      width: 100%;
      min-height: 100%;
      margin: auto auto;
      // border-radius: 5px;
      background: white;
      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .noDisplay{
      display: none;
    }
    .subPage {
      float: left;
      margin: 0;
      padding-top: 5px;
      width: 105mm;
      height: 74.25mm;
      // border: 1px solid black;
      position: relative;
    }
    .transition1{
      transform: translate(-8px,0px)
    }
    .transition2{
      transform: translate(55px,0px)
    }
    .transition3{
      transform: translate(-8px,30px)
    }
    .transition4{
      transform: translate(55px,30px)
    }
    .transition5{
      transform: translate(-8px,100px)
    }
    .transition6{
      transform: translate(55px,100px)
    }
    .transition7{
      transform: translate(-8px,135px)
    }
    .transition8{
      transform: translate(55px,135px)
    }
    .header-area {
      width: 100%;
      position:absolute;
      top:9%;
      color: black;
      font-size: 27pt;
      font-style: arial;
      font-weight: bold;
      text-align: center;
    }

    .header-text {
      color: black;
      font-size: 24pt;
      font-style: arial;
      // padding-bottom: 100px;
      // border:1px solid black;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }

    .center-area {
      width: 100%;
      position:absolute;
      top:25%;
      display: flex;
      flex-direction: column;
    }
    .offer-text {
      justify-content: center;
       margin: 0;
       width: 100%;
       display:flex;
     }

    .price-container {
      position:absolute;
      top:64%;
      width:90%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      display: flex;
      justify-content: space-between;
    }
    .price-label {
      font-style: arial;
      font-size: 16pt;
      font-weight: bold;
    }
    .rupeeSymbol{
      font-weight: bold;
      font-size: 36pt;
      font-family: Arial;
      padding-top: 8px;
    }
    .percentageSymbol{
      font-weight: bold;
      font-size: 36pt;
      font-family: Arial;
      padding-top: 20px;
    }

    .buyClass{
      width: 40%;
      text-align: center;
      font-weight: bold;
      font-size: 38pt;
      font-family: Arial;
      position : absolute;
      top:35%;
      left:40%;
      transform: translateY(-50%) translateX(-50%);
    }
    .ClassSave3{
      width: 75%;
      text-align: center;
      font-weight: bold;
      font-size: 20pt;
      font-family: Arial;
      position : absolute;
      //color: blue;
      top:35%;
      left:40%;
      transform: translateY(-50%) translateX(-50%);
    }

    .savefooter{

    position:fixed;
    left:0;
    bottom:0;
    width:100%;
    //color:red;
    text-align:right;
    left-margin: 1px;
    padding: 10px;

  }

    .offer-text-rupee {
      font-weight: bold;
      font-size: 54pt;
      font-family: Arial;
      vertical-align: super;
      position: relative;
    }
    .mrp-container {
      position: absolute;
      right:4%;
      top:59%;
      // border: 1px solid black;
    }
    .save-container {
      position: absolute;
      left:4%;
      top:59%
    }
   .description {
      position: absolute;
      padding:0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      top:81%;
      width: 65%;
      text-align: center;
       padding:0;
       color: #000000;
       font-weight: bold;
       font-size: 13pt;
       font-family: Arial;
    }
    .familyDescription{
      position: absolute;
      padding:0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      top:75%;
      width: 65%;
      text-align: center;
       padding:0;
       color: #000000;
       font-weight: bold;
       font-size: 11pt;
       font-family: Arial;
    }

    .description-text {
       text-align: center;
       padding:0;
       color: #000000;
       font-weight: bold;
       font-size: 11pt;
       font-family: Arial;
      //  width: 100%;
     }

    .footer-date {
      position: absolute;
      bottom: 1%;
      font-weight: bold;
      font-size: 5pt;
      font-family: Arial;
      right: 2%
    }
    .validTo {
      position: absolute;
      bottom: 1%;
      font-weight: bold;
      font-size: 5pt;
      font-family: Arial;
      left:2%;
    }
    @page {
      size: A4;
      margin-top: 2mm;
    }
    @media print {
        html, body {
          width: 210mm;
          height: 297mm;
        }
        div {
          break-inside: avoid;
        }
      .page {
        border: initial;
        border-radius: initial;
        width: initial;
        margin: 5mm auto;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
      }
      .page-without-break {
        margin: 5mm auto;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
      }

    }

    </style>`;
  }
  return `<style>
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Arial";
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .page {
    width:29.7cm;
    height: 21cm;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .noDisplay{
    display: none;
  }
  .header-area {
    width: 100%;
    position:absolute;
    top:5%;
    color: black;
    font-size: 67pt;
    font-style: arial;
    font-weight: bold;
    text-align: center;
  }

  .center-area {
    width: 100%;
    position:absolute;
    top:23%;
    display: flex;
    flex-direction: column;
  }
  .offer-text {
    justify-content: center;
     margin: 0;
     width: 100%;
     display:flex;
   }

  .price-container {
    position:absolute;
    top:68%;
    width:90%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    justify-content: space-between;
  }
  .price-label {
    font-style: arial;
    font-size: 40pt;
    font-weight: bold;
  }

  .mrp-container{
    position: absolute;
    right:5%;
    top:65%;
  }
  .save-container{
    position: absolute;
    left:5%;
    top:65%
  }
  .rupeeSymbol{
    font-weight: bold;
    font-size: 120pt;
    font-family: Arial;
    padding-top: 20px;
  }
  .percentageSymbol{
    font-weight: bold;
    font-size: 80pt;
    font-family: Arial;
    padding-top: 115px;
  }

  .buyClass{
    width: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 90pt;
    font-family: Arial;
    //color:green;
    position : absolute;
    // line-height:120pt;
    top:36%;
    left:43%;
    transform: translateY(-50%) translateX(-50%);
  }
    .longText{
    width: 80%;
    text-align: center;
    font-weight: bold;
    font-size: 45pt;
    font-family: Arial;
    //color:green;
    position : absolute;
    // line-height:120pt;
    top:36%;
    left:43%;
    transform: translateY(-50%) translateX(-50%);
  }
  .ClassSave3{
    position: absolute;
    padding:1;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top:40%;
    //color:blue;
    width: 80%;
    text-align: center;
    padding:1;
    font-weight: bold;
    font-size: 63pt;
    font-family: Arial;
  }

  .offer-text-rupee {
    font-weight: bold;
    font-family: Arial;
    font-size: 180pt;
    position: relative;

  }

 .description {
    position: absolute;
    padding:0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top:90%;
    width: 65%;
    text-align: center;
    padding:0;
    color: #000000;
    font-weight: bold;
    font-size: 34pt;
    font-family: Arial;
  }
  .familyDescription{
    position: absolute;
    padding:0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top:80%;
    width: 65%;
    text-align: center;
    padding:0;
    color: #000000;
    font-weight: bold;
    font-size: 34pt;
    font-family: Arial;
  }

  .price-text-area {
      padding: 25px;
      float: left;
      font-weight: bold;
      font-style: arial;
      font-size: 38pt;
  }
   .price-text-area2 {
    float:right:
      padding: 25px;
      font-weight: bold;
      font-style: arial;
      font-size: 38pt;
  }

  .savefooter{

    position:fixed;
    left:0;
    bottom:0;
    width:100%;
    //color:red;
    text-align:right;
    left-margin: 200px;

  }

  .footer-text {
    color: #000000;
    font-weight: bold;
    font-size: 34pt;
    font-family: Arial;
    width: 100%;
 }

  .footer-date {
    // float: right;
    font-weight: bold;
    font-size: 10pt;
    font-family: Arial;
    // margin-left:24cm;
    position: absolute;
    bottom: -5%;
    right: 3%;
  }
  .validTo {
    font-weight: bold;
    font-size: 10pt;
    font-family: Arial;
    position: absolute;
    bottom: -5%;
    left: 5%;
  }
  @page {
    size: landscape;
    margin: 0;
  }
  @media print {
      html, body {
        width:29.7cm;
        height: 21cm;
      }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    }
  }
  </style>`;
};

const divideArrInSubArr = data => {
  const divideInSubpartOf = 8;
  let subArr = [];
  const arr = [];
  if (data.length > 0) {
    for (let index = 0; index < data.length; index += 1) {
      const element = data[index];
      if ((index + 1) % divideInSubpartOf === 0) {
        subArr.push(element);
        arr.push(subArr);
        subArr = [];
      } else {
        subArr.push(element);
      }
    }
    if (subArr.length > 0) {
      arr.push(subArr);
    }
    return arr;
  }
  return [];
};

export default {
  getTemplatePrintCss,
  divideArrInSubArr
};
