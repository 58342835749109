<template>
  <div>
    <div v-if="!isComparison" class="component-tablebox">
      <promo-template-a4 v-show="false" :data="templateDataA4" ref="promoTemplateA4"></promo-template-a4>
      <promo-template-a6 v-show="false" :data="templateDataA6" ref="promoTemplateA6"></promo-template-a6>
      <promo-template-a7 v-show="false" :data="templateDataA7" ref="promoTemplateA7"></promo-template-a7>
      <!-- download buttons -->
      <b-row class="table-bottom-wrapper mb-2">
        <b-col sm="12" lg="8" class="align-items-center">
          <b-row>
            <b-col sm="12" lg="12" class="mb-1 align-items-center">
              <div class="mt-2">
                <input
                  :checked="allSelected"
                  :disabled="isDisabled"
                  @click="selectAll"
                  class="allimage"
                  type="checkbox"
                  id="scales"
                />
                <label for="scales" class="px-2">Select all </label>
                <b-button
                  data-v-step="3"
                  size="sm"
                  :class="{
                    'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                    'btn btn-primary text-light mb-2 mr-2': isDarkMode
                  }"
                  @click="printAll('A4')"
                >
                  <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />{{ printTextA4 }}
                </b-button>
                <b-button
                  data-v-step="3"
                  v-b-modal.modal-lg
                  size="sm"
                  :class="{
                    'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                    'btn btn-primary text-light mb-2 mr-2': isDarkMode
                  }"
                  @click="printAll('A7')"
                >
                  <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />{{ printTextA7 }}
                </b-button>
                <b-modal
                  :header-bg-variant="isDarkMode ? 'dark' : 'light'"
                  :header-text-variant="isDarkMode ? 'light' : 'dark'"
                  :body-bg-variant="isDarkMode ? 'dark' : 'light'"
                  :footer-bg-variant="isDarkMode ? 'dark' : 'light'"
                  id="modal-lg"
                  size="lg"
                  v-model="modalVisible"
                  title="Enter number of copy per item"
                >
                  <div v-for="(array, i) in inputSets" :key="i">
                    <b-row class="my-2">
                      <b-col lg="9" sm="12">
                        <input
                          type="text"
                          :class="{
                            'form-control bg-light text-dark': !isDarkMode,
                            'form-control bg-dark text-light placeholder-light': isDarkMode
                          }"
                          disabled
                          :value="array.articleDescription"
                          :placeholder="array.articleDescription"
                      /></b-col>
                      <b-col lg="2" sm="12">
                        <input
                          type="number"
                          min="1"
                          max="3"
                          onkeyup="if(this.value > 3) this.value = 1;"
                          :class="{
                            'form-control inputValues bg-light text-dark': !isDarkMode,
                            'form-control inputValues bg-dark text-light placeholder-light': isDarkMode
                          }"
                          placeholder="1"
                      /></b-col>
                      <b-col lg="1" sm="12">
                        <button
                          @click="removeArticle(i)"
                          :class="{ 'btn btn-danger': !isDarkMode, 'btn btn-outline-danger': isDarkMode }"
                          >X</button
                        ></b-col
                      >
                    </b-row>
                  </div>
                  <template #modal-footer>
                    <button
                      @click="clickModalPrintA7()"
                      :class="{
                        'box-shadow2 btn btn-success': !isDarkMode,
                        'btn btn-primary text-light': isDarkMode
                      }"
                      >Print</button
                    >
                    <button
                      @click="modalVisible = false"
                      :class="{
                        'box-shadow2 btn btn-danger': !isDarkMode,
                        'btn btn-outline-danger text-light': isDarkMode
                      }"
                      >Cancel</button
                    >
                  </template>
                </b-modal>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="d-flex justify-content-end align-items-center">
          <b-button
            data-v-step="3"
            size="sm"
            :class="{
              'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
              'btn btn-primary text-light mb-2 mr-2': isDarkMode
            }"
            @click="clearLocalList()"
          >
            <font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />Clear List
          </b-button>
        </b-col>
      </b-row>
      <!-- table content -->
      <div
        :class="{
          'box-shadow2': !isDarkMode,
          '': isDarkMode
        }"
      >
        <div
          :class="{
            'table-row table-row-header': !isDarkMode,
            'table-row-dark dark-div border-top': isDarkMode
          }"
        >
          <div
            v-for="(column, index) in columns"
            :key="column.id"
            :class="{
              ...column.class,
              'table-column': !isDarkMode,
              'table-column-dark': isDarkMode,
              'table-column-first': index === 0,
              'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
            }"
            :style="columnStyle(column)"
          >
            {{ column.headerText }}
          </div>
        </div>
        <div
          :class="{
            'table-row ': !isDarkMode,
            'table-row-dark': isDarkMode
          }"
          v-for="row in rows"
          :key="row.id"
        >
          <div
            v-for="(column, index) in columns"
            :key="column.id"
            :class="[
              {
                ...column.class,
                'table-column ': !isDarkMode,
                'table-column-dark': isDarkMode,
                'table-column-first': index === 0,
                'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1,
                'table-isPrinted': row.isPrinted === true && !isDarkMode,
                'table-isPrinted-dark': row.isPrinted === true && isDarkMode
              }
            ]"
            :style="columnStyle(column)"
          >
            <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
            <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
            <div v-if="column.urlKey"
              ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
            >
            <div v-if="column.textKey === 'select'">
              <input
                class="checkbox2"
                type="checkbox"
                :disabled="isExpired(row)"
                :checked="selectedArticles.includes(row)"
                @click="select(row)"
              />
            </div>
            <span v-if="column.textKey && column.textKey != 'activeTillTime'" class="span-text">{{
              row[column.textKey]
            }}</span>
            <span v-if="column.textKey === 'activeTillTime'" class="span-text">{{
              showTime(row[column.textKey])
            }}</span>
            <div v-if="column.type === 'functions'" class="text-center">
              <b-button-group>
                <b-button
                  data-v-step="5"
                  v-if="column.functions.edit"
                  size="sm"
                  :disabled="isExpired(row)"
                  @click="printSingleArticle(row, 'A4')"
                  :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                  class="box-shadow2"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Print A4 </b-button
                ><br />
                <b-button
                  data-v-step="5"
                  v-if="column.functions.edit"
                  size="sm"
                  :disabled="isExpired(row)"
                  @click="printSingleArticle(row, 'A7')"
                  :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                  class="box-shadow2"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Print A7
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Comparison List of Articles -->
    <div v-if="isComparison" class="component-tablebox">
      <promo-compare-template-a4
        v-show="false"
        :dataA4="templateCombinedDataA4"
        :listPrint="true"
        ref="promoCompareTemplateA4"
      ></promo-compare-template-a4>
      <promo-compare-template-a7
        v-show="false"
        :dataA7="templateCombinedDataA7"
        :listPrint="true"
        ref="promoCompareTemplateA7"
      ></promo-compare-template-a7>
      <promo-compare-template-a6
        v-show="false"
        :dataA6="templateCombinedDataA6"
        :listPrint="true"
        ref="promoCompareTemplateA6"
      ></promo-compare-template-a6>
      <!-- download buttons -->
      <b-row class="table-bottom-wrapper mb-2">
        <b-col sm="12" lg="8" class="align-items-center">
          <b-row>
            <b-col sm="12" lg="12" class="mb-1">
              <input
                :checked="allCompareSelected"
                :disabled="isCompareDisabled"
                @click="selectAllCompare"
                class="allimage"
                type="checkbox"
                id="scales"
              />
              <label for="scales" class="px-2">Select all </label>
              <!-- <b-button
                data-v-step="3"
                :disabled="this.selectedArticlesCompare.length < 1"
                size="sm"
                :class="{
                  'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                  'btn btn-primary text-light mb-2 mr-2': isDarkMode
                }"
                @click="printAllCompare('A4compare')"
              >
                <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />{{ printTextA4Compare }}
              </b-button>
              <b-button
                data-v-step="3"
                v-b-modal.modal-lg-compare
                :disabled="this.selectedArticlesCompare.length < 1"
                size="sm"
                :class="{
                  'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                  'btn btn-primary text-light mb-2 mr-2': isDarkMode
                }"
                @click="printAllCompare('A7compare')"
              >
                <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />{{ printTextA7Compare }}
              </b-button> -->
              <b-button
                data-v-step="3"
                v-b-modal.modal-lg-compare
                :disabled="this.selectedArticlesCompare.length < 1"
                :variant="isDarkMode ? 'primary' : 'success'"
                size="sm"
                class="mr-2"
                @click="printAllCompare('A6compare')"
              >
                <font-awesome-icon :icon="['fas', 'copy']" class="mr-1" />{{ printTextA6Compare }}
              </b-button>
              <b-modal
                :header-bg-variant="isDarkMode ? 'dark' : 'light'"
                :header-text-variant="isDarkMode ? 'light' : 'dark'"
                :body-bg-variant="isDarkMode ? 'dark' : 'light'"
                :footer-bg-variant="isDarkMode ? 'dark' : 'light'"
                id="modal-lg-compare"
                size="lg"
                v-model="modalVisibleCompare"
                title="Enter number of copy per item"
              >
                <div v-for="(array, i) in inputSetsCompare" :key="i">
                  <b-row class="my-2">
                    <b-col lg="9" sm="12">
                      <input
                        type="text"
                        :class="{
                          'form-control bg-light text-dark': !isDarkMode,
                          'form-control bg-dark text-light placeholder-light': isDarkMode
                        }"
                        disabled
                        :value="array.spencersArticleDescription"
                        :placeholder="array.spencersArticleDescription"
                    /></b-col>
                    <b-col lg="2" sm="12">
                      <input
                        type="number"
                        min="1"
                        max="3"
                        onkeyup="if(this.value > 3) this.value = 1;"
                        :class="{
                          'form-control inputValuesCompare bg-light text-dark': !isDarkMode,
                          'form-control inputValuesCompare bg-dark text-light placeholder-light': isDarkMode
                        }"
                        placeholder="1"
                    /></b-col>
                    <b-col lg="1" sm="12">
                      <button
                        @click="removeArticleCompare(i)"
                        :class="{ 'btn btn-danger': !isDarkMode, 'btn btn-outline-danger': isDarkMode }"
                        >X</button
                      ></b-col
                    >
                  </b-row>
                </div>
                <template #modal-footer>
                  <button
                    @click="clickModalPrintA6Compare()"
                    :class="{
                      'box-shadow2 btn btn-success': !isDarkMode,
                      'btn btn-primary text-light': isDarkMode
                    }"
                    >Print</button
                  >
                  <button
                    @click="modalVisibleCompare = false"
                    :class="{
                      'box-shadow2 btn btn-danger': !isDarkMode,
                      'btn btn-outline-danger text-light': isDarkMode
                    }"
                    >Cancel</button
                  >
                </template>
              </b-modal>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="d-flex justify-content-end align-items-center">
          <b-button
            data-v-step="3"
            size="sm"
            :class="{
              'box-shadow2 btn btn-success': !isDarkMode,
              'btn btn-primary text-light': isDarkMode
            }"
            @click="clearLocalCompareList()"
          >
            <font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />Clear Compare List
          </b-button>
        </b-col>
      </b-row>
      <!-- table content -->
      <div
        :class="{
          'box-shadow2 table-container': !isDarkMode,
          '  ': isDarkMode
        }"
      >
        <div
          :class="{
            'table-row table-row-header': !isDarkMode,
            'table-row-dark dark-div border-top': isDarkMode
          }"
        >
          <div
            v-for="(column, index) in columnsCompare"
            :key="index"
            :class="{
              ...column.class,
              'table-column': !isDarkMode,
              'table-column-dark': isDarkMode,
              'table-column-first': index === 0,
              'table-column-last-odd': columnsCompare.length === index + 1 && (columnsCompare.length - 1) % 2 === 1
            }"
            :style="columnStyle(column)"
          >
            {{ column.headerText }}
          </div>
        </div>
        <div
          :class="{
            'table-row ': !isDarkMode,
            'table-row-dark': isDarkMode
          }"
          v-for="(row, rowIndex) in rowsCompare"
        >
          <div
            v-for="(column, index) in columnsCompare"
            :key="index"
            :class="[
              {
                ...column.class,
                'table-column ': !isDarkMode,
                'table-column-dark': isDarkMode,
                'table-column-first': index === 0,
                'table-column-last-odd': columnsCompare.length === index + 1 && (columnsCompare.length - 1) % 2 === 1,
                'table-isPrinted': row.isPrinted === true
              }
            ]"
            :style="columnStyle(column)"
          >
            <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
            <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
            <div v-if="column.urlKey"
              ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
            >
            <div v-if="column.textKey === 'select'">
              <input
                class="checkbox2"
                type="checkbox"
                :checked="selectedArticlesCompare.includes(row)"
                @click="selectCompare(row)"
              />
            </div>

            <div v-if="column.textKey === 'mrpSpencers'">
              <b-form-input
                type="number"
                :class="{
                  'inputBox box-shadow3 bg-light text-dark': !isDarkMode,
                  'inputBox box-shadow3 bg-dark text-light placeholder-light': isDarkMode
                }"
                v-model="row.mrpSpencers"
              ></b-form-input>
            </div>
            <div v-if="column.textKey === 'offerSpencers'">
              <b-form-input
                type="number"
                :class="{
                  'inputBox box-shadow3 bg-light text-dark': !isDarkMode,
                  'inputBox box-shadow3 bg-dark text-light placeholder-light': isDarkMode
                }"
                v-model="row.offerSpencers"
              ></b-form-input>
            </div>
            <div v-if="column.textKey === 'mrpLeadingBrand'">
              <b-form-input
                type="number"
                :class="{
                  'inputBox box-shadow3 bg-light text-dark': !isDarkMode,
                  'inputBox box-shadow3 bg-dark text-light placeholder-light': isDarkMode
                }"
                v-model="row.mrpLeadingBrand"
              ></b-form-input>
            </div>
            <div v-if="column.textKey === 'offerLeadingBrand'">
              <b-form-input
                type="number"
                :class="{
                  'inputBox box-shadow3 bg-light text-dark': !isDarkMode,
                  'inputBox box-shadow3 bg-dark text-light placeholder-light': isDarkMode
                }"
                v-model="row.offerLeadingBrand"
              ></b-form-input>
            </div>
            <span v-if="column.textKey === 'index'" class="span-text">{{ rowIndex + 1 }}</span>
            <span
              v-if="
                column.textKey &&
                column.textKey != 'activeTillTime' &&
                column.textKey != 'mrpSpencers' &&
                column.textKey != 'offerSpencers' &&
                column.textKey != 'mrpLeadingBrand' &&
                column.textKey != 'offerLeadingBrand'
              "
              class="span-text"
              >{{ row[column.textKey] }}</span
            >
            <span v-if="column.textKey === 'activeTillTime'" class="span-text">{{
              showTime(row[column.textKey])
            }}</span>
            <div v-if="column.type === 'functions'" class="text-center">
              <b-button-group>
                <!-- <b-button
                  data-v-step="5"
                  size="sm"
                  @click="printSingleArticleCompare(row, 'A4compare')"
                  :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                  class="box-shadow2"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Print A4 </b-button
                ><br />
                <b-button
                  data-v-step="5"
                  size="sm"
                  @click="printSingleArticleCompare(row, 'A7compare')"
                  :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                  class="box-shadow2"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Print A7
                </b-button>-->
                <b-button
                  data-v-step="5"
                  size="sm"
                  @click="printSingleArticleCompare(row, 'A6compare')"
                  :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Print A6
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import PromoTemplateA4 from '@/components/PromoTemplateA4.vue';
import PromoTemplateA6 from '@/components/PromoTemplateA6.vue';
import PromoTemplateA7 from '@/components/PromoTemplateA7.vue';

import PromoCompareTemplateA4 from '@/components/PromoCompareTemplateA4.vue';
import PromoCompareTemplateA6 from '@/components/PromoCompareTemplateA6.vue';
import PromoCompareTemplateA7 from '@/components/PromoCompareTemplateA7.vue';
import PrintTemplate from '@/helper/printTemplateHelper';
import PrintCompareTemplate from '@/helper/printCompareTemplateHelper';

export default {
  name: 'PopManualFormBox',
  components: {
    PromoTemplateA4,
    PromoTemplateA7,
    PromoTemplateA6,
    PromoCompareTemplateA4,
    PromoCompareTemplateA6,
    PromoCompareTemplateA7
  },
  props: {
    columns: Array,
    rows: Array,
    columnsCompare: Array,
    rowsCompare: Array,
    printTextA4: String,
    printTextA7: String,
    printTextA6: String,
    printTextA4Compare: String,
    printTextA7Compare: String,
    printTextA6Compare: String,
    isComparison: Boolean
  },
  data() {
    return {
      appendedText: 'List',
      modalVisible: false,
      modalVisibleCompare: false,
      inputSets: [],
      inputSetsCompare: [],
      selectedArticles: [],
      selectedArticlesCompare: [],
      allSelected: false,
      allCompareSelected: false,
      templateDataA7: [],
      templateDataA6: [],
      templateDataA4: [],
      templateCombinedDataA4: [],
      templateCombinedDataA6: [],
      templateCombinedDataA7: [],
      isDisabled: false,
      isCompareDisabled: false,
      selectedArticleCount: 0,
      arrayOfInputFieldValues: [],
      mrpSpencers: '',
      offerSpencers: '',
      mrpLeadingBrand: '',
      offerLeadingBrand: ''
    };
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    selectedArticles(newValue) {
      this.selectedArticleCount = newValue.length;
    },
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedArticles = [];
        this.allSelected = false;
        if (newValue !== null) {
          this.appendedText = newValue;
        } else {
          this.appendedText = 'List';
        }
      }
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
      if (newValue === 'DEACTIVATED PROMO' || newValue === 'EXPIRED PROMO') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
  },
  mounted() {},
  methods: {
    clearLocalList() {
      this.$emit('clearList');
    },
    clearLocalCompareList() {
      this.selectedArticlesCompare = [];
      this.rowsCompare.map(item => {
        item.isPrinted = false;
        item.mrpSpencers = '';
        item.offerSpencers = '';
        item.mrpLeadingBrand = '';
        item.offerLeadingBrand = '';
      });
    },
    isExpired(row) {
      if (row.delta === 'DEACTIVATED PROMO' || row.delta === 'EXPIRED PROMO') {
        return true;
      }
      return false;
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedArticles = [];
        this.allSelected = false;
      } else {
        this.selectedArticles = this.rows.filter(row => row.articleDescription);
        this.allSelected = true;
      }
    },
    selectAllCompare() {
      if (this.allSelectedCompare) {
        this.selectedArticlesCompare = [];
        this.allSelectedCompare = false;
      } else {
        this.selectedArticlesCompare = this.rowsCompare.filter(row => row.spencersArticleDescription);
        this.allSelectedCompare = true;
      }
    },
    select(row) {
      this.allSelected = false;
      if (this.selectedArticles.includes(row)) {
        this.selectedArticles = this.selectedArticles.filter(i => i !== row);
      } else {
        this.selectedArticles = [...this.selectedArticles, row];
      }
    },
    selectCompare(row) {
      this.allSelectedCompare = false;
      if (this.selectedArticlesCompare.includes(row)) {
        this.selectedArticlesCompare = this.selectedArticlesCompare.filter(i => i !== row);
      } else {
        this.selectedArticlesCompare = [...this.selectedArticlesCompare, row];
      }
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    removeArticle(index) {
      this.inputSets.splice(index, 1);
    },
    removeArticleCompare(index) {
      this.inputSetsCompare.splice(index, 1);
    },
    clickModalPrintA7() {
      this.modalVisible = false;
      const inputs = document.getElementsByClassName('inputValues');
      this.arrayOfInputFieldValues = [];
      for (let i = 0; i < inputs.length; i++) {
        if (Number(inputs[i].value) <= 0) {
          this.arrayOfInputFieldValues.push(1);
        } else this.arrayOfInputFieldValues.push(Number(inputs[i].value));
      }
      const arrOfArticlesToPrint = this.selectedArticles
        .map((item, index) => {
          return Array(this.arrayOfInputFieldValues[index]).fill(item);
        })
        .flat();
      this.templateDataA7 = PrintTemplate.divideArrInSubArr(arrOfArticlesToPrint);
      this.printCommand('A7');
      this.printedCommand();
    },
    clickModalPrintA6() {
      this.modalVisible = false;
      const inputs = document.getElementsByClassName('inputValues');
      this.arrayOfInputFieldValues = [];
      for (let i = 0; i < inputs.length; i++) {
        if (Number(inputs[i].value) <= 0) {
          this.arrayOfInputFieldValues.push(1);
        } else this.arrayOfInputFieldValues.push(Number(inputs[i].value));
      }
      const arrOfArticlesToPrint = this.selectedArticles
        .map((item, index) => {
          return Array(this.arrayOfInputFieldValues[index]).fill(item);
        })
        .flat();
      this.templateDataA6 = PrintTemplate.divideArrInSubArr(arrOfArticlesToPrint);
      this.printCommand('A6');
      this.printedCommand();
    },
    clickModalPrintA7Compare() {
      this.modalVisibleCompare = false;
      const inputs = document.getElementsByClassName('inputValuesCompare');
      this.arrayOfInputFieldValues = [];
      for (let i = 0; i < inputs.length; i++) {
        if (Number(inputs[i].value) <= 0) {
          this.arrayOfInputFieldValues.push(1);
        } else this.arrayOfInputFieldValues.push(Number(inputs[i].value));
      }
      const arrOfArticlesToPrint = this.selectedArticlesCompare
        .map((item, index) => {
          return Array(this.arrayOfInputFieldValues[index]).fill(item);
        })
        .flat();
      this.templateCombinedDataA7 = PrintCompareTemplate.divideArrInSubArr(arrOfArticlesToPrint);
      this.printCommandCompare('A7compare');
      this.printedCommandCompare();
    },
    clickModalPrintA6Compare() {
      this.modalVisibleCompare = false;
      const inputs = document.getElementsByClassName('inputValuesCompare');
      this.arrayOfInputFieldValues = [];
      for (let i = 0; i < inputs.length; i++) {
        if (Number(inputs[i].value) <= 0) {
          this.arrayOfInputFieldValues.push(1);
        } else this.arrayOfInputFieldValues.push(Number(inputs[i].value));
      }
      const arrOfArticlesToPrint = this.selectedArticlesCompare
        .map((item, index) => {
          return Array(this.arrayOfInputFieldValues[index]).fill(item);
        })
        .flat();
      this.templateCombinedDataA6 = PrintCompareTemplate.divideArrInSubArr(arrOfArticlesToPrint);
      this.printCommandCompare('A6compare');
      this.printedCommandCompare();
    },
    async printAll(printType) {
      if (printType === 'A7') {
        if (this.selectedArticles.length > 0) {
          this.modalVisible = true;
          this.inputSets = this.selectedArticles;
        }
      } else if (printType === 'A6') {
        if (this.selectedArticles.length > 0) {
          this.modalVisible = true;
          this.inputSets = this.selectedArticles;
        }
      } else {
        this.templateDataA4 = this.selectedArticles;
        this.printCommand(printType);
        this.printedCommand();
      }
    },
    async printAllCompare(printTypeCompare) {
      const articlesToPrint = this.selectedArticlesCompare;
      const findEmpty = this.selectedArticlesCompare.find(
        item => !item.mrpSpencers || !item.offerSpencers || !item.mrpLeadingBrand || !item.offerLeadingBrand
      );
      const findNotValid = this.selectedArticlesCompare.find(
        item => Number(item.offerSpencers) > Number(item.offerLeadingBrand)
      );
      if (findEmpty) {
        Vue.swal({
          title: `Empty Fields`,
          text: "MRP's and Offer's must be filled ",
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          result.value ? (this.modalVisibleCompare = false) : (this.modalVisibleCompare = true);
        });
      } else if (findNotValid) {
        Vue.swal({
          title: `Offer Price`,
          text: 'Spencers Offer Price must be less than the Leading Brand offer',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          result.value ? (this.modalVisibleCompare = false) : (this.modalVisibleCompare = true);
        });
      } else if (printTypeCompare === 'A7compare' && articlesToPrint.length > 0) {
        this.inputSetsCompare = articlesToPrint;
        this.modalVisibleCompare = true;
      } else if (printTypeCompare === 'A6compare' && articlesToPrint.length > 0) {
        this.inputSetsCompare = articlesToPrint;
        this.modalVisibleCompare = true;
      } else {
        this.templateCombinedDataA4 = articlesToPrint;
        this.printCommandCompare(printTypeCompare);
        this.printedCommandCompare();
      }
    },
    printSingleArticle(row, type) {
      this.selectedArticles = [];
      this.select(row);
      this.printAll(type);
    },
    printSingleArticleCompare(row, type) {
      if (!row.mrpSpencers || !row.mrpLeadingBrand || !row.offerSpencers || !row.offerLeadingBrand) {
        Vue.swal({
          title: `Empty Fields of ${row.spencersArticleCode}`,
          text: "MRP's and Offer's must be filled ",
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          result.value ? (this.modalVisibleCompare = false) : (this.modalVisibleCompare = true);
        });
      } else if (Number(row.offerSpencers) > Number(row.offerLeadingBrand)) {
        Vue.swal({
          title: `Offer Price of ${row.spencersArticleCode}`,
          text: 'Spencers Offer Price must be less than the Leading Brand offer',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          result.value ? (this.modalVisibleCompare = false) : (this.modalVisibleCompare = true);
        });
      } else {
        this.selectedArticlesCompare = [];
        this.selectCompare(row);
        this.printAllCompare(type);
      }
    },
    printCommand(printType) {
      const stylesHtml =
        printType === 'A7'
          ? PrintTemplate.getTemplatePrintCss('A7')
          : printType === 'A6'
          ? PrintTemplate.getTemplatePrintCss('A6')
          : PrintTemplate.getTemplatePrintCss('A4');
      setTimeout(() => {}, 2000);
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      setTimeout(() => {
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${
              printType === 'A7'
                ? this.$refs.promoTemplateA7.$el.innerHTML
                : printType === 'A6'
                ? this.$refs.promoTemplateA6.$el.innerHTML
                : this.$refs.promoTemplateA4.$el.innerHTML
            }
          </body>
        </html>`);
      }, 1000);
      setTimeout(() => {
        WinPrint.document.close();
      }, 1000);
      setTimeout(() => {
        WinPrint.focus();
      }, 1000);
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 2000);
    },
    printCommandCompare(printTypeCompare) {
      const stylesHtml =
        printTypeCompare === 'A7compare'
          ? PrintCompareTemplate.getTemplatePrintCss('A7compare')
          : printTypeCompare === 'A6compare'
          ? PrintCompareTemplate.getTemplatePrintCss('A6compare')
          : PrintCompareTemplate.getTemplatePrintCss('A4compare');
      setTimeout(() => {}, 2000);
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      setTimeout(() => {
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${
              printTypeCompare === 'A7compare'
                ? this.$refs.promoCompareTemplateA7.$el.innerHTML
                : printTypeCompare === 'A6compare'
                ? this.$refs.promoCompareTemplateA6.$el.innerHTML
                : this.$refs.promoCompareTemplateA4.$el.innerHTML
            }
          </body>
        </html>`);
      }, 1000);
      setTimeout(() => {
        WinPrint.document.close();
      }, 1000);
      setTimeout(() => {
        WinPrint.focus();
      }, 1000);
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 2000);
    },
    printedCommand() {
      Vue.swal({
        title: 'Mark these items printed',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Marked Printed',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDismissed) {
          return null;
        }
        if (result.value) {
          const articleList = this.selectedArticles.map(item => item.articleCode);
          this.$emit('update', { articleList });
        }
        this.selectedArticles = [];
      });
    },
    printedCommandCompare() {
      Vue.swal({
        title: 'Mark these items printed',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Marked Printed',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDismissed) {
          return null;
        }
        if (result.value) {
          const printedArticles = this.selectedArticlesCompare.map(item => (item.isPrinted = true));
          this.templateCombinedDataA4 = [];
          this.templateCombinedDataA6 = [];
          this.templateCombinedDataA7 = [];
        }
      });
    },
    columnStyle(column) {
      return { width: column.width };
    }
  }
};
</script>

<style scoped>
.inputBox::-webkit-outer-spin-button,
.inputBox::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.component-tablebox {
  font-size: 0.9rem;
}

.swal-content__input {
  margin: 5px 0;
}

.inputCtr {
  width: 700px !important;
}

.table-top-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: left;
}

.table-container {
  display: block;
  width: 100%;
}

.row-total-count {
  margin-bottom: 0.5rem;
}

.row-total-count span {
  font-weight: bold;
}

.table-row {
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px #d9d9d9;
  transition: 0.5s;
  margin-left: 0;
  margin-right: 0;
  /* border: 2px solid black; */
}

.table-row:first-of-type {
  border-top: solid 1px #d9d9d9;
  border-left: solid 1px #d9d9d9;
  border-bottom: solid 2px #d9d9d9;
}

.table-column {
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  word-wrap: break-word;
}

.allimage {
  width: 22px;
  height: 22px;
  /* border: 1px solid black; */
}

.table-columns {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
}

.table-columns .table-column {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}

.table-columns .table-column:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-isPrinted {
  background: #f5c9a9 !important;
}

.table-row:first-of-type .table-column {
  background: #fff;
  color: #000;
  border-color: #d9d9d9;
  font-weight: bold;
}

.table-row:nth-child(odd) .table-column {
  background: #f4f2f1;
}

.table-row:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.table-column-full {
  width: 100%;
}

.table-column-rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.flex-cell {
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
}

@media all and (max-width: 767px) {
  .table-row {
    border-bottom: solid 2px #d9d9d9;
    border-right: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 50% !important;
    border: 0;
    text-align: left !important;
    background: #fff;
  }

  .table-row .table-column.table-column-first {
    border-bottom: solid 1px #d9d9d9;
    width: 100% !important;
  }

  .table-row .table-column.table-column-last-odd {
    width: 100% !important;
  }
}

@media all and (max-width: 430px) {
  .table-column {
    width: 100%;
  }

  .column .table-column {
    border-bottom: solid 1px;
  }

  .table-column.table-column-first {
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
  }

  .table-row .table-column {
    width: 100% !important;
    border-bottom: 0;
    text-align: left !important;
  }

  .table-row .table-column:last-of-type {
    border-bottom: solid 1px #d9d9d9;
  }

  .table-columns {
    width: 100%;
  }

  .table-columns.table-column {
    border-bottom: solid 1px #d9d9d9;
  }

  .flex-cell {
    width: 100%;
  }
}
</style>
