const popCompareArticles = [
  {
    spencersArticleCode: 1007594,
    spencersArticleDescription: 'SMART CHOICE MASALA NDLS 180G',
    leadingBrandArticleCode: 1007590,
    leadingBrandArticleDescription: 'CHINGS SECRET VEG HAKKA NDLS 150G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1140164,
    spencersArticleDescription: 'SMART CHOICE MIX PICKLE 400G BTL',
    leadingBrandArticleCode: 1016326,
    leadingBrandArticleDescription: 'MOTHERS RECIPE  MIXED PCKL 400G PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1140165,
    spencersArticleDescription: 'SMART CHOICE GREEN CHILLI PICKL 400G BTL',
    leadingBrandArticleCode: 1016318,
    leadingBrandArticleDescription: 'MOTHERS RECIPE GR CHILLI  PCKL 400G PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1140166,
    spencersArticleDescription: 'SPENCER SMT CH LIME JUICE 250ML PET',
    leadingBrandArticleCode: 1008798,
    leadingBrandArticleDescription: 'DABUR LEMONEEZE CORDIAL 250ML BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1150972,
    spencersArticleDescription: 'SMART CHOICE LIME PICKLE 400G BTL',
    leadingBrandArticleCode: 1016322,
    leadingBrandArticleDescription: 'MOTHERS RECIPE  LIME PCKL 400G PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1150973,
    spencersArticleDescription: 'SMART CHOICE MANGO PICKLE 400G BTL',
    leadingBrandArticleCode: 1016324,
    leadingBrandArticleDescription: 'MOTHERS RECIPE  MNGO PCKL 400G PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1249755,
    spencersArticleDescription: 'SMART CH PURE INSTNT 50g COFFEE POUCH',
    leadingBrandArticleCode: 1008887,
    leadingBrandArticleDescription: 'BRU 50g COFFE POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1249756,
    spencersArticleDescription: 'SMART CH CHICORY  50g COFFEE POUCH',
    leadingBrandArticleCode: 1008887,
    leadingBrandArticleDescription: 'BRU 50g COFFE POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1261549,
    spencersArticleDescription: 'SPENCERS SMT CH GUAVA DRINK 1000ML PET',
    leadingBrandArticleCode: 1394123,
    leadingBrandArticleDescription: 'MINUTE MAID GRITTY GUAVA 1000ML PET BOTT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1261550,
    spencersArticleDescription: 'SPENCERS SMT CH LITCHI DRINK 1000ML PET',
    leadingBrandArticleCode: 1008691,
    leadingBrandArticleDescription: 'DABUR REAL LITCHI JUICE 1000ML TET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1266460,
    spencersArticleDescription: 'SMART CH PURE INSTANT 50g COFFEE JAR',
    leadingBrandArticleCode: 1008900,
    leadingBrandArticleDescription: 'NESCAFE CLASSIC 50g COFFEE JAR',
    isPrinted: false
  },
  {
    spencersArticleCode: 1277729,
    spencersArticleDescription: 'SPENCERS SMT CH APPLE DRINK 1000ML PET',
    leadingBrandArticleCode: 1416369,
    leadingBrandArticleDescription: 'Minute Maid Honey Infused Apple 1L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1286594,
    spencersArticleDescription: 'SMART CH  GREEN TEA BAG 25S BOX',
    leadingBrandArticleCode: 1228496,
    leadingBrandArticleDescription: 'TETLEY GRN 25S TEA BAG BOX',
    isPrinted: false
  },
  {
    spencersArticleCode: 1290510,
    spencersArticleDescription: 'SMART CH  TEA BAG 25S BOX',
    leadingBrandArticleCode: 1008961,
    leadingBrandArticleDescription: 'TAJMAHAL 25S TEA BAG BOX',
    isPrinted: false
  },
  {
    spencersArticleCode: 1293515,
    spencersArticleDescription: 'BB FLOWER LOOFAH',
    leadingBrandArticleCode: 1236752,
    leadingBrandArticleDescription: 'VEGA LOOFAH - CUBES - 50GM',
    isPrinted: false
  },
  {
    spencersArticleCode: 1306550,
    spencersArticleDescription: 'SMART CHOICE VEG NDLS 180G',
    leadingBrandArticleCode: 1007590,
    leadingBrandArticleDescription: 'CHINGS SECRET VEG HAKKA NDLS 150G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1306551,
    spencersArticleDescription: 'SMART CHOICE EGG NDLS 180G',
    leadingBrandArticleCode: 1170027,
    leadingBrandArticleDescription: 'WAI WAI HAKKA NOODLES EGG 180G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1319005,
    spencersArticleDescription: 'SMART CHOICE ELBOW PASTA 500G',
    leadingBrandArticleCode: 1377577,
    leadingBrandArticleDescription: 'WEIKFIELD PASTA ELBOW 400 G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1322909,
    spencersArticleDescription: 'BB ROUND HAIR BRUSH',
    leadingBrandArticleCode: 1118923,
    leadingBrandArticleDescription: 'VEGA HAIR ROUND BRUSH -E3RB',
    isPrinted: false
  },
  {
    spencersArticleCode: 1322910,
    spencersArticleDescription: 'BB LICE COMB',
    leadingBrandArticleCode: 1307843,
    leadingBrandArticleDescription: 'GUBB HAIR COMB LICE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341227,
    spencersArticleDescription: 'DOUBLETICK FRESH ROSE ARFRSHNR BLOCK 75G',
    leadingBrandArticleCode: 1049792,
    leadingBrandArticleDescription: 'ODONIL LVNDR MEADOW ARFRSHNR BLOCK 75G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341228,
    spencersArticleDescription: 'DOUBLETICK FRESH ROSE ARFRSHNR BLOCK100G',
    leadingBrandArticleCode: 1261066,
    leadingBrandArticleDescription: 'ODONIL ROOM FRESHENING GEL ROSE 75g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341229,
    spencersArticleDescription: 'DOUBLETICK FRESH MOGRA ARFRSHNR BLOCK75G',
    leadingBrandArticleCode: 1085990,
    leadingBrandArticleDescription: 'ODONIL JASMINE MIST ARFRSHNR BLOCK 75g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341230,
    spencersArticleDescription: 'DOUBLETICK FRESH LVNDR ARFRSH BLOCK75G',
    leadingBrandArticleCode: 1049792,
    leadingBrandArticleDescription: 'ODONIL LVNDR MEADOW ARFRSHNR BLOCK 75G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341231,
    spencersArticleDescription: 'DOUBLETICK FRESH LVNDR ARFRSH BLOCK 100G',
    leadingBrandArticleCode: 1261067,
    leadingBrandArticleDescription: 'ODONIL ROOM FRESHENING GEL LAVANDER 75g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341234,
    spencersArticleDescription: 'HANDS ON JASMINE PHENYL FLR CLNR 2X1L',
    leadingBrandArticleCode: 1011357,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF CITRUS FLR CLNR 975ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341235,
    spencersArticleDescription: 'HANDS ON PINE PHENYL FLR CLNR KIT2X1L',
    leadingBrandArticleCode: 1011357,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF CITRUS FLR CLNR 975ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341235,
    spencersArticleDescription: 'HANDS ON PINE PHENYL FLR CLNR KIT2X1L',
    leadingBrandArticleCode: 1011357,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF CITRUS FLR CLNR 975ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341236,
    spencersArticleDescription: 'BB SHAVING BRUSH',
    leadingBrandArticleCode: 1010891,
    leadingBrandArticleDescription: 'GILLETTE SHVING BRUSH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341241,
    spencersArticleDescription: 'DT CTC LEAF TEA 500G TEA POUCH',
    leadingBrandArticleCode: 1008950,
    leadingBrandArticleDescription: 'TATA TEA GOLD LEAF 500g TEA POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341242,
    spencersArticleDescription: 'DT CTC LEAF TEA 1KG TEA POUCH',
    leadingBrandArticleCode: 1008948,
    leadingBrandArticleDescription: 'TATA TEA GOLD LEAF 1kg TEA POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341244,
    spencersArticleDescription: 'HANDS ON SF CLNR CITRUS 975ML',
    leadingBrandArticleCode: 1399133,
    leadingBrandArticleDescription: 'Lizol IN DFC CIT 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341244,
    spencersArticleDescription: 'HANDS ON SF CLNR CITRUS 975ML',
    leadingBrandArticleCode: 1399133,
    leadingBrandArticleDescription: 'Lizol IN DFC CIT 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341245,
    spencersArticleDescription: 'HANDS ON SF CLNR FLORAL 975ML',
    leadingBrandArticleCode: 1399133,
    leadingBrandArticleDescription: 'Lizol IN DFC CIT 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341245,
    spencersArticleDescription: 'HANDS ON SF CLNR FLORAL 975ML',
    leadingBrandArticleCode: 1399133,
    leadingBrandArticleDescription: 'Lizol IN DFC CIT 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341246,
    spencersArticleDescription: 'HANDS ON SF CLNR PINE 975ML',
    leadingBrandArticleCode: 1399133,
    leadingBrandArticleDescription: 'Lizol IN DFC CIT 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341246,
    spencersArticleDescription: 'HANDS ON SF CLNR PINE 975ML',
    leadingBrandArticleCode: 1399133,
    leadingBrandArticleDescription: 'Lizol IN DFC CIT 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341248,
    spencersArticleDescription: 'BB ACTIVE CLEAN TOTH BRUSH VALUE PK 6S',
    leadingBrandArticleCode: 1125768,
    leadingBrandArticleDescription: `ORAL B CAVITY DEFENCE 4'S`,
    isPrinted: false
  },
  {
    spencersArticleCode: 1341249,
    spencersArticleDescription: 'BB EXTRA SENSITIVE TOTH BRSH VALUE PK4S',
    leadingBrandArticleCode: 1335232,
    leadingBrandArticleDescription: 'SENSODYNE TOOTH BRUSH 4S',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341250,
    spencersArticleDescription: 'BB TONGUE CLEANER',
    leadingBrandArticleCode: 1329480,
    leadingBrandArticleDescription: 'GUBB TONGUE CLEANER 1S',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341253,
    spencersArticleDescription: 'BB PROTECT HANDWASH 750ML REF PKT',
    leadingBrandArticleCode: 1291105,
    leadingBrandArticleDescription: 'DETTOL LHW POUCH ORG 750ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341254,
    spencersArticleDescription: 'BB PEACH HANDWASH 750ML REF PKT',
    leadingBrandArticleCode: 1019728,
    leadingBrandArticleDescription: 'LIFEBOUY HW NATURE 800ml-900ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341255,
    spencersArticleDescription: 'BB SANDAL HANDWASH 750ML REF PKT',
    leadingBrandArticleCode: 1291106,
    leadingBrandArticleDescription: 'DETTOL LHW POUCH SKC 750ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341256,
    spencersArticleDescription: 'BB GREEN APPLE HANDWASH 750ML REF PKT',
    leadingBrandArticleCode: 1301724,
    leadingBrandArticleDescription: 'DETTOL LHW POUCH REEN 750ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341257,
    spencersArticleDescription: 'BB STRAWBERRY HANDWASH 750ML REF PKT',
    leadingBrandArticleCode: 1301723,
    leadingBrandArticleDescription: 'DETTOL LHW POUCH SENS 750ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341259,
    spencersArticleDescription: 'BATH & BEAUTY COLOUR COTTON BALLS 50P PK',
    leadingBrandArticleCode: 1329478,
    leadingBrandArticleDescription: 'GUBB COTTON COLORED BALLS 50S',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341262,
    spencersArticleDescription: 'HANDS ON KIT 1L FC+1L TC+500ML DLIQ',
    leadingBrandArticleCode: 1301150,
    leadingBrandArticleDescription: 'HARPIC1L+LIZOL 975ML+BTHRM CLR 500ML KIT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341264,
    spencersArticleDescription: 'HANDS ON GLASS CLEANER 500ML',
    leadingBrandArticleCode: 1011400,
    leadingBrandArticleDescription: 'COLIN GLASS CLEANER 500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341264,
    spencersArticleDescription: 'HANDS ON GLASS CLEANER 500ML',
    leadingBrandArticleCode: 1011400,
    leadingBrandArticleDescription: 'COLIN GLASS CLEANER 500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341265,
    spencersArticleDescription: 'HANDS ON DISHWASH LIQ 500ML',
    leadingBrandArticleCode: 1050443,
    leadingBrandArticleDescription: 'VIMDROP DISHWSH LIQ 500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341265,
    spencersArticleDescription: 'HANDS ON DISHWASH LIQ 500ML',
    leadingBrandArticleCode: 1050443,
    leadingBrandArticleDescription: 'VIMDROP DISHWSH LIQ 500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341266,
    spencersArticleDescription: 'HANDS ON TOILT CLNR MLTIPCK 3X500ML',
    leadingBrandArticleCode: 1324647,
    leadingBrandArticleDescription: 'HARPIC 3x600ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341266,
    spencersArticleDescription: 'HANDS ON TOILT CLNR MLTIPCK 3X500ML',
    leadingBrandArticleCode: 1324647,
    leadingBrandArticleDescription: 'HARPIC 3x600ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341268,
    spencersArticleDescription: 'DT CTC LEAF TEA 250 G TEA POUCH',
    leadingBrandArticleCode: 1008934,
    leadingBrandArticleDescription: 'BROOKE BOND TAJ MAHAL 250g-275gTEA POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341356,
    spencersArticleDescription: 'SMART CHOICE  Cornflk Pch 500G',
    leadingBrandArticleCode: 1287920,
    leadingBrandArticleDescription: 'KELLOGGS  Cornflk Pch 290G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341357,
    spencersArticleDescription: 'SMART CHOICE  Oats Pch 1Kg',
    leadingBrandArticleCode: 1060189,
    leadingBrandArticleDescription: 'QUAKER  Oats Pch 1Kg',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341358,
    spencersArticleDescription: 'SMART CHOICE Honey Pet 250G ',
    leadingBrandArticleCode: 1008146,
    leadingBrandArticleDescription: 'DABUR HONEY Btl 250G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341359,
    spencersArticleDescription: 'SMART CHOICE Honey Pet 500G',
    leadingBrandArticleCode: 1008148,
    leadingBrandArticleDescription: 'DABUR HONEY Btl 500G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341360,
    spencersArticleDescription: 'SMART CHOICE Honey Pet Pet 1Kg',
    leadingBrandArticleCode: 1008145,
    leadingBrandArticleDescription: 'DABUR HONEY Btl 1Kg',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341361,
    spencersArticleDescription: 'SMART CHOICE SOYA SAUCE 350G',
    leadingBrandArticleCode: 1008241,
    leadingBrandArticleDescription: 'CHINGS DARK SOYA SAUCE 200G BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341362,
    spencersArticleDescription: 'SMART CHOICE VINEGAR 650ml',
    leadingBrandArticleCode: 1148347,
    leadingBrandArticleDescription: 'TOPS VINEGAR 610ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341363,
    spencersArticleDescription: 'SMART CHOICE  Cornflk Bx 250G',
    leadingBrandArticleCode: 1008444,
    leadingBrandArticleDescription: 'KELLOGGS  Cornflk Bx 250G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341364,
    spencersArticleDescription: 'SMART CHOICE  Cornflk Bx 475G',
    leadingBrandArticleCode: 1008445,
    leadingBrandArticleDescription: 'KELLOGGS  Cornflk Bx 475G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341365,
    spencersArticleDescription: 'SMART CHOICE  Chocos Cornflk Bx 375G',
    leadingBrandArticleCode: 1008440,
    leadingBrandArticleDescription: 'KELLOGGS Chocos Cornflk Pch 385G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341366,
    spencersArticleDescription: 'SMART CHOICE  Chocos Cornflk Bx 700G',
    leadingBrandArticleCode: 1008441,
    leadingBrandArticleDescription: 'Kelloggs chocos 715g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341367,
    spencersArticleDescription: 'SMART CHOICE Straw Musli Bx 425G',
    leadingBrandArticleCode: 1134267,
    leadingBrandArticleDescription: 'KELLOGGS FRUIT MAGIC MUSLI PCH 500G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341368,
    spencersArticleDescription: 'SMART CHOICE Fruit&Nut Musli Bx 425G',
    leadingBrandArticleCode: 1259522,
    leadingBrandArticleDescription: 'KELLOGGS FRUIT&NUT MUSLI PCH 500G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341369,
    spencersArticleDescription: 'SMART CHOICE Classic Musli Bx 425G',
    leadingBrandArticleCode: 1259519,
    leadingBrandArticleDescription: 'KELLOGGS NO ADDED SUGAR MUSLI PCH 500G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341370,
    spencersArticleDescription: 'SMART CHOICE Honey Musli Bx 425G',
    leadingBrandArticleCode: 1134265,
    leadingBrandArticleDescription: 'KELLOGGS NUT DELIGHT MUSLI PCH 500G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341371,
    spencersArticleDescription: 'SMART CHOICE MUSTARD KASUNDI 200G',
    leadingBrandArticleCode: 1050990,
    leadingBrandArticleDescription: 'DRUK KASUNDI 200G PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341372,
    spencersArticleDescription: 'SMART CHOICE PENNE PASTA 500G',
    leadingBrandArticleCode: 1377578,
    leadingBrandArticleDescription: 'WEIKFIELD PASTA PENNE 400 G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341373,
    spencersArticleDescription: 'SMART CHOICE FUSILLI PASTA 500G',
    leadingBrandArticleCode: 1377579,
    leadingBrandArticleDescription: 'WEIKFIELD PASTA FUSILLI 400 G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341375,
    spencersArticleDescription: 'SMART CHOICE VERMICELLI PLAIN 400G-450g',
    leadingBrandArticleCode: 1007659,
    leadingBrandArticleDescription: 'BAMBINO PLAIN VERMICELLI 400G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341376,
    spencersArticleDescription: 'SMART CHOICE VERMICELLI ROASTD 400G-450g',
    leadingBrandArticleCode: 1007660,
    leadingBrandArticleDescription: 'BAMBINO ROASTED VERMICELLI 400G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341377,
    spencersArticleDescription: 'SMART CHOICE PLAIN VERMICELLI 900-1KG',
    leadingBrandArticleCode: 1007657,
    leadingBrandArticleDescription: 'BAMBINO PLAIN VERMICELLI 850 G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341378,
    spencersArticleDescription: 'SMART CHOICE HOT AND SWEET SAUCE 350G',
    leadingBrandArticleCode: 1008223,
    leadingBrandArticleDescription: 'MAGGI HOT&SWEET SAUCE 500G BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341379,
    spencersArticleDescription: 'SMART CHOICE GREEN CHILLI SAUCE 200G',
    leadingBrandArticleCode: 1016554,
    leadingBrandArticleDescription: 'CHINGS GREEN CHILLI SAUCE 190G BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341380,
    spencersArticleDescription: 'SMART CHOICE NON FRUIT VINEGAR 170ml',
    leadingBrandArticleCode: 1008097,
    leadingBrandArticleDescription: 'CHINGS CHILLI VINEGAR 170ML BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341381,
    spencersArticleDescription: 'SMART CHOICE CHINESE SAUCE 3X200G MLTIPK',
    leadingBrandArticleCode: 1340658,
    leadingBrandArticleDescription: 'CHINGS SCHEZWAN CHUTNEY 600G BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341386,
    spencersArticleDescription: 'SMART CHOICE ROASTED VERMICELLI 900-950G',
    leadingBrandArticleCode: 1007662,
    leadingBrandArticleDescription: 'BAMBINO ROASTED VERMICELLI 900G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341387,
    spencersArticleDescription: 'NUMKEEN ONION VADAM 150G PKT',
    leadingBrandArticleCode: 1266500,
    leadingBrandArticleDescription: 'SMS SAGO COLOUR VADAM 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341389,
    spencersArticleDescription: 'NUMKEEN RTF STAR 200g',
    leadingBrandArticleCode: 1266489,
    leadingBrandArticleDescription: 'SMS FRYUMS 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341392,
    spencersArticleDescription: 'NUMKEEN RTF WHITE FNGR 150g',
    leadingBrandArticleCode: 1266490,
    leadingBrandArticleDescription: 'SMS GOLD FINGER 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341393,
    spencersArticleDescription: 'NUMKEEN PUNJABI MASALA PAPAD 180g',
    leadingBrandArticleCode: 1255512,
    leadingBrandArticleDescription: 'LALAJI MOONG PUNJABI PAPPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341394,
    spencersArticleDescription: 'NUMKEEN CHILLY PAPAD 180G PKT',
    leadingBrandArticleCode: 1315989,
    leadingBrandArticleDescription: 'LALAJI LAL MIRCH ALLOO PAPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341395,
    spencersArticleDescription: 'NUMKEEN PAPAD 200G PKT',
    leadingBrandArticleCode: 1255518,
    leadingBrandArticleDescription: 'LALAJI PAPAD FRY 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341396,
    spencersArticleDescription: 'NUMKEEN RICE PAPAD 150G PKT',
    leadingBrandArticleCode: 1255516,
    leadingBrandArticleDescription: 'LALAJI JEERA RICE PAPPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341397,
    spencersArticleDescription: 'NUMKEEN SAGO PAPAD 150g',
    leadingBrandArticleCode: 1083095,
    leadingBrandArticleDescription: 'BALAJI SAGO APPLAM 200GM',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341400,
    spencersArticleDescription: 'NUMKEEN RICE VADAM 150G PKT',
    leadingBrandArticleCode: 1255516,
    leadingBrandArticleDescription: 'LALAJI JEERA RICE PAPPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341408,
    spencersArticleDescription: 'NUMKEEN CURD CHILLY 100g',
    leadingBrandArticleCode: 1048464,
    leadingBrandArticleDescription: 'VIJAYA CURD CHILLIES SPL 100G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341411,
    spencersArticleDescription: 'NUMKEEN POTATO WAFERS 150G PKT',
    leadingBrandArticleCode: 1400821,
    leadingBrandArticleDescription: 'SARDAR JI PAPAD  POTATO PAPAD 500G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341412,
    spencersArticleDescription: 'NUMKEEN ALPHABETS 200G PKT',
    leadingBrandArticleCode: 1215974,
    leadingBrandArticleDescription: 'MALLIKA ASSORTED  FRYUMS 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341419,
    spencersArticleDescription: 'NUMKEEN MANGALORE RICE PAPAD 200G PKT',
    leadingBrandArticleCode: 1100606,
    leadingBrandArticleDescription: 'IYERS CURD CHILLIES 100g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341425,
    spencersArticleDescription: 'NUMKEEN RICE SANDIGE 120G PKT',
    leadingBrandArticleCode: 1213680,
    leadingBrandArticleDescription: 'IYERS ONION SANDIGE 140g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341426,
    spencersArticleDescription: 'NUMKEEN SAGO SANDIGE 120G PKT',
    leadingBrandArticleCode: 1213680,
    leadingBrandArticleDescription: 'IYERS ONION SANDIGE 140g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341427,
    spencersArticleDescription: 'NUMKEEN PANI PURI 180G PKT',
    leadingBrandArticleCode: 1195050,
    leadingBrandArticleDescription: 'SHAREAT FOOCHKA PANI PURI 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341428,
    spencersArticleDescription: 'NUMKEEN ARALU SANDIGE 150G PKT',
    leadingBrandArticleCode: 1213680,
    leadingBrandArticleDescription: 'IYERS ONION SANDIGE 140g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341430,
    spencersArticleDescription: 'NUMKEEN MASALA PAPAD LONG CHIPS 120G PKT',
    leadingBrandArticleCode: 1294938,
    leadingBrandArticleDescription: 'DNV SPECIAL MASALA PAPAD 100G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1341431,
    spencersArticleDescription: 'NUMKEEN MASALA PAPAD RND CHIPS 120G PKT',
    leadingBrandArticleCode: 1294937,
    leadingBrandArticleDescription: 'DNV MASALA PAPAD-SMALL 100G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343722,
    spencersArticleDescription: 'BB Loofah 50g',
    leadingBrandArticleCode: 1236752,
    leadingBrandArticleDescription: 'VEGA LOOFAH - CUBES - 50GM',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343723,
    spencersArticleDescription: 'BB - Loofah',
    leadingBrandArticleCode: 1236752,
    leadingBrandArticleDescription: 'VEGA LOOFAH - CUBES - 50GM',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343727,
    spencersArticleDescription: 'BB Shower Cap Pack of 1',
    leadingBrandArticleCode: 1307973,
    leadingBrandArticleDescription: 'GUBB SHOWER CAPS 1S REUSABLE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343728,
    spencersArticleDescription: 'BB OVAL PUMIC STONE',
    leadingBrandArticleCode: 1261673,
    leadingBrandArticleDescription: 'VEGA PUMICE STONE OVAL- PD-16',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343730,
    spencersArticleDescription: 'BB Loofah with wooden Handle',
    leadingBrandArticleCode: 1127904,
    leadingBrandArticleDescription: 'VEGA LOOFAH WITH HANDLE BACK BRUSH BA1/1',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343731,
    spencersArticleDescription: 'BB NAIL CLIPPER',
    leadingBrandArticleCode: 1190525,
    leadingBrandArticleDescription: 'VEGA NAIL CLIPPER LARGE BLACK - LNC-02',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343738,
    spencersArticleDescription: 'HANDS ON SF CLNR Citrus 2L',
    leadingBrandArticleCode: 1197919,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF CITRUS FLR CLNR 2L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343738,
    spencersArticleDescription: 'HANDS ON SF CLNR Citrus 2L',
    leadingBrandArticleCode: 1197919,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF CITRUS FLR CLNR 2L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343739,
    spencersArticleDescription: 'HANDS ON SF CLNR Floral 2L',
    leadingBrandArticleCode: 1197920,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF FLORAL FLR CLNR 2L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343739,
    spencersArticleDescription: 'HANDS ON SF CLNR Floral 2L',
    leadingBrandArticleCode: 1197920,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISINF FLORAL FLR CLNR 2L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343740,
    spencersArticleDescription: 'HANDS ON JASMINE PHENYL FLR CLNR 5L',
    leadingBrandArticleCode: 1296123,
    leadingBrandArticleDescription: 'GAINDA BLACK PHENYLE 5L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343741,
    spencersArticleDescription: 'HANDS ON PINE PHENYL FLR CLNR 5L',
    leadingBrandArticleCode: 1328975,
    leadingBrandArticleDescription: 'ITC NIMYLE PINE FLR CLNR 5L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343741,
    spencersArticleDescription: 'HANDS ON PINE PHENYL FLR CLNR 5L',
    leadingBrandArticleCode: 1328975,
    leadingBrandArticleDescription: 'ITC NIMYLE PINE FLR CLNR 5L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343742,
    spencersArticleDescription: 'HANDS ON DISHWASH LIQ 750ML',
    leadingBrandArticleCode: 1247262,
    leadingBrandArticleDescription: 'VIMDROP DISHWSH LIQ 750ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343742,
    spencersArticleDescription: 'HANDS ON DISHWASH LIQ 750ML',
    leadingBrandArticleCode: 1247262,
    leadingBrandArticleDescription: 'VIMDROP DISHWSH LIQ 750ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343743,
    spencersArticleDescription: 'HANDS ON REGULAR TOILT CLNR 1L',
    leadingBrandArticleCode: 1252473,
    leadingBrandArticleDescription: 'HARPIC POWER ORIGINAL TOILT CLNR 1L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343743,
    spencersArticleDescription: 'HANDS ON REGULAR TOILT CLNR 1L',
    leadingBrandArticleCode: 1252473,
    leadingBrandArticleDescription: 'HARPIC POWER ORIGINAL TOILT CLNR 1L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343744,
    spencersArticleDescription: 'DOUBLETICK BLUE FLUSHMATIC MLTIPCK 2X50G',
    leadingBrandArticleCode: 1015806,
    leadingBrandArticleDescription: 'HRP ITC STFISH CIT 50GMX2 PK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1343745,
    spencersArticleDescription: 'DOUBLETICK GREEN FLUSHMATIC MLTIPCK2X50G',
    leadingBrandArticleCode: 1011325,
    leadingBrandArticleDescription: 'HRP ITC STFISH MAR 50GMX2 PK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344022,
    spencersArticleDescription: 'NUMKEEN METHI SEV 200 GM POUCH',
    leadingBrandArticleCode: 1193144,
    leadingBrandArticleDescription: 'DAADIS GLDN CRISP METHI KHAKHRA 180G PK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344023,
    spencersArticleDescription: 'NUMKEEN MASALA DAL 200 GM POUCH',
    leadingBrandArticleCode: 1148184,
    leadingBrandArticleDescription: 'Bikano Dal Moth 200 Gm Pouch',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344024,
    spencersArticleDescription: 'NUMKEEN CORN FLAKES 150 GM POUCH',
    leadingBrandArticleCode: 1148181,
    leadingBrandArticleDescription: 'Bikano Corn Flakes Mix 200 Gm Pouch',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344025,
    spencersArticleDescription: 'NUMKEEN DIET MIXTURE 150 GM POUCH',
    leadingBrandArticleCode: 1336918,
    leadingBrandArticleDescription: 'HALDIRAM DIET MIXTURE 250 GM POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344032,
    spencersArticleDescription: 'NUMKEEN HING JEERA PEANUTS 200 GM POUCH',
    leadingBrandArticleCode: 1293651,
    leadingBrandArticleDescription: 'KURKURE SALTED PEANUT POUCH 155g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344033,
    spencersArticleDescription: 'NUMKEEN LEMON CHILLI PEANUTS 200 GM PCH',
    leadingBrandArticleCode: 1293651,
    leadingBrandArticleDescription: 'KURKURE SALTED PEANUT POUCH 155g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344034,
    spencersArticleDescription: 'NUMKEEN HING JEERA CHANA 200 GM POUCH',
    leadingBrandArticleCode: 1214978,
    leadingBrandArticleDescription: 'JABSONS HING JEERA CHANA 150 GM POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344035,
    spencersArticleDescription: 'NUMKEEN MASALA BOONDI PCH 150-200G',
    leadingBrandArticleCode: 1322881,
    leadingBrandArticleDescription: 'Prabhat Masala Boondi 180 Gm Pouch',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344036,
    spencersArticleDescription: 'NUMKEEN NUT CRACKER 200 GM POUCH',
    leadingBrandArticleCode: 1108883,
    leadingBrandArticleDescription: 'HALDIRAM SNACKS NUT CRACKER 200 GM POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344037,
    spencersArticleDescription: 'NUMKEEN MALAI SEV 200 GM POUCH',
    leadingBrandArticleCode: 1397086,
    leadingBrandArticleDescription: 'Priniti Malai Sev 200g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344038,
    spencersArticleDescription: 'NUMKEEN RAITA BOONDI PCH 150-200G',
    leadingBrandArticleCode: 1148351,
    leadingBrandArticleDescription: 'HALDIRAM SNACKS RAITA BOONDI 200 GM PCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344039,
    spencersArticleDescription: 'NUMKEEN BIKANERI BHUJIA 400 GM POUCH',
    leadingBrandArticleCode: 1148179,
    leadingBrandArticleDescription: 'Bikano Bikaneri Bhujia 400 Gm Pouch',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344040,
    spencersArticleDescription: 'NUMKEEN BIKANERI BHUJIA 900 GM POUCH',
    leadingBrandArticleCode: 1416214,
    leadingBrandArticleDescription: 'BRIJNANDAN BIKANERI BHUJIA 750G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344041,
    spencersArticleDescription: 'NUMKEEN ALOO BHUJIA 400 GM POUCH',
    leadingBrandArticleCode: 1148176,
    leadingBrandArticleDescription: 'Bikano Aloo Bhujia 400 Gm Pouch',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344042,
    spencersArticleDescription: 'NUMKEEN ALOO BHUJIA 900 GM POUCH',
    leadingBrandArticleCode: 1380961,
    leadingBrandArticleDescription: 'Prabhuji Aloo Bhujia 900g-1Kg',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344043,
    spencersArticleDescription: 'NUMKEEN MOONG DAL 200 GM POUCH',
    leadingBrandArticleCode: 1007179,
    leadingBrandArticleDescription: 'HALDIRAM NGP MOONG DAL 150 GM POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1344044,
    spencersArticleDescription: 'NUMKEEN MOONG DAL 400 GM POUCH',
    leadingBrandArticleCode: 1108882,
    leadingBrandArticleDescription: 'HALDIRAM SNACKS MASLA MOONG DAL 400G PCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1373226,
    spencersArticleDescription: 'BB Luxury Back Sponge',
    leadingBrandArticleCode: 1175105,
    leadingBrandArticleDescription: 'VEGA LOOFAH LUXURY SPONGE FOR BACK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1373229,
    spencersArticleDescription: 'BB Hair Comb Large Pack of 1',
    leadingBrandArticleCode: 1360729,
    leadingBrandArticleDescription: 'BABILA HAIR COMB FAMILY 9  HC V004',
    isPrinted: false
  },
  {
    spencersArticleCode: 1373230,
    spencersArticleDescription: 'BB Wooden Comb Pack of 1',
    leadingBrandArticleCode: 1072002,
    leadingBrandArticleDescription: 'VEGA HAIR FAMILY COMB LARGE - 1299',
    isPrinted: false
  },
  {
    spencersArticleCode: 1373235,
    spencersArticleDescription: 'BB POWDER/BLUSH BRUSH - PACK OF 1',
    leadingBrandArticleCode: 1062903,
    leadingBrandArticleDescription: 'VEGA MAKE UP BLUSH BRUSH - EV19',
    isPrinted: false
  },
  {
    spencersArticleCode: 1374257,
    spencersArticleDescription: 'BATH & BEAUTY TONGUE CLEANER 2PC VAL PK',
    leadingBrandArticleCode: 1329474,
    leadingBrandArticleDescription: 'GUBB TONGUE CLEANER SET 2 PCS',
    isPrinted: false
  },
  {
    spencersArticleCode: 1378493,
    spencersArticleDescription: 'SMART CHOICE Cornflakes Pch 875GM',
    leadingBrandArticleCode: 1279031,
    leadingBrandArticleDescription: 'Kelloggs Corn Flakes 900 gm',
    isPrinted: false
  },
  {
    spencersArticleCode: 1378494,
    spencersArticleDescription: 'SMART CHOICE RED CHILLI SAUCE 200GM',
    leadingBrandArticleCode: 1008243,
    leadingBrandArticleDescription: 'CHINGS RED CHILLY SAUCE 200G BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1378495,
    spencersArticleDescription: 'SMART CHOICE GREEN CHILLI SAUCE 600 GM',
    leadingBrandArticleCode: 1008242,
    leadingBrandArticleDescription: 'CHINGS GREEN CHILLI SAUCE 680G PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1378497,
    spencersArticleDescription: 'SMART CHOICE CHINESE EGG NOODLES 600g',
    leadingBrandArticleCode: 1398376,
    leadingBrandArticleDescription: 'CHINGS SOAK WHOLE WEAT HAKKA NDLS 600g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1378498,
    spencersArticleDescription: 'SMART CHOICE CHINESE VEG NOODLES 1200g',
    leadingBrandArticleCode: 1285276,
    leadingBrandArticleDescription: 'CHINGS VEG HAKKA NOODLES 600g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1379699,
    spencersArticleDescription: 'BB HAND SANITIZER 60 ML',
    leadingBrandArticleCode: 1224991,
    leadingBrandArticleDescription: 'DETTOL HAND SANITIZER 50ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1379700,
    spencersArticleDescription: 'BB HAND SANITIZER 100 ML',
    leadingBrandArticleCode: 1293526,
    leadingBrandArticleDescription: 'SAVLON HAND SANITIZER GEL 100ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380396,
    spencersArticleDescription: 'NUMKEEN MOONG PAPAD 200G',
    leadingBrandArticleCode: 1008373,
    leadingBrandArticleDescription: 'LIJJAT MOONG PAPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380397,
    spencersArticleDescription: 'NUMKEEN RTF 3D FISH 200G',
    leadingBrandArticleCode: 1315990,
    leadingBrandArticleDescription: 'LALAJI PAPAD FACES 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380398,
    spencersArticleDescription: 'NUMKEEN RTF 3D BIG HEART 200G',
    leadingBrandArticleCode: 1315990,
    leadingBrandArticleDescription: 'LALAJI PAPAD FACES 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380399,
    spencersArticleDescription: 'NUMKEEN RTF 3D SIXER 200G',
    leadingBrandArticleCode: 1315990,
    leadingBrandArticleDescription: 'LALAJI PAPAD FACES 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380400,
    spencersArticleDescription: 'NUMKEEN RTF 3D TRIANGLE 200G',
    leadingBrandArticleCode: 1315990,
    leadingBrandArticleDescription: 'LALAJI PAPAD FACES 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380401,
    spencersArticleDescription: 'NUMKEEN RTF FLAVORED ONION RING 150G',
    leadingBrandArticleCode: 1255520,
    leadingBrandArticleDescription: 'WAVES ONION WAFERS FRY N EAT 100G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380402,
    spencersArticleDescription: 'NUMKEEN RTF 2D WHEEL  200G',
    leadingBrandArticleCode: 1171941,
    leadingBrandArticleDescription: 'VIJAYA SM WHEELS 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380403,
    spencersArticleDescription: 'NUMKEEN MOONG PUNJABI PAPAD 200G',
    leadingBrandArticleCode: 1255512,
    leadingBrandArticleDescription: 'LALAJI MOONG PUNJABI PAPPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380404,
    spencersArticleDescription: 'NUMKEEN URAD PAPAD 200G',
    leadingBrandArticleCode: 1050165,
    leadingBrandArticleDescription: 'LIJJAT URAD PAPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380405,
    spencersArticleDescription: 'NUMKEEN URAD PUNJABI PAPAD 200G',
    leadingBrandArticleCode: 1050165,
    leadingBrandArticleDescription: 'LIJJAT URAD PAPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380406,
    spencersArticleDescription: 'NUMKEEN CHANA MASALA PAPAD 200G',
    leadingBrandArticleCode: 1290597,
    leadingBrandArticleDescription: `MOTHER'S CHANA MASALA PAPAD 180-200g`,
    isPrinted: false
  },
  {
    spencersArticleCode: 1380407,
    spencersArticleDescription: 'NUMKEEN CHANA GARLIC PAPAD 200G',
    leadingBrandArticleCode: 1255498,
    leadingBrandArticleDescription: 'LALAJI CHANA LEHSUN PAPPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380408,
    spencersArticleDescription: 'NUMKEEN MOONG GARLIC PAPAD 200G',
    leadingBrandArticleCode: 1255511,
    leadingBrandArticleDescription: 'LALAJI MOONG LEHSUN PAPPAD 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380409,
    spencersArticleDescription: 'NUMKEEN RTF 2D GOLD FINGER 125G',
    leadingBrandArticleCode: 1170538,
    leadingBrandArticleDescription: 'VIJAYA GOLD FINGERS 200G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380481,
    spencersArticleDescription: 'Smart Choice Soan Papdi 450g',
    leadingBrandArticleCode: 1286279,
    leadingBrandArticleDescription: 'Prabhuji Elaichi Soan Papdi 400-450g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1380539,
    spencersArticleDescription: 'NUMKEEN RTF 2D WINDOW 200G',
    leadingBrandArticleCode: 1170538,
    leadingBrandArticleDescription: 'VIJAYA GOLD FINGERS 200G PKT ',
    isPrinted: false
  },
  {
    spencersArticleCode: 1382468,
    spencersArticleDescription: 'SMART CHOICE INSTANT MASALA NOODLES 70G',
    leadingBrandArticleCode: 1007600,
    leadingBrandArticleDescription: 'MAGGI 2 MINUTE MASALA NDLS 70G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1382505,
    spencersArticleDescription: 'BB PADDLE HAIR BRUSH',
    leadingBrandArticleCode: 1118921,
    leadingBrandArticleDescription: 'VEGA HAIR PADDLE BRUSH - 8586',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383050,
    spencersArticleDescription: 'HANDS ON DISH WASH BAR 400G TUB PCK',
    leadingBrandArticleCode: 1261204,
    leadingBrandArticleDescription: 'VIM DISH WASH BAR 500G TUB PCK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383051,
    spencersArticleDescription: 'HANDS ON DISH WASH BAR 2X700G TUB PCK',
    leadingBrandArticleCode: 1309146,
    leadingBrandArticleDescription: 'EXO ROUND DISH WASH BAR 2X700G TUB PCK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383053,
    spencersArticleDescription: 'Smart Choice Soan Papdi 200g',
    leadingBrandArticleCode: 1406395,
    leadingBrandArticleDescription: 'Charliee Premium Soan Papdi 210g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383154,
    spencersArticleDescription: 'Smart Choice Besan Ladoo 336g',
    leadingBrandArticleCode: 1259384,
    leadingBrandArticleDescription: 'Prabhuji Besan Laddu 336g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383399,
    spencersArticleDescription: 'BB PEDICURE FILE ',
    leadingBrandArticleCode: 1307970,
    leadingBrandArticleDescription: 'GUBB PEDICURE FILE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383400,
    spencersArticleDescription: 'BB DE TANGLING COMB(PACK OF 3)',
    leadingBrandArticleCode: 1059685,
    leadingBrandArticleDescription: 'VEGA HAIR DE-TANGLING COMB DOUBLE ROW',
    isPrinted: false
  },
  {
    spencersArticleCode: 1383401,
    spencersArticleDescription: 'BB HAIR DYE BRUSH & COMB SET(PACK OF 3)',
    leadingBrandArticleCode: 1118976,
    leadingBrandArticleDescription: 'VEGA MEHNDI/DYE BRUSH - MB01',
    isPrinted: false
  },
  {
    spencersArticleCode: 1384868,
    spencersArticleDescription: 'SPENCERS SMT CH  MANGO DRINK 1000ML PET',
    leadingBrandArticleCode: 1008639,
    leadingBrandArticleDescription: 'PARLE FROOTI MAN JUICE 1000ML-1200ML PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385317,
    spencersArticleDescription: 'HANDS ON DISINFECT CITRUS FLCLNR3X500ML',
    leadingBrandArticleCode: 1336331,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISNF CITRUS FLR CLNR 3X500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385317,
    spencersArticleDescription: 'HANDS ON DISINFECT CITRUS FLCLNR3X500ML',
    leadingBrandArticleCode: 1336331,
    leadingBrandArticleDescription: 'LIZOL 3IN1 DISNF CITRUS FLR CLNR 3X500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385318,
    spencersArticleDescription: 'HANDS ON TOILET CL 2X1L +TC 500ML KIT',
    leadingBrandArticleCode: 1277086,
    leadingBrandArticleDescription: 'HARPIC POWER 2X1l+500ML MLTIPCK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385318,
    spencersArticleDescription: 'HANDS ON TOILET CL 2X1L +TC 500ML KIT',
    leadingBrandArticleCode: 1277086,
    leadingBrandArticleDescription: 'HARPIC POWER 2X1l+500ML MLTIPCK',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385319,
    spencersArticleDescription: 'HANDS ON NEEM FLOOR CLEANER 1 L',
    leadingBrandArticleCode: 1018637,
    leadingBrandArticleDescription: 'ITC NIMYLE GREEN FLR CLNR 1L',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385321,
    spencersArticleDescription: 'HANDS ON 500ML TC+HANDS ON 500ML FC KIT',
    leadingBrandArticleCode: 1280986,
    leadingBrandArticleDescription: 'LIZOL 625ML+Harpic 600ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1385321,
    spencersArticleDescription: 'HANDS ON 500ML TC+HANDS ON 500ML FC KIT',
    leadingBrandArticleCode: 1280986,
    leadingBrandArticleDescription: 'LIZOL 625ML+Harpic 600ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386079,
    spencersArticleDescription: 'BB HW PROTECT 750 ML*2 COMBO PACK',
    leadingBrandArticleCode: 1336656,
    leadingBrandArticleDescription: 'DETTOL ORIGINAL HW POUCH 750ML 1+1 FREE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386080,
    spencersArticleDescription: 'BB HW PEACH & STRAWBERRY 750ML COMBO PK',
    leadingBrandArticleCode: 1009845,
    leadingBrandArticleDescription: 'VLON HW HERBAL SENSATIVE 750ML 1+1FREE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386081,
    spencersArticleDescription: 'BB HW PROTECT & SANDAL 750ML COMBO PK',
    leadingBrandArticleCode: 1336656,
    leadingBrandArticleDescription: 'DETTOL ORIGINAL HW POUCH 750ML 1+1 FREE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386084,
    spencersArticleDescription: 'BB FACE PACK BRUSH(PACK OF 2)',
    leadingBrandArticleCode: 1118976,
    leadingBrandArticleDescription: 'VEGA MEHNDI/DYE BRUSH - MB01',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386085,
    spencersArticleDescription: 'BB BEAUTY BLENDING SPONGE',
    leadingBrandArticleCode: 1329486,
    leadingBrandArticleDescription: 'GUBB BEAUTY BLENDING SPONGE',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386087,
    spencersArticleDescription: 'BB PUMIC STONE WITH HANDLE',
    leadingBrandArticleCode: 1360758,
    leadingBrandArticleDescription: 'BABILA HANDY PUMIC STONE 36 PC PC V003',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386156,
    spencersArticleDescription: 'SMART CHOICE INSTANT MASALA NOODLES 280G',
    leadingBrandArticleCode: 1007602,
    leadingBrandArticleDescription: 'MAGGI 2 MINUTE MASALA NDLS 280G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386311,
    spencersArticleDescription: 'BB FLEXI TOOTHBRUSH B3G1',
    leadingBrandArticleCode: 1317822,
    leadingBrandArticleDescription: 'COLGATE ZIGZAG CHARCOAL BLACK TBR 4S',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386312,
    spencersArticleDescription: 'BB FLEXI TOOTHBRUSH B6G2',
    leadingBrandArticleCode: 1317823,
    leadingBrandArticleDescription: 'COLGATE ZIGZAG TOOTHBRUSH 6S',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386688,
    spencersArticleDescription: 'BB PREMIUM HANDMADE COMB',
    leadingBrandArticleCode: 1261668,
    leadingBrandArticleDescription: 'VEGA HAIR GROOMING COMB HMC-72',
    isPrinted: false
  },
  {
    spencersArticleCode: 1386689,
    spencersArticleDescription: 'BB WOODEN PADDLE HAIR BRUSH',
    leadingBrandArticleCode: 1118921,
    leadingBrandArticleDescription: 'VEGA HAIR PADDLE BRUSH - 8586',
    isPrinted: false
  },
  {
    spencersArticleCode: 1387859,
    spencersArticleDescription: 'SMART CHOICE PREMIUM SUJI RUSK 275G',
    leadingBrandArticleCode: 1035311,
    leadingBrandArticleDescription: 'BRITANNIA SUJI TOAST RUSK 250G-275G PKT',
    isPrinted: false
  },
  {
    spencersArticleCode: 1388464,
    spencersArticleDescription: 'SMART CH  BLACK TEA BAGÂ 100SÂ BOX',
    leadingBrandArticleCode: 1008960,
    leadingBrandArticleDescription: 'TAJMAHAL 100S TEA BAG BOX',
    isPrinted: false
  },
  {
    spencersArticleCode: 1388591,
    spencersArticleDescription: 'BATH & BEAUTY HAND SANITIZER JAR 5 LTR',
    leadingBrandArticleCode: 1151801,
    leadingBrandArticleDescription: 'SAVLON HAND SANITIZER LIQUID 5 LTR',
    isPrinted: false
  },
  {
    spencersArticleCode: 1392821,
    spencersArticleDescription: 'Smart Choice Schezwan Sauce Btl 250G',
    leadingBrandArticleCode: 1285271,
    leadingBrandArticleDescription: 'CHINGS SCHEZWAN CHUTNEY 250G BTL',
    isPrinted: false
  },
  {
    spencersArticleCode: 1393952,
    spencersArticleDescription: 'SPL SMT CH MIXED FRUIT DRINK 1000ML PET',
    leadingBrandArticleCode: 1219219,
    leadingBrandArticleDescription: 'MINUTE MAID PULPY MXDFR JUICE 1000ML PET',
    isPrinted: false
  },
  {
    spencersArticleCode: 1395089,
    spencersArticleDescription: 'Hands ON Glass Cleaner 500ml x2',
    leadingBrandArticleCode: 1327271,
    leadingBrandArticleDescription: 'COLIN GLASS CLEANER MULTIPACK 3x500 ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1395386,
    spencersArticleDescription: 'BB Disposable Razor (4+2)',
    leadingBrandArticleCode: 1010696,
    leadingBrandArticleDescription: 'GILLETTE PRESTO INTRNL PUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1402863,
    spencersArticleDescription: 'HANDS ON TOILT CLNR 500ML',
    leadingBrandArticleCode: 1011312,
    leadingBrandArticleDescription: 'HARPIC PWR TRIPLE ACTN TOILT CLNR 500ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1404336,
    spencersArticleDescription: 'HON SFCLNR CITRUS975ML &TOILT CLNR 500ML',
    leadingBrandArticleCode: 1399132,
    leadingBrandArticleDescription: 'Lizol IN DFC FLO 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1404337,
    spencersArticleDescription: 'HON SFCLNR FLRL975ML & TOILT CLNR 500ML',
    leadingBrandArticleCode: 1399132,
    leadingBrandArticleDescription: 'Lizol IN DFC FLO 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1404338,
    spencersArticleDescription: 'HON SFCLNR PINE975ML & TOILT CLNR 500ML',
    leadingBrandArticleCode: 1399132,
    leadingBrandArticleDescription: 'Lizol IN DFC FLO 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1404339,
    spencersArticleDescription: 'HON 1L TCL & HON CITRUS FLCLN 500ML',
    leadingBrandArticleCode: 1399132,
    leadingBrandArticleDescription: 'Lizol IN DFC FLO 1000ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406875,
    spencersArticleDescription: 'SPENCERS SMART CHOICE WAFFY ORANGE 120G',
    leadingBrandArticleCode: 1007488,
    leadingBrandArticleDescription: 'DUKES ORANGE WAFFY 60g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406876,
    spencersArticleDescription: 'SPENCERS SMARTCHOICE WAFFY STRWBERY 120G',
    leadingBrandArticleCode: 1405064,
    leadingBrandArticleDescription: 'UNIBIC WAFER STRAWBERRY 1+1 75G',
    isPrinted: false
  },
  {
    spencersArticleCode: 1399051,
    spencersArticleDescription: 'SMART CHOICE PURE INSTANT COFFEE JAR 100g',
    leadingBrandArticleCode: 1008895,
    leadingBrandArticleDescription: 'NESCAFE CLASSIC 100g COFFEE JAR',
    isPrinted: false
  },
  {
    spencersArticleCode: 1399052,
    spencersArticleDescription: 'SMART CHOICE CHICORY INSTNT COFFEE 100G',
    leadingBrandArticleCode: 1008885,
    leadingBrandArticleDescription: 'BRU 100g COFFE POUCH',
    isPrinted: false
  },
  {
    spencersArticleCode: 1412419,
    spencersArticleDescription: `BATH & BEAUTY PAPER STICK EAR BUDS 50'S`,
    leadingBrandArticleCode: 1011157,
    leadingBrandArticleDescription: `JOHNSONS BABY COTTON BUDS 30'S PKT`,
    isPrinted: false
  },
  {
    spencersArticleCode: 1410303,
    spencersArticleDescription: 'Bath & Beauty Coconut Oil 250ml 1+1',
    leadingBrandArticleCode: 1215808,
    leadingBrandArticleDescription: 'PARACHUTE COCO HAIR OIL 250/300ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1410304,
    spencersArticleDescription: 'Bath & Beauty Coconut Oil 500ml 1+1',
    leadingBrandArticleCode: 1010335,
    leadingBrandArticleDescription: 'PARACHUTE COCONUT OIL 550ml',
    isPrinted: false
  },
  {
    spencersArticleCode: 1410305,
    spencersArticleDescription: 'Bath & Beauty Coconut Oil 200ml  WD Mth',
    leadingBrandArticleCode: 1060357,
    leadingBrandArticleDescription: 'PARCHUT Coconut Easy Jar Oil  250 ML /F',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406869,
    spencersArticleDescription: 'SPENCERS SMRT CHOICE WAFFY CHOCO 60g',
    leadingBrandArticleCode: 1007487,
    leadingBrandArticleDescription: 'PARCHUT Coconut Easy Jar Oil  250 ML /F',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406870,
    spencersArticleDescription: 'SPENCERS SMART CHOICE WAFFY VANILLA 60g',
    leadingBrandArticleCode: 1007490,
    leadingBrandArticleDescription: 'DUKES VANILLA WAFFY 60g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406871,
    spencersArticleDescription: 'SPENCERS SMART CHOICE WAFFY ORANGE 60G',
    leadingBrandArticleCode: 1007488,
    leadingBrandArticleDescription: 'DUKES ORANGE WAFFY 60g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406872,
    spencersArticleDescription: 'SPENCERS SMARTCHOICE WAFFY STRAWBERY 60G',
    leadingBrandArticleCode: 1007489,
    leadingBrandArticleDescription: 'DUKES STRAWBERRY WAFFY 60g',
    isPrinted: false
  },
  {
    spencersArticleCode: '1406873',
    spencersArticleDescription: 'SPENCERS SMART CHOICE WAFFY CHOCO 120G',
    leadingBrandArticleCode: 1290497,
    leadingBrandArticleDescription: 'DUKES DARK WAFFY BISCUITS 150g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1406874,
    spencersArticleDescription: 'SPENCERS SMART CHOICE WAFFY VANILLA 120G',
    leadingBrandArticleCode: 1396788,
    leadingBrandArticleDescription: 'Treat Wafers Vanilla 60g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1407073,
    spencersArticleDescription: 'SMART CHOICE Tomato Ketchup Pch 900g',
    leadingBrandArticleCode: 1193167,
    leadingBrandArticleDescription: 'KISSAN Fresh Tomato Ketchup Pch 900g',
    isPrinted: false
  },
  {
    spencersArticleCode: 1427496,
    spencersArticleDescription: 'HANDS ON Liquid Dishwash 1L Pouch',
    leadingBrandArticleCode: 1268112,
    leadingBrandArticleDescription: 'VIM LIQUID POUCH 900ML',
    isPrinted: false
  },
  {
    spencersArticleCode: 1427497,
    spencersArticleDescription: 'HANDS ON Glass cleaner 1L Pouch',
    leadingBrandArticleCode: 1327275,
    leadingBrandArticleDescription: 'COLIN IN REFIL 1 LITRE',
    isPrinted: false
  }
];
const popCompareArticlesColumns = [
  {
    type: 'row_num',
    headerText: '#',
    class: { 'text-center': true },
    textKey: 'index',
    width: '4%'
  },
  {
    type: 'row_num',
    headerText: 'Select',
    class: { 'text-center': true },
    textKey: 'select',
    width: '5%'
  },
  {
    type: 'row_num',
    headerText: 'Spencers Article Code',
    class: { 'text-center': true },
    textKey: 'spencersArticleCode',
    width: '9%'
  },
  {
    type: 'string',
    headerText: 'Spencers Article Description',
    class: { 'text-center': true },
    textKey: 'spencersArticleDescription',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Spencers MRP',
    class: { 'text-center': true },
    textKey: 'mrpSpencers',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Spencers Selling Price',
    class: { 'text-center': true },
    textKey: 'offerSpencers',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Leading Brand Article Code',
    class: { 'text-center': true },
    textKey: 'leadingBrandArticleCode',
    width: '10%'
  },
  {
    type: 'string',
    headerText: 'Leading Brand Description',
    class: { 'text-center': true },
    textKey: 'leadingBrandArticleDescription',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Leading Brand MRP',
    class: { 'text-center': true },
    textKey: 'mrpLeadingBrand',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Leading Brand Selling Price',
    class: { 'text-center': true },
    textKey: 'offerLeadingBrand',
    width: '10%'
  },
  {
    type: 'functions',
    headerText: 'Functions',
    class: { 'text-center': true },
    textKey: 'functions',
    width: '12%'
  }
];
export { popCompareArticles, popCompareArticlesColumns };
