<template>
  <div>
    <div v-if="!listPrint" class="page">
      <div class="topHeading"><h1>COMPARE & SAVE</h1></div>
      <div class="articlesDiv" :key="index" v-for="(item, index) in arr">
        <div class="spencersArea">
          <div class="descriptionSpencers">
            {{ item.description }}
          </div>
          <div class="mrpSpencers">
            <span class="priceSpencers">MRP:</span>
            <span class="priceSpencers">&#x20B9;{{ item.mrp }}</span>
          </div>
          <div class="saveSpencers">
            <span class="offerPriceSpencers">Offer Price: &#x20B9;{{ item.body ? item.body : 'No Offer' }}</span>
          </div>
        </div>
        <div class="othersArea">
          <div class="descriptionOthers">
            <!-- {{ item.descriptionCompare }} -->
            LEADING NATIONAL BRAND
          </div>
          <div class="mrpOthers">
            <span class="priceOthers">MRP:</span>
            <span class="priceOthers">&#x20B9;{{ item.mrpCompare }}</span>
          </div>
          <div class="saveOthers">
            <span class="offerPriceOthers"
              >Offer Price: &#x20B9;{{ item.bodyCompare ? item.bodyCompare : 'No Offer' }}</span
            >
          </div>
        </div>
      </div>
      <div class="bottomHeading">
        <h1 class="saveText">Save &#x20B9;{{ save ? fixDigits(save) : 0 }}</h1>
        <h2 class="tempText">Over Other Brand </h2>
      </div>
    </div>
    <!--List Printing-->
    <div v-if="listPrint">
      <div class="page" :key="index" v-for="(item, index) in listArr">
        <div class="topHeading"><h1>COMPARE & SAVE</h1></div>
        <div class="articlesDiv">
          <div class="spencersArea">
            <div class="descriptionSpencers">
              {{ item.spencersArticleDescription }}
            </div>
            <div class="mrpSpencers">
              <span class="priceSpencers">MRP:</span>
              <span class="priceSpencers">&#x20B9;{{ item.mrpSpencers }}</span>
            </div>
            <div class="saveSpencers">
              <span class="offerPriceSpencers"
                >Offer Price: &#x20B9;{{ item.offerSpencers ? item.offerSpencers : 'No Offer' }}</span
              >
            </div>
          </div>
          <div class="othersArea">
            <div class="descriptionOthers">
              <!-- {{ item.descriptionCompare }} -->
              LEADING NATIONAL BRAND
            </div>
            <div class="mrpOthers">
              <span class="priceOthers">MRP:</span>
              <span class="priceOthers">&#x20B9;{{ item.mrpLeadingBrand }}</span>
            </div>
            <div class="saveOthers">
              <span class="offerPriceOthers"
                >Offer Price: &#x20B9;{{ item.offerLeadingBrand ? item.offerLeadingBrand : 'No Offer' }}</span
              >
            </div>
          </div>
        </div>
        <div class="bottomHeading">
          <h1 class="saveText">Save &#x20B9;{{ item.saveList ? item.saveList : 0 }}</h1>
          <h2 class="tempText">Over Other Brand </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// For Reference: http://jsfiddle.net/mturjak/2wk6Q/1949/
import Vue from 'vue';
export default {
  name: 'PromoCompareTemplateA4',
  props: {
    dataA4: Array,
    listPrint: Boolean
  },
  data() {
    return {
      arr: [],
      listArr: [],
      save: 0
    };
  },
  methods: {
    fixDigits(item) {
      const tempItem = Number(item);
      return Number.isInteger(tempItem) ? tempItem.toFixed(0) : tempItem.toFixed(2);
    }
  },
  watch: {
    dataA4(newValue) {
      if (newValue !== null && newValue !== undefined) {
        if (this.listPrint === true) {
          this.listArr = newValue.map(item => {
            let mrpSpencers = '';
            let spencersArticleDescription = '';
            let offerSpencers = '';
            let mrpLeadingBrand = '';
            let leadingBrandArticleDescription = '';
            let offerLeadingBrand = '';
            let saveList = '';

            mrpSpencers = item.mrpSpencers;
            spencersArticleDescription = item.spencersArticleDescription;
            offerSpencers = item.offerSpencers;
            mrpLeadingBrand = item.mrpLeadingBrand;
            leadingBrandArticleDescription = item.leadingBrandArticleDescription;
            offerLeadingBrand = item.offerLeadingBrand;
            saveList = Number(item.offerLeadingBrand) - Number(item.offerSpencers);

            return {
              mrpSpencers,
              spencersArticleDescription,
              offerSpencers,
              mrpLeadingBrand,
              leadingBrandArticleDescription,
              offerLeadingBrand,
              saveList
            };
          });
        } else {
          this.arr = newValue.map(item => {
            let body = '';
            let mrp = '';
            let description = '';
            let bodyCompare = '';
            let saveCompare = '';
            let mrpCompare = '';
            let descriptionCompare = '';
            mrp = item.mrp;
            description = item.articleDescription;
            mrpCompare = item.mrpCompare;
            descriptionCompare = item.articleDescriptionCompare;
            body = item.finalPriceSpencer;
            bodyCompare = item.finalPriceBrand;
            this.save = Number(item.finalPriceBrand) - Number(item.finalPriceSpencer);
            return {
              body,
              mrp,
              description,
              bodyCompare,
              saveCompare,
              mrpCompare,
              descriptionCompare
            };
          });
        }
      }
    }
  }
};
</script>
