<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <table-box
      :columns="columns"
      :rows="filteredUsers"
      :pagination="pagination"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No staff found. Please add new user."
      :showAdd="true"
      :showDownload="true"
      :showPagination="false"
      :showDownloadReport="true"
      :showRolesReport="true"
      :showUpload="true"
      addText="Create new User"
      downloadReportText="Download Active Report"
      downloadRolesReportText="Download Role wise Report"
      downloadText="Download Users"
      uploadText="Upload Users"
      @add="onAdd"
      @download="onDownload"
      @edit="onEdit"
      @delete="onDelete"
      @show-upload="onShowUpload"
      @download-active-report="onDownloadActiveReport"
      @download-roles-report="onDownloadRolesReport"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </table-box>
  </div>
</template>

<script>
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import TableBox from '@/components/TableBox.vue';
import router from '@/router';
import moment from 'moment';

export default {
  name: 'UserList',
  components: {
    TableBox
  },
  metaInfo() {
    return {
      title: 'Users',
      meta: [
        {
          name: 'description',
          content: `Manage user list`
        }
      ]
    };
  },
  mounted() {
    this.reportByRegion({});
    this.listRole({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.list({ type: 'user', query: this.$route.query });
  },
  data() {
    return {
      title: 'Users',
      newUsers1: [],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Role(s)',
          class: { 'text-center': true },
          textKey: 'roles',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Company',
          class: { 'text-center': true },
          textKey: 'company',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'E-Mail',
          class: { 'text-center': true },
          textKey: 'email',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Access To',
          class: { 'text-center': true },
          textKey: 'accessto',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Emp Id',
          class: { 'text-center': true },
          textKey: 'empid',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Designation',
          class: { 'text-center': true },
          textKey: 'designation',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Status',
          class: { 'text-center': true },
          textKey: 'statusName',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Last Login',
          class: { 'text-center': true },
          textKey: 'lastLogin',
          width: '10%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('user', [
      'loading',
      'baseUrl',
      'users',
      'pagination',
      'inboundList',
      'activeReport',
      'rolesReport',
      'regionReport',
      'activeInactiveReport'
    ]),
    ...mapState('auth', ['user']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList']),
    ...mapState('role', ['roles']),
    ...mapState('darkMode', ['isDarkMode']),
    filteredUsers() {
      const nblCompany = 'NBL';
      const srlCompany = 'SRL';
      if (this.ifFoodSafetyAdmin()) {
        const allowedRoles = ['QUALITY_AUDIT_MANAGER', 'QUALITY_AUDIT_HEAD', 'QUALITY_AUDIT_EXECUTIVE']; // Replace with your allowed roles
        return this.users
          .filter(user => allowedRoles.includes(user.roles))
          .map((user, index) => ({ ...user, index: index + 1 }));
      } else if (this.ifFinanceAdmin()) {
        const allowedRoles = ['ACCOUNTANT', 'STORE_SALES_MANAGER', 'REGIONAL_SALES_MANAGER', 'AREA_SALES_MANAGER'];
        return this.users
          .filter(
            user =>
              (user.roles === 'ACCOUNTANT' && user.company === srlCompany) ||
              (allowedRoles.includes(user.roles) && user.company === nblCompany)
          )
          .map((user, index) => ({ ...user, index: index + 1 }));
      } else {
        return this.users.map((user, index) => ({ ...user, index: index + 1 }));
      }
    }
  },
  methods: {
    ...mapActions('user', [
      'list',
      'deleteOne',
      'upload',
      'downloadReport',
      'reportByRoles',
      'reportByRegion',
      'activeInactive'
    ]),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster']),
    ...mapActions('role', ['listRole']),
    ifFoodSafetyAdmin() {
      const newUser = this.user.roles.filter(role => role.name === 'QUALITY_AUDIT_HEAD');
      if (newUser.length > 0) {
        return true;
      }
      return false;
    },
    ifFinanceAdmin() {
      const newUser = this.user.roles.filter(role => role.name === 'FINANCE_ADMIN');
      if (newUser.length > 0) {
        return true;
      }
      return false;
    },
    onAdd() {
      router.push('/user/new');
    },
    onEdit({ row }) {
      router.push(`/user/${row.mobile}`);
    },
    onDownloadInboundCaller() {
      this.listCaller({ type: 'user', query: this.$route.query });
    },
    onDownload() {
      let csv = 'Row Number,Mobile,Company,Name,Role,Status,Access To,Last Login,Employee Id,Email,Designation\n';
      this.users.forEach(row => {
        Object.values(row).forEach((val, index) => {
          if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(index)) {
            csv += `"${val}"`;
            csv += ',';
          }
        });
        csv += '\n';
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Users.csv';
      anchor.click();
    },
    onDownloadActiveReport() {
      this.activeInactive();
    },
    onDownloadRolesReport() {
      this.reportByRoles();
    },
    onDelete({ row }) {
      if (this.user.mobile === row.mobile) {
        Vue.swal({
          title: 'Access Denied',
          text: 'You can not delete yourself !',
          type: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          },
          preConfirm: () => {
            this.deleteOne({
              type: 'user',
              mobile: row.mobile
            });
          }
        });
      }
    },
    createCsv2() {
      const csv = `Mobile,Name,Email,Password,Roles (Separated by Comma),Clusters(Separated by Comma),Regions(Separated by Comma),Employee-Id,Designation,Store-Ids (Separated by Commas),Status,Company\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'User.csv';
      anchor.click();
    },
    async onShowUpload() {
      await Vue.swal({
        title: 'Upload file for Users..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your User file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv2();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  const storeids = item[9] ? item[9].split(',') : [];
                  const roles = item[4] ? item[4].split(',') : [];
                  const clusters = item[5] ? item[5].split(',') : [];
                  const regions = item[6] ? item[6].split(',') : [];

                  const storesCap = storeids.map(store => store.charAt(0).toUpperCase() + store.slice(1).toLowerCase());
                  const regionsCap = regions.map(
                    region => region.charAt(0).toUpperCase() + region.slice(1).toLowerCase()
                  );
                  const clustersCap = clusters.map(cluster => {
                    const newCluster = cluster.toLowerCase();
                    if (newCluster === 'ncr') {
                      return 'NCR';
                    }
                    if (newCluster === 'tnkbw') {
                      return 'TNKBW';
                    }
                    if (newCluster === 'east up') {
                      return 'East UP';
                    }
                    if (newCluster === 'cap') {
                      return 'CAP';
                    }
                    return cluster.charAt(0).toUpperCase() + cluster.slice(1).toLowerCase();
                  });

                  const rolesCap = roles.map(role => role.toUpperCase());

                  const values = item.reduce((acc, val, index) => {
                    if (index === 0) {
                      const checkLength = val.length;
                      if (checkLength !== 10) {
                        Vue.swal({
                          title: 'Invalid Mobile Number',
                          text: `Mobile number ${val} should be 10 digits.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.mobile = val;
                    }
                    if (index === 1) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Name',
                          text: `Name ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.name = val;
                    }
                    if (index === 2) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Email',
                          text: `Email ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.email = val.toLowerCase();
                    }
                    if (index === 3) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Password',
                          text: `Password ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.password = val;
                    }
                    if (index === 4) {
                      if (rolesCap.length === 0) {
                        Vue.swal({
                          title: 'Invalid Roles',
                          text: `Roles ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      if (rolesCap.length >= 1) {
                        for (let i = 0; i < rolesCap.length; i++) {
                          const checkRoles = this.newRoles.filter(role => role.role === rolesCap[i]);
                          if (checkRoles.length === 0) {
                            Vue.swal({
                              title: 'Invalid Roles',
                              text: `Role ${rolesCap[i]} not found in database! please enter the correct role.`,
                              type: 'error',
                              confirmButtonText: 'Ok',
                              background: this.isDarkMode ? '#464d54' : '#ffffff',
                              customClass: {
                                title: this.isDarkMode ? 'text-light' : 'text-dark',
                                content: this.isDarkMode ? 'text-light' : 'text-dark'
                              }
                            });
                            return null;
                          }
                        }
                        acc.roles = rolesCap;
                      }
                    }
                    if (index === 5) {
                      if (clustersCap.length >= 1) {
                        for (let i = 0; i < clustersCap.length; i++) {
                          const checkClusters = this.clusters.filter(cluster => cluster.name === clustersCap[i]);
                          if (checkClusters.length === 0) {
                            Vue.swal({
                              title: 'Invalid Cluster',
                              text: `Cluster ${clustersCap[i]} is not found in databse! please enter the correct cluster.`,
                              type: 'error',
                              confirmButtonText: 'Ok',
                              background: this.isDarkMode ? '#464d54' : '#ffffff',
                              customClass: {
                                title: this.isDarkMode ? 'text-light' : 'text-dark',
                                content: this.isDarkMode ? 'text-light' : 'text-dark'
                              }
                            });
                            return null;
                          }
                        }
                      }
                      acc.clusters = clustersCap;
                    }
                    if (index === 6) {
                      if (regionsCap.length >= 1) {
                        for (let i = 0; i < regionsCap.length; i++) {
                          const checkInDatabse = this.regions.filter(region => region.region === regionsCap[i]);
                          if (checkInDatabse.length === 0) {
                            Vue.swal({
                              title: 'Invalid Region',
                              text: `Region ${regionsCap[i]} is not found in the database! please enter the correct region`,
                              type: 'error',
                              confirmButtonText: 'Ok',
                              background: this.isDarkMode ? '#464d54' : '#ffffff',
                              customClass: {
                                title: this.isDarkMode ? 'text-light' : 'text-dark',
                                content: this.isDarkMode ? 'text-light' : 'text-dark'
                              }
                            });
                            return null;
                          }
                        }
                      }
                      acc.regions = regionsCap;
                    }
                    if (index === 7) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Employee ID',
                          text: `Employee ID ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.empid = val;
                    }
                    if (index === 8) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Designation',
                          text: `Designation ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.designation = val;
                    }
                    if (index === 9) {
                      if (storesCap.length >= 1) {
                        for (let i = 1; i < storesCap.length; i++) {
                          const checkStoreids = this.newStoreList.filter(store => store.storeid === storesCap[i]);
                          if (checkStoreids.length === 0) {
                            Vue.swal({
                              title: 'Invalid Store',
                              text: `Store ${storesCap[i]} is not found in the database! please eneter the correct store`,
                              type: 'error',
                              confirmButtonText: 'Ok',
                              background: this.isDarkMode ? '#464d54' : '#ffffff',
                              customClass: {
                                title: this.isDarkMode ? 'text-light' : 'text-dark',
                                content: this.isDarkMode ? 'text-light' : 'text-dark'
                              }
                            });
                            return null;
                          }
                        }
                      }
                      acc.storeids = storesCap;
                    }
                    if (index === 10) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Status',
                          text: `Status ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      const newStatus = val.toLowerCase();
                      if (newStatus === 'active') {
                        acc.status = 1;
                      }
                      if (newStatus === 'disabled') {
                        acc.status = 0;
                      }
                    }
                    if (index === 11) {
                      if (val.length === 0) {
                        Vue.swal({
                          title: 'Invalid Company',
                          text: `Company ${val} should not be empty.`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      acc.company = val;
                    }
                    const abc = ['Cluster', 'Region', 'Stores'];
                    if (clustersCap.length === 0) {
                      if (regionsCap.length === 0) {
                        if (storesCap.length === 0) {
                          Vue.swal({
                            title: 'Invalid Data',
                            text: `All area fields should not be empty!
                              Please assign atleast one :- ${abc[0]} or ${abc[1]} or ${abc[2]}`,
                            type: 'error',
                            confirmButtonText: 'Ok',
                            background: this.isDarkMode ? '#464d54' : '#ffffff',
                            customClass: {
                              title: this.isDarkMode ? 'text-light' : 'text-dark',
                              content: this.isDarkMode ? 'text-light' : 'text-dark'
                            }
                          });
                          return null;
                        }
                      }
                    }
                    return acc;
                  }, {});
                  return values;
                });
                this.upload({ router, data: inputs });
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  },
  watch: {
    activeInactiveReport(newValue) {
      if (newValue) {
        let csv = '';
        csv += `Time From   ${moment()
          .subtract(24, 'hours')
          .format('DD-MM-YYYY   hh:mm  a')}     To    ${moment().format('DD-MM-YYYY   hh:mm  a')}\n`;
        csv += ',\n';
        csv += `Region,Total User,Active User,Inactive User\n`;
        const total = newValue.map(item => item.TotalUser);
        const active = newValue.map(item => item.ActiveUser);
        const inactive = newValue.map(item => item.InactiveUser);
        const filteredTotal = total.filter(item => item !== undefined);
        const filteredActive = active.filter(item => item !== undefined);
        const filteredInactive = inactive.filter(item => item !== undefined);

        csv += `East,${filteredTotal[0].East},${filteredActive[0].East},${filteredInactive[0].East}\n`;
        csv += `West,${filteredTotal[0].West},${filteredActive[0].West},${filteredInactive[0].West}\n`;
        csv += `North,${filteredTotal[0].North},${filteredActive[0].North},${filteredInactive[0].North}\n`;
        csv += `South,${filteredTotal[0].South},${filteredActive[0].South},${filteredInactive[0].South}\n`;
        csv += `India,${filteredTotal[0].India},${filteredActive[0].India},${filteredInactive[0].India}\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'User Active Report.csv';
        anchor.click();
      }
    },
    // activeReport(newValue) {
    //   if (newValue) {
    //     const startTime = newValue.map(report => report.LastDay);
    //     let csv = '';
    //     csv += `,,,All Users Report\n`;
    //     csv += `,Time From   ${startTime}   to    ${moment().format('DD-MM-YYYY   HH:mm:ss')}\n`;
    //     csv += ',Active Users,,Total Users,,Inactive Users\n';
    //     newValue.forEach(row => {
    //       Object.values(row).forEach((value, index) => {
    //         if ([0, 2, 3].includes(index)) {
    //           csv += `,"${value}"`;
    //           csv += ',';
    //         }
    //       });
    //       csv += '\n';
    //     });

    //     const anchor = document.createElement('a');
    //     anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    //     anchor.target = '_blank';
    //     anchor.download = 'Users Report.csv';
    //     anchor.click();
    //   }
    // },
    regionReport(newValue) {
      this.newUsers1 = newValue;
    },
    rolesReport(newValue) {
      if (newValue) {
        const activeData = newValue.map(report => report.ActiveUser);
        const filteredData = activeData.filter(item => item !== undefined).flat();
        const allRoles = filteredData.map(report => report.ListOfEmployeesActiveToday);
        const uniqueRoles = [...new Set(allRoles.flat(1))];
        const newUsers = uniqueRoles.map(role => {
          const users = filteredData.filter(report => report.ListOfEmployeesActiveToday.includes(role));
          return {
            role,
            users
          };
        });
        let csv = '';
        csv += `,,List Of Employees Active Today\n`;
        csv += `,,Report Date - ${moment().format('DD-MM-YYYY   hh:mm A')}\n`;
        csv += ',,\n';
        csv += `Region,Total Active User, Last Day Active User, Live Active User\n`;
        const newData = this.newUsers1;
        const live = newData.map(report => report.LiveData);
        const last = newData.map(report => report.LastDay);
        const filteredLive = live.filter(report => report !== undefined);
        const filteredLast = last.filter(report => report !== undefined);
        const totalOfEast = filteredLive[0].East + filteredLast[0].East;
        const totalOfWest = filteredLive[0].West + filteredLast[0].West;
        const totalOfNorth = filteredLive[0].North + filteredLast[0].North;
        const totalOfSouth = filteredLive[0].South + filteredLast[0].South;
        const totalOfIndia = filteredLive[0].India + filteredLast[0].India;

        csv += `East,${totalOfEast},${filteredLast[0].East},${filteredLive[0].East}\n`;
        csv += `West,${totalOfWest},${filteredLast[0].West},${filteredLive[0].West}\n`;
        csv += `North,${totalOfNorth},${filteredLast[0].North},${filteredLive[0].North}\n`;
        csv += `South,${totalOfSouth},${filteredLast[0].South},${filteredLive[0].South}\n`;
        csv += `India,${totalOfIndia},${filteredLast[0].India},${filteredLive[0].India}\n`;
        csv += ',\n';
        csv += ',\n';

        csv += ',,\n';
        csv += `Roles,Name,Mapped To\n`;
        newUsers.forEach(role => {
          role.users.forEach(user => {
            const mappedTo = [];
            user.mappedTo.forEach(mapped => {
              mappedTo.push(mapped.storeids, mapped.regions, mapped.clusters);
            });
            const finalData = JSON.parse(JSON.stringify(mappedTo));
            csv += `"${role.role}","${user.name}","${finalData.join(',').replace(/,,/g, '  ')}"\n`;
          });
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Roles Report.csv';
        anchor.click();
      }
    },

    clusterList(newValue) {
      let comparingClusters = [];
      comparingClusters = newValue.map(cluster => ({
        name: cluster.value
      }));
      this.clusters = comparingClusters;
    },
    regionList(newValue) {
      let comparingRegions = [];
      comparingRegions = newValue.map(region => ({
        region: region.value
      }));
      this.regions = comparingRegions;
    },
    roles(newValue) {
      let comparingRoles = [];
      comparingRoles = newValue.map(role => ({
        role: role.text,
        id: role.value
      }));
      this.newRoles = comparingRoles;
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
    }
  }
};
</script>
