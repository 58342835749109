<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <!-- {{ JSON.stringify(assetList) }} -->
    <asset-transfer-list-box
      :columns="columns"
      :rows="assetList.map((i, index) => ({ ...i, index: index + 1 }))"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :filterOptions="filterOptions"
      :searchItems="searchItems"
      :showToolbar="true"
      :showPagination="false"
      :show-download="true"
      addText="Create New Asset Transfer List"
      emptyText="No Store found. Please add new Store."
      @add="onAdd"
      @edit="onEdit"
      @mark="onMark"
    >
    </asset-transfer-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import AssetTransferListBox from '@/components/AssetTransferListBox.vue';

export default {
  name: 'Store',
  components: { AssetTransferListBox },
  metaInfo() {
    return {
      title: 'Store',
      meta: [
        {
          name: 'description',
          content: `Manage Store`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listAsset({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listAsset({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listAsset({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Asset Transfer List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'From Store Id',
          class: { 'text-center': true },
          textKey: 'fromStoreId',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'To Store Id',
          class: { 'text-center': true },
          textKey: 'toStoreId',
          width: '18%'
        },
        {
          type: 'string',
          headerText: 'Created By',
          class: { 'text-center': true },
          textKey: 'createdBy',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '10%'
        },

        {
          type: 'string',
          headerText: 'Updated At',
          class: { 'text-center': true },
          textKey: 'updatedAt',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Completed',
          class: { 'text-center': true },
          textKey: 'markReceived',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'ATF Details',
          class: { 'text-center': true },
          textKey: 'atfDetails',
          width: '14%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '10%',
          functions: {
            edit: true,
            mark: true
          }
        }
      ],
      searchItems: ['From Store', 'To Store'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'SENT', text: 'SENT' },
        { value: 'ARRIVING', text: 'ARRIVING' },
        { value: 'COMPLETED', text: 'COMPLETED' }
      ]
    };
  },
  computed: {
    ...mapState('assettransfer', ['baseUrl', 'loading', 'assetList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('assettransfer', ['listAsset']),

    onAdd() {
      router.push('/atf/new');
    },
    onEdit({ row }) {
      router.push(`/atf/${row.id}`);
    },

    onMark() {}

    // page ka edit ka url dena hai//
    //  onEdit({ row }) {
    //   router.push(`/atf/${row.mobile}`);
    // }
    // onDelete({ row }) {
    //   if (this.user.mobile === row.mobile) {
    //     Vue.swal({
    //       title: 'Access Denied',
    //       text: 'You can not delete yourself !',
    //       type: 'error',
    //       confirmButtonText: 'Ok',
    // background: this.isDarkMode ? '#464d54' : '#ffffff',
    // customClass: {
    //   title: this.isDarkMode ? 'text-light' : 'text-dark',
    //   content: this.isDarkMode ? 'text-light' : 'text-dark'
    // }
    //     });
    //   } else {
    //     Vue.swal({
    //       title: 'Are you sure?',
    //       text: "You won't be able to revert this.",
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes, delete it!',
    //     background: this.isDarkMode ? '#464d54' : '#ffffff',
    // customClass: {
    //   title: this.isDarkMode ? 'text-light' : 'text-dark',
    //   content: this.isDarkMode ? 'text-light' : 'text-dark'
    // }

    //       preConfirm: () => {
    //         this.deleteOne({
    //           type: 'user',
    //           mobile: row.mobile
    //         });
    //       }
    //     });
    //   }
    // },
  }
};
</script>
