<template>
  <div>
    <div class="page" :key="index" v-for="(item, index) in arr">
      <div :class="[item.symbol === '' ? 'noDisplay' : 'header-area']">
        <div class="header-text">
          {{ item.title }}
        </div>
      </div>
      <div class="center-area">
        <div class="offer-text">
          <span v-if="item.symbol === 'RUPEES'" class="rupeeSymbol">&#x20B9;</span>
          <span :class="[item.symbol === '' ? 'noDisplay' : 'offer-text-rupee']">
            {{ beforeDecimalFix(item.body)
            }}<sub style="font-size: 90pt; vertical-align: baseline">{{ afterDecimalFix(item.body) }}</sub></span
          >
          <span v-if="item.symbol === 'PERCENT'" class="percentageSymbol"> &percnt;</span>
        </div>
      </div>
      <span
        :class="[
          item.isSave3Family === true
            ? 'ClassSave3'
            : item.body.length > 25
            ? 'longText'
            : item.symbol === ''
            ? 'buyClass'
            : 'noDisplay'
        ]"
        >{{ item.body }}</span
      >

      <div :class="[item.symbol === '' || item.isDifferentFamily === true ? 'noDisplay' : 'save-container']">
        <span class="price-label"> {{ item.subTitle }}:&#x20B9; </span>
        <span class="price-label">{{ fixDigits(item.save) }}</span>
      </div>
      <div :class="[item.isDifferentFamily === true ? 'noDisplay' : 'mrp-container']">
        <span class="price-label">MRP:&#x20B9;</span>
        <span class="price-label">{{ fixDigits(item.mrp) }}</span>
      </div>
      <div
        :class="[
          item.isDifferentFamily === true || item.isSelectedbrick === true ? 'familyDescription' : 'description'
        ]"
      >
        {{ item.description }}
      </div>
      <!-- <div :class="[(item.save3footer === true) ? 'savefooter' : 'noDisplay']">
        <span class="savefooter">
          <h2>terms and conditions terms and conditions</h2>
        </span>
      </div> -->

      <div class="validTo">(ValidTo:{{ item.validTo }})</div>
      <div class="footer-date"> (PPD:{{ currentDate() }}) </div>
    </div>
  </div>
</template>
<script>
// For Reference: http://jsfiddle.net/mturjak/2wk6Q/1949/
export default {
  name: 'PromoTemplateA4',
  props: {
    data: Array
  },
  data() {
    return {
      arr: []
    };
  },
  watch: {
    data(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.arr = newValue.map(item => {
          let title = '';
          let body = '';
          let symbol = '';

          let save = '';
          let mrp = '';
          let description = '';
          let subTitle = '';
          const selectedbrick = [
            'NON VEG HEAT&SERVE',
            'NON VEG RTC-RTF',
            'NON VEG COLD CUT',
            'VEG HEAT&SERVE',
            'VEG RTC-RTF',
            'FROZEN VEGETABLE',
            'NON VEG RAW',
            'NB Non-Veg-RTC - RTF',
            "NB Non Veg Coldcut's"
          ];
          const differentFamily = ['FISH & MEAT', 'FRESH VEGETABLE', 'FRESH FRUIT', 'EGG'];
          let isDifferentFamily = false;
          let isSelectedbrick = false;
          let isSave3Family = false;
          let save3footer = false;
          let validTo = '';
          mrp = item.mrp;
          description = item.articleDescription;
          validTo = item.validTo;
          if (item.brick !== '' && selectedbrick.includes(item?.brick)) {
            isSelectedbrick = true;
            isDifferentFamily = false;
            title = 'OUR PRICE';
            symbol = 'RUPEES';
            subTitle = 'SAVE';
            if (item.offerPrice > 0) {
              body = item.offerPrice;
              save = item.mrp - item.offerPrice;
            } else if (item.percentageOff > 0) {
              const price = 100 - item.percentageOff;
              const tempBody = (price / 100) * item.mrp;
              body = Number.isInteger(tempBody) ? tempBody.toFixed(0) : tempBody.toFixed(2);
              const tempSave = item.mrp * (item.percentageOff / 100);
              save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
            } else if (item.rupeesOff > 0) {
              body = item.mrp - item.rupeesOff;
              save = item.rupeesOff;
            }
          } else if (item.family !== '' && differentFamily.includes(item?.family)) {
            isDifferentFamily = true;
            title = 'OUR PRICE';
            if (item.rupeesOff > 0) {
              body = item.mrp - item.rupeesOff;
              symbol = 'RUPEES';
            } else if (item.percentageOff > 0) {
              const tempSave = item.mrp * ((100 - item.percentageOff) / 100);
              body = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
              symbol = 'RUPEES';
            } else {
              body = item.offerPrice;
              symbol = 'RUPEES';
            }
          } else if (
            item?.popPaperType?.includes('SAVE3') ||
            item?.popPaperType?.includes('SPENCERS PRICE3') ||
            item?.popPaperType?.includes('SAVE2')
          ) {
            if (item.offerCommunication.length < 1000) {
              isSave3Family = true;
              save3footer = true;
              body = item.offerCommunication;
            }
          } else if (
            (item?.pop1?.includes('Buy') || item?.pop1?.includes('BUY')) &&
            (item?.pop1?.includes('Get') || item?.pop1?.includes('GET'))
          ) {
            const tempSave = item.mrp * ((100 - item.percentageOff) / 100);
            save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
            // body = item.pop1;
            let wordsToCheck = ['off', 'free'];
            let textLower = item.pop1 ? item?.pop1?.toLowerCase() : '';
            let containsWord = wordsToCheck.some(word => textLower.includes(word));
            if (containsWord) {
              body = item.pop1;
            } else {
              body = `${item.pop1} FREE`;
            }

            symbol = '';
          } else if (item.popPaperType?.includes('SAVE')) {
            title = 'SAVE';
            subTitle = 'OUR PRICE';
            if (item.rupeesOff > 0) {
              body = item.rupeesOff;
              symbol = 'RUPEES';
              save = item.mrp - item.rupeesOff;
            } else if (item.percentageOff > 0) {
              const tempSave = item.mrp * ((100 - item.percentageOff) / 100);
              save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
              body = item.percentageOff;
              symbol = 'PERCENT';
              body = item.percentageOff;
            } else {
              body = item.mrp - item.offerPrice;
              symbol = 'RUPEES';
              save = item.offerPrice;
            }
          } else {
            title = 'OUR PRICE';
            symbol = 'RUPEES';
            subTitle = 'SAVE';

            if (item.offerPrice > 0) {
              body = item.offerPrice;
              save = item.mrp - item.offerPrice;
            } else if (item.percentageOff > 0) {
              const price = 100 - item.percentageOff;
              const tempBody = (price / 100) * item.mrp;
              body = Number.isInteger(tempBody) ? tempBody.toFixed(0) : tempBody.toFixed(2);
              const tempSave = item.mrp * (item.percentageOff / 100);
              save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
            } else if (item.rupeesOff > 0) {
              body = item.mrp - item.rupeesOff;
              save = item.rupeesOff;
            }
          }
          return {
            body,
            save,
            mrp,
            title,
            symbol,

            description,
            subTitle,
            isDifferentFamily,
            isSave3Family,
            save3footer,
            isSelectedbrick,
            validTo
          };
        });
      }
    }
  },
  methods: {
    beforeDecimalFix(item) {
      const checkNan = !isNaN(Number(item));
      if (checkNan) {
        const tempItem = Number(item);
        return Number.isInteger(tempItem) ? tempItem : `${tempItem.toString().split('.')[0]}.`;
      }
      return item;
    },
    afterDecimalFix(item) {
      const checkNan = !isNaN(Number(item));
      if (checkNan) {
        const tempItem = Number(item);
        return Number.isInteger(tempItem) ? '' : tempItem.toFixed(2).toString().split('.')[1];
      }
      return item;
    },
    fixDigits(item) {
      const tempItem = Number(item);
      return Number.isInteger(tempItem) ? tempItem.toFixed(0) : tempItem.toFixed(2);
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
      return date;
    }
  }
};
</script>
