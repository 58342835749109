<template>
  <div>
    <div class="custom-width">
      <h1 v-if="!isCompareDiv" class="page-title text-center">{{ title }}</h1>
      <h1 v-if="isCompareDiv" class="page-title text-center">{{ titleComparison }}</h1>
      <promo-template-a4 v-show="false" :data="templateDataA4" ref="promoTemplateA4"></promo-template-a4>
      <promo-template-a6 v-show="false" :data="templateDataA6" ref="promoTemplateA6"></promo-template-a6>
      <promo-template-a7 v-show="false" :data="templateDataA7" ref="promoTemplateA7"></promo-template-a7>
      <promo-compare-template-a4
        v-show="false"
        :dataA4="templateCombinedDataA4"
        :listPrint="false"
        ref="promoCompareTemplateA4"
      ></promo-compare-template-a4>
      <promo-compare-template-a7
        v-show="false"
        :dataA7="templateCombinedDataA7"
        :listPrint="false"
        ref="promoCompareTemplateA7"
      ></promo-compare-template-a7>
      <promo-compare-template-a6
        v-show="false"
        :dataA6="templateCombinedDataA6"
        :listPrint="false"
        ref="promoCompareTemplateA6"
      ></promo-compare-template-a6>

      <div>
        <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"> </b-overlay>
        <b-form @submit.stop.prevent="onSubmit">
          <b-row>
            <b-col sm="12" lg="12" v-if="!isCompareDiv">
              <b-button
                :class="{
                  'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                  'btn btn-primary text-light mb-2 mr-2': isDarkMode
                }"
                @click="showUpload"
                ><font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload POP Articles</b-button
              >
              <b-button
                :class="{
                  'box-shadow2 btn btn-success ml-1 float-right': !isDarkMode,
                  'btn btn-primary text-light ml-1 float-right': isDarkMode
                }"
                @click="onManual('A7')"
                size="sm"
              >
                <font-awesome-icon :icon="['fas', 'print']" class="mr-1" />Print A7
              </b-button>
              <b-button
                :class="{
                  'box-shadow2 btn btn-success ml-1 float-right': !isDarkMode,
                  'btn btn-primary text-light ml-1 float-right': isDarkMode
                }"
                @click="onManual('A4')"
                size="sm"
              >
                <font-awesome-icon :icon="['fas', 'print']" class="mr-1" />Print A4
              </b-button>
            </b-col>
            <b-col sm="12" lg="12" v-if="isCompareDiv">
              <!--<b-button
                :class="{
                  'box-shadow2 btn btn-success mr-3 mb-2': !isDarkMode,
                  'btn btn-primary text-light mr-3 mb-2': isDarkMode
                }"
                @click="onManualCompare('A4compare')"
                size="sm"
              >
                <font-awesome-icon :icon="['fas', 'print']" class="mr-1" />Print A4 Comparison
              </b-button>
              <b-button
                :class="{
                  'box-shadow2 btn btn-success mb-2': !isDarkMode,
                  'btn btn-primary text-light mb-2': isDarkMode
                }"
                @click="onManualCompare('A7compare')"
                size="sm"
              >
                <font-awesome-icon :icon="['fas', 'print']" class="mr-1" />Print A7 Comparison
              </b-button> -->
              <b-button
                class=""
                @click="onManualCompare('A6compare')"
                size="sm"
                :variant="isDarkMode ? 'primary' : 'success'"
              >
                <font-awesome-icon :icon="['fas', 'print']" class="mr-1" />Print A6 Comparison
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="m-1" id="compareArticles" name="compareArticles">
                <label>
                  <b-form-checkbox
                    type="checkbox"
                    name="type"
                    id="compareCheckBox"
                    size="lg"
                    @change="isCompareDiv === false ? (isCompareDiv = true) : (isCompareDiv = false)"
                  >
                    Compare</b-form-checkbox
                  >
                </label>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="!isCompareDiv"
            :class="{ 'box-shadow2 mt-1 p-3': !isDarkMode, 'box-shadow2 mt-1 p-3 dark-div rounded': isDarkMode }"
          >
            <b-col>
              <b-row
                ><b-col
                  :class="{
                    'text-center p-2 page-title': !isDarkMode,
                    'text-center p-2 page-title bg-dark': isDarkMode
                  }"
                  >SPENCERS</b-col
                ></b-row
              >
              <b-row class="mt-3"
                ><b-col sm="12" lg="6" id="popPaperType" name="popPaperType">
                  <span> Select Type : </span> <span class="text-danger">*</span>
                  <label
                    :class="{ 'mx-3 box-shadow3 p-1': !isDarkMode, 'mx-3 box-shadow3 p-1 bg-dark rounded': isDarkMode }"
                  >
                    <input type="radio" v-model="popPaperType" name="type" value="SAVE" />
                    Save
                  </label>
                  <label :class="{ ' box-shadow3 p-1': !isDarkMode, ' box-shadow3 p-1 bg-dark rounded': isDarkMode }">
                    <input type="radio" v-model="popPaperType" name="type" value="PRICE" />
                    Price
                  </label>
                </b-col>
                <b-col id="popPaperType" name="popPaperType">
                  <span> Select Format : </span> <span class="text-danger">*</span>
                  <label
                    :class="{ 'ml-2 box-shadow3 p-1': !isDarkMode, 'ml-2 box-shadow3 p-1 bg-dark rounded': isDarkMode }"
                  >
                    <input type="radio" v-model="format" name="format" value="offer" />
                    Offer Price
                  </label>
                  <label
                    :class="{ 'ml-2 box-shadow3 p-1': !isDarkMode, 'ml-2 box-shadow3 p-1 bg-dark rounded': isDarkMode }"
                  >
                    <input type="radio" v-model="format" name="format" value="percentage" />
                    Percentage Off
                  </label>
                  <label
                    :class="{ 'ml-2 box-shadow3 p-1': !isDarkMode, 'ml-2 box-shadow3 p-1 bg-dark rounded': isDarkMode }"
                  >
                    <input type="radio" v-model="format" name="format" value="rupee" />
                    Rupee Off
                  </label>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col sm="12" lg="6">
                  <label> Enter Article Code : </label>
                  <span class="text-danger"> *</span>
                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    v-model="searchTerm"
                    @keydown.native="search"
                    placeholder="Search..."
                  ></b-form-input>
                  <b-form-text v-if="searchTerm != '' && searchTerm != null" id="input-role-help"
                    >Note: Press enter to search</b-form-text
                  >
                </b-col>
                <b-col>
                  <b-row class="mt-2">
                    <b-col sm="12" lg="12">
                      <span> {{ capitalizeFirstLetter(item.formatType) }} : </span> <span class="text-danger">*</span>
                      <b-input-group class="box-shadow3" v-if="format === 'offer' || format === 'rupee'" prepend="₹">
                        <b-form-input
                          :class="{
                            'box-shadow3 bg-light text-dark': !isDarkMode,
                            'bg-dark text-light placeholder-light': isDarkMode
                          }"
                          id="formatType"
                          type="number"
                          v-model="formatTypeValue"
                          placeholder="Enter value"
                        >
                        </b-form-input>
                      </b-input-group>
                      <b-input-group class="box-shadow3" v-if="format === 'percentage'" append="%">
                        <b-form-input
                          :class="{
                            'box-shadow3 bg-light text-dark': !isDarkMode,
                            'bg-dark text-light placeholder-light': isDarkMode
                          }"
                          id="formatType"
                          type="number"
                          v-model="formatTypeValue"
                          placeholder="Enter value"
                        >
                        </b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <b-form-group id="articleDescription" label-for="articleDescription">
                    <template v-slot:label>
                      DESCRIPTION
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="articleDescription"
                      type="text"
                      v-model="item.articleDescription"
                      placeholder="Description"
                      disabled
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="mrp" label-for="mrp">
                    MRP
                    <span class="text-danger">*</span>

                    <b-form-input
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      id="mrp"
                      type="number"
                      v-model="mrp"
                      placeholder="0"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="save" label-for="save">
                    {{ subTitle }}
                    <b-form-input
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      id="save"
                      type="number"
                      v-model="item.save"
                      placeholder="SAVE "
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- COMPARSION DIV -->
          <b-row
            v-if="isCompareDiv"
            :class="{ 'box-shadow2 mt-1 p-3': !isDarkMode, 'box-shadow2 mt-1 p-3 dark-div rounded': isDarkMode }"
          >
            <b-col sm="12" lg="6">
              <div
                :class="{
                  'text-center p-2 page-title': !isDarkMode,
                  'text-center p-2 page-title bg-dark': isDarkMode
                }"
                >SPENCERS</div
              >
              <b-row class="mt-2">
                <b-col>
                  <label> Enter Article Code : </label>
                  <span class="text-danger"> *</span>
                  <b-form-input
                    v-model="searchTermSpencers"
                    @keydown.native="searchSpencers"
                    placeholder="Search..."
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                  ></b-form-input>
                  <b-form-text
                    v-if="searchTermSpencers != '' && searchTermSpencers != null"
                    id="input-role-help-spencers"
                    >Note: Press enter to search</b-form-text
                  >
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group label-for="articleDescriptionSpencers">
                    <template v-slot:label>
                      DESCRIPTION
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="articleDescriptionSpencers"
                      type="text"
                      v-model="itemSpencers.articleDescription"
                      placeholder="Description"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label-for="mrpSpencers">
                    MRP
                    <span class="text-danger">*</span>

                    <b-form-input
                      id="mrpSpencers"
                      type="number"
                      v-model="mrpSpencers"
                      placeholder="0"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      @focus="isSpencersMrp = false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> Select Format : </span> <span class="text-danger">*</span>
                  <label class="mx-3">
                    <input type="radio" v-model="formatSpencers" name="format" value="offer" />
                    Offer Price
                  </label>
                  <label>
                    <input type="radio" v-model="formatSpencers" name="format" value="percentage" />
                    Percentage Off
                  </label>
                  <label class="mx-3">
                    <input type="radio" v-model="formatSpencers" name="format" value="rupee" />
                    Rupee Off
                  </label>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col id="offertypeSpencers" sm="12" lg="12">
                  <span> {{ capitalizeFirstLetter(itemSpencers.formatType) }} : </span>
                  <b-input-group
                    class="box-shadow3"
                    v-if="formatSpencers === 'offer' || formatSpencers === 'rupee'"
                    prepend="₹"
                  >
                    <b-form-input
                      id="formatTypeSpencers"
                      type="number"
                      v-model="formatSpencersValue"
                      :disabled="isSpencersMrp"
                      placeholder="Enter value"
                      :class="{
                        'bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    >
                    </b-form-input>
                  </b-input-group>
                  <b-input-group class="box-shadow3" v-if="formatSpencers === 'percentage'" append="%">
                    <b-form-input
                      id="formatTypeSpencers"
                      type="number"
                      v-model="formatSpencersValue"
                      :disabled="isSpencersMrp"
                      placeholder="Enter value"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group label-for="saveSpencers">
                    {{ subTitleSpencers }}
                    <b-form-input
                      id="saveSpencers"
                      type="number"
                      v-model="itemSpencers.save"
                      placeholder="SAVE"
                      disabled
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <div
                :class="{
                  'text-center p-2 page-title': !isDarkMode,
                  'text-center p-2 page-title bg-dark': isDarkMode
                }"
                >LEADING NATIONAL BRAND</div
              >
              <b-row class="mt-2">
                <b-col>
                  <label> Enter Article Code : </label>
                  <span class="text-danger"> *</span>
                  <b-form-input
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    v-model="searchTermLeadingBrand"
                    placeholder="Search..."
                    disabled
                  ></b-form-input>
                  <b-form-text
                    v-if="searchTermSpencers != '' && searchTermSpencers != null"
                    id="input-role-help-spencers"
                    >Note: Press enter to search</b-form-text
                  >
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group id="articleCompareDescription" label-for="articleCompareDescription">
                    <template v-slot:label>
                      DESCRIPTION
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="articleCompareDescription"
                      type="text"
                      v-model="itemLeadingBrand.articleDescription"
                      placeholder="Description"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="mrpCompare" label-for="mrpCompare">
                    MRP
                    <span class="text-danger">*</span>

                    <b-form-input
                      id="mrpCompare"
                      type="number"
                      v-model="mrpLeadingBrand"
                      placeholder="0"
                      @focus="isLeadingBrandMrp = false"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col id="popPaperTypeCompare" name="popPaperTypeCompare">
                  <span> Select Format : </span> <span class="text-danger">*</span>
                  <label class="mx-3">
                    <input type="radio" v-model="formatLeadingBrand" name="formatLeadingBrand" value="offer" />
                    Offer Price
                  </label>
                  <label>
                    <input type="radio" v-model="formatLeadingBrand" name="formatLeadingBrand" value="percentage" />
                    Percentage Off
                  </label>
                  <label class="mx-3">
                    <input type="radio" v-model="formatLeadingBrand" name="formatLeadingBrand" value="rupee" />
                    Rupee Off
                  </label>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col id="offertypeSpencers" sm="12" lg="12">
                  <span> {{ capitalizeFirstLetter(formatLeadingBrand) }} : </span>
                  <b-input-group
                    class="box-shadow3"
                    v-if="formatLeadingBrand === 'offer' || formatLeadingBrand === 'rupee'"
                    prepend="₹"
                  >
                    <b-form-input
                      id="formatCompareType"
                      type="number"
                      v-model="formatLeadingBrandValue"
                      :disabled="isLeadingBrandMrp"
                      placeholder="Enter value"
                      :class="{
                        'bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    >
                    </b-form-input>
                  </b-input-group>
                  <b-input-group class="box-shadow3" v-if="formatLeadingBrand === 'percentage'" append="%">
                    <b-form-input
                      id="formatCompareType"
                      type="number"
                      v-model="formatLeadingBrandValue"
                      :disabled="isLeadingBrandMrp"
                      placeholder="Enter value"
                      :class="{
                        'bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group id="saveCompare" label-for="saveCompare">
                    {{ subTitleLeadingBrand }}
                    <b-form-input
                      id="saveCompare"
                      type="number"
                      v-model="itemLeadingBrand.save"
                      placeholder="SAVE "
                      disabled
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div v-if="isCompareDiv" class="mx-1">
      <br />
      <PopManualBox
        :columnsCompare="PopCompareArticlesColumns"
        :rowsCompare="PopCompareArticles"
        printTextA4Compare="Print A4"
        printTextA7Compare="Print A7"
        printTextA6Compare="Print A6"
        :isComparison="true"
      >
      </PopManualBox>
    </div>
    <div class="mx-1" v-if="customLocalPopListModified.length > 0">
      <br />
      <PopManualBox
        :columns="columns"
        :rows="customLocalPopListModified"
        printTextA4="Print A4"
        printTextA7="Print A7"
        printTextA6="Print A6"
        @update="onUpdate"
        @clearList="onClearList"
        :isComparison="false"
      >
      </PopManualBox>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import csv from 'csvtojson';
import PromoTemplateA4 from '@/components/PromoTemplateA4.vue';
import PromoTemplateA7 from '@/components/PromoTemplateA7.vue';
import PromoTemplateA6 from '@/components/PromoTemplateA6.vue';
import PromoCompareTemplateA4 from '@/components/PromoCompareTemplateA4.vue';
import PromoCompareTemplateA6 from '@/components/PromoCompareTemplateA6.vue';
import PromoCompareTemplateA7 from '@/components/PromoCompareTemplateA7.vue';
import PopManualBox from '@/components/PopManualBox.vue';
import PrintTemplate from '@/helper/printTemplateHelper';
import PrintCompareTemplate from '@/helper/printCompareTemplateHelper';
import { popCompareArticles, popCompareArticlesColumns } from '@/helper/popCompareArticles';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'PopManualForm',
  components: {
    PromoTemplateA7,
    PromoTemplateA6,
    PromoTemplateA4,
    PromoCompareTemplateA4,
    PromoCompareTemplateA7,
    PromoCompareTemplateA6,

    PopManualBox
  },

  data() {
    return {
      title: 'Pop Manual Form',
      titleComparison: 'Pop Compare Form',
      subTitle: 'Save',
      subTitleSpencers: 'Save',
      subTitleLeadingBrand: 'Save',
      templateDataA7: [],
      templateDataA6: [],
      templateDataA4: [],
      templateCombinedDataA7: [],
      templateCombinedDataA6: [],
      templateCombinedDataA4: [],
      format: 'offer',
      popPaperType: 'SAVE',
      formatTypeValue: '',
      mrp: '',
      formatSpencers: 'offer',
      formatSpencersValue: '',
      mrpSpencers: '',
      formatLeadingBrand: 'offer',
      formatLeadingBrandValue: '',
      mrpLeadingBrand: '',
      loading: false,
      PopCompareArticles: popCompareArticles,
      PopCompareArticlesColumns: popCompareArticlesColumns,
      assignedStore: [],
      item: {
        offerPrice: 0,
        percentageOff: 0,
        rupeesOff: 0,
        family: '',
        articleDescription: '',
        formatType: 'offer',
        save: 0,
        validTo: null,
        pop1: '',
        brick: '',
        differentFamily: ['FISH & MEAT', 'FRESH VEGETABLE', 'FRESH FRUIT', 'EGG'],
        isSave3Family: false
      },
      itemSpencers: {
        offerPrice: 0,
        percentageOff: 0,
        rupeesOff: 0,
        articleDescription: '',
        save: 0,
        formatType: 'offer'
      },
      itemLeadingBrand: {
        offerPrice: 0,
        percentageOff: 0,
        rupeesOff: 0,
        articleDescription: '',
        save: 0,
        formatType: 'offer'
      },
      inputs: [],
      searchTerm: null,
      searchTermSpencers: null,
      searchTermLeadingBrand: null,
      isCompareDiv: false,
      isLeadingBrandMrp: true,
      isSpencersMrp: true,
      filterOptions: [
        {
          value: null,
          text: 'Select Filter'
        },
        {
          value: 'FISH & MEAT',
          text: 'FISH & MEAT'
        },
        {
          value: 'FRESH VEGETABLE',
          text: 'FRESH VEGETABLE'
        },
        {
          value: 'FRESH FRUIT',
          text: 'FRESH FRUIT'
        },
        {
          value: 'EGG',
          text: 'EGG'
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: {
            'text-center': true
          },
          textKey: 'index',
          width: '3%'
        },
        {
          type: 'row_num',
          headerText: 'Select',
          class: {
            'text-center': true
          },
          textKey: 'select',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Family Description',
          textKey: 'family',
          class: {
            'text-center': true
          },
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Brick Description',
          textKey: 'brick',
          class: {
            'text-center': true
          },
          width: '9%'
        },
        {
          type: 'string',
          headerText: ' P Article code',
          textKey: 'articleCode',
          class: {
            'text-center': true
          },
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Parent Article Description',
          textKey: 'articleDescription',
          class: {
            'text-center': true
          },
          width: '14%'
        },

        {
          type: 'string',
          headerText: 'P Article MRP',
          textKey: 'mrp',
          class: {
            'text-center': true
          },
          width: '6%'
        },

        {
          type: 'string',
          headerText: 'Offer Price',
          textKey: 'offerPrice',
          class: {
            'text-center': true
          },
          width: '5%'
        },
        {
          type: 'string',
          headerText: '% Off',
          textKey: 'percentageOff',
          class: {
            'text-center': true
          },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Rs Off',
          textKey: 'rupeesOff',
          class: {
            'text-center': true
          },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Offer Communication',
          textKey: 'pop1',
          class: {
            'text-center': true
          },
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Valid To',
          textKey: 'validTo',
          class: {
            'text-center': true
          },
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Pop Type (Save/our Price)',
          textKey: 'popPaperType',
          class: {
            'text-center': true
          },
          width: '8%'
        }
      ]
    };
  },
  mounted() {
    this.restoreCustomLocalPopList();
    this.getStoreByUser();
  },
  computed: {
    ...mapState('popPrint', [
      'baseUrl',
      'popLists',
      'pagination',
      'popListsCompare',
      'paginationCompare',
      'customLocalPopList'
    ]),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    customLocalPopListModified() {
      if (this.customLocalPopList.length) {
        return this.customLocalPopList.map((item, index) => ({
          ...item,
          index: index + 1
        }));
      }
      return [];
    },
    metaDescription() {
      return 'Manual POP Print';
    }
  },
  watch: {
    popLists(newValue) {
      const filteredArrayOfItem = newValue
        .filter(item => item.delta !== '')
        .sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
      if (filteredArrayOfItem.length > 0) {
        this.loading = false;
        this.mrp = filteredArrayOfItem[0].mrp;
        this.item.articleDescription = filteredArrayOfItem[0].articleDescription;
        this.item.family = filteredArrayOfItem[0].family;
        this.item.pop1 = filteredArrayOfItem[0].pop1;
      }
    },
    format(newValue, oldValue) {
      this.item.formatType = newValue;
      if (newValue !== oldValue) {
        this.formatTypeValue = '';
        this.item.save = 0;
      }
      if (newValue === 'offer') {
        this.subTitle = 'Save';
      } else {
        this.subTitle = 'Our Price';
      }
    },
    formatSpencers(newValue, oldValue) {
      this.itemSpencers.formatType = newValue;
      if (newValue !== oldValue) {
        this.formatSpencersValue = '';
        this.itemSpencers.save = 0;
      }
      if (newValue === 'offer') {
        this.subTitleSpencers = 'Save';
      } else {
        this.subTitleSpencers = 'Our Price';
      }
    },
    formatLeadingBrand(newValue, oldValue) {
      this.itemLeadingBrand.formatType = newValue;
      if (newValue !== oldValue) {
        this.formatLeadingBrandValue = '';
        this.itemLeadingBrand.save = 0;
      }
      if (newValue === 'offer') {
        this.subTitleLeadingBrand = 'Save';
      } else {
        this.subTitleLeadingBrand = 'Our Price';
      }
    },
    popPaperType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.item.offerPrice = '';
        this.item.percentageOff = '';
        this.item.rupeesOff = '';
        this.item.formatType = 'offer';
        this.item.save = 0;
        this.templateDataA7 = [];
        this.templateDataA6 = [];
        this.templateDataA4 = [];
        this.format = 'offer';
        this.formatTypeValue = '';
      }
    },
    formatTypeValue(newValue) {
      const value = Number(newValue);
      if (this.format === 'offer') {
        this.item.offerPrice = value;
        this.item.percentageOff = '';
        this.item.rupeesOff = '';
        this.item.save = this.mrp - value;
      } else if (this.format === 'percentage') {
        this.item.offerPrice = '';
        this.item.percentageOff = value;
        this.item.rupeesOff = '';
        const tempSave = this.mrp * ((100 - value) / 100);
        this.item.save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
      } else {
        this.item.offerPrice = '';
        this.item.rupeesOff = value;
        this.item.percentageOff = '';
        this.item.save = this.mrp - value;
      }
    },
    formatSpencersValue(newValue) {
      const value = Number(newValue);
      if (this.formatSpencers === 'offer') {
        this.itemSpencers.offerPrice = value;
        this.itemSpencers.percentageOff = '';
        this.itemSpencers.rupeesOff = '';
        this.itemSpencers.save = this.mrpSpencers - value;
      } else if (this.formatSpencers === 'percentage') {
        this.itemSpencers.offerPrice = '';
        this.itemSpencers.percentageOff = value;
        this.itemSpencers.rupeesOff = '';
        const tempSave = this.mrpSpencers * ((100 - value) / 100);
        this.itemSpencers.save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
      } else {
        this.itemSpencers.offerPrice = '';
        this.itemSpencers.rupeesOff = value;
        this.itemSpencers.percentageOff = '';
        this.itemSpencers.save = this.mrpSpencers - value;
      }
    },
    formatLeadingBrandValue(newValue) {
      const value = Number(newValue);
      if (this.formatLeadingBrand === 'offer') {
        this.itemLeadingBrand.offerPrice = value;
        this.itemLeadingBrand.percentageOff = '';
        this.itemLeadingBrand.rupeesOff = '';
        this.itemLeadingBrand.save = this.mrpLeadingBrand - value;
      } else if (this.formatLeadingBrand === 'percentage') {
        this.itemLeadingBrand.offerPrice = '';
        this.itemLeadingBrand.percentageOff = value;
        this.itemLeadingBrand.rupeesOff = '';
        const tempSave = this.mrpLeadingBrand * ((100 - value) / 100);
        this.itemLeadingBrand.save = Number.isInteger(tempSave) ? tempSave.toFixed(0) : tempSave.toFixed(2);
      } else {
        this.itemLeadingBrand.offerPrice = '';
        this.itemLeadingBrand.rupeesOff = value;
        this.itemLeadingBrand.percentageOff = '';
        this.itemLeadingBrand.save = this.mrpLeadingBrand - value;
      }
    }
  },
  methods: {
    ...mapActions('popPrint', [
      'listPop',
      'report',
      'searchByArticle',
      'setCustomLocalPopList',
      'removeCustomLocalPopList',
      'updateCustomLocalPopList',
      'restoreCustomLocalPopList'
    ]),
    ...mapActions('report', ['upload']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    onClearList() {
      this.removeCustomLocalPopList();
    },
    createCsvForBulkUpload() {
      const csv = `Family Description,Brick Description, P Article code,Parent Article description,P Article MRP,Offer Price,Percentage off,Rupee off,Offer Communication,Valid To(MM-DD-YYYY),PopType(Save/our Price)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'PopArticle.csv';
      anchor.click();
    },
    onUpdate({ articleList }) {
      this.updateCustomLocalPopList({
        articleList
      });
    },
    search(event) {
      if (event.key === 'Enter') {
        this.loading = true;
        this.searchByArticle({
          articleCode: this.searchTerm
        });
      }
    },
    searchSpencers(event) {
      if (event.key === 'Enter') {
        const storedPopCompareArticles = this.PopCompareArticles;
        const searchTerm = parseInt(this.searchTermSpencers);
        const foundArticle = storedPopCompareArticles.find(article => article.spencersArticleCode === searchTerm);
        if (!foundArticle) {
          this.loading = true;
        } else {
          this.loading = false;
          this.itemSpencers.articleDescription = foundArticle.spencersArticleDescription;
          this.itemLeadingBrand.articleDescription = foundArticle.leadingBrandArticleDescription;
          this.searchTermLeadingBrand = foundArticle.leadingBrandArticleCode;
        }
      }
    },
    async showUpload() {
      await Vue.swal({
        title: 'Upload POP Articles',
        text: 'Please select the file to upload',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your POP Articles file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsvForBulkUpload();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                let uploadingArticleList;
                let isUploadingArticleList = true;
                if (csvRow.length > 1 && csvRow.length <= 151) {
                  uploadingArticleList = csvRow.slice(1, csvRow.length).map((item, index) => {
                    const popType = item[10].toUpperCase();

                    if (!item[3]) {
                      Vue.swal({
                        title: 'Description is missing',
                        text: `Description must be provided`,
                        type: 'error',
                        confirmButtonText: 'Ok',
                        background: this.isDarkMode ? '#464d54' : '#ffffff',
                        customClass: {
                          title: this.isDarkMode ? 'text-light' : 'text-dark',
                          content: this.isDarkMode ? 'text-light' : 'text-dark'
                        }
                      });
                      isUploadingArticleList = false;
                      return null;
                    }
                    if (!item[4]) {
                      Vue.swal({
                        title: 'MRP is missing',
                        text: 'MRP must be provided',
                        type: 'error',
                        confirmButtonText: 'Ok',
                        background: this.isDarkMode ? '#464d54' : '#ffffff',
                        customClass: {
                          title: this.isDarkMode ? 'text-light' : 'text-dark',
                          content: this.isDarkMode ? 'text-light' : 'text-dark'
                        }
                      });
                      isUploadingArticleList = false;
                      return null;
                    }
                    if (!popType) {
                      Vue.swal({
                        title: 'POP Type field is empty',
                        text: `Please Fill POP Types with 'SAVE' or 'OUR PRICE'`,
                        type: 'error',
                        confirmButtonText: 'Ok',
                        background: this.isDarkMode ? '#464d54' : '#ffffff',
                        customClass: {
                          title: this.isDarkMode ? 'text-light' : 'text-dark',
                          content: this.isDarkMode ? 'text-light' : 'text-dark'
                        }
                      });
                      isUploadingArticleList = false;
                      return null;
                    }
                    // if (!popType.includes('SAVE') && !popType.includes('OUR PRICE')) {
                    //   Vue.swal({
                    //     title: 'Invalid POP Type',
                    //     text: `Valid POP Types are 'SAVE' or 'OUR PRICE'`,
                    //     type: 'error',
                    //     confirmButtonText: 'Ok',
                    // background: this.isDarkMode ? '#464d54' : '#ffffff',
                    // customClass: {
                    //   title: this.isDarkMode ? 'text-light' : 'text-dark',
                    //   content: this.isDarkMode ? 'text-light' : 'text-dark'
                    // }
                    //   });
                    //   isUploadingArticleList = false;
                    //   return null;
                    // }
                    return {
                      index: index + 1,
                      family: item[0],
                      brick: item[1],
                      articleCode: item[2],
                      articleDescription: item[3],
                      mrp: parseFloat(item[4].replaceAll(',', '')),
                      offerPrice: parseFloat(item[5].replaceAll(',', '')),
                      percentageOff: item[6],
                      rupeesOff: parseFloat(item[7].replaceAll(',', '')),
                      pop1: item[8],
                      validTo: item[9].replaceAll('.', '-'),
                      popPaperType: popType
                    };
                  });
                } else {
                  Vue.swal({
                    title: 'Limit of items are not matched',
                    text: `Please upload items between 1 to 150`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  isUploadingArticleList = false;
                  return null;
                }
                if (isUploadingArticleList) {
                  this.setCustomLocalPopList({
                    printList: uploadingArticleList
                  });
                } else {
                  this.removeCustomLocalPopList();
                }
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    capitalizeFirstLetter(inputString) {
      if (typeof inputString !== 'string' || inputString.length === 0) {
        return inputString;
      }

      return inputString[0].toUpperCase() + inputString.slice(1);
    },
    async onManual(printType) {
      const manualArrayOfArticle = [
        {
          index: '1',
          mrp: this.mrp,
          offerPrice: this.item.offerPrice,
          percentageOff: this.item.percentageOff,
          rupeesOff: this.item.rupeesOff,
          articleDescription: this.item.articleDescription,
          family: this.item.family !== null ? this.item.family : '',
          popPaperType: this.popPaperType,
          pop1: this.item.pop1,
          offerCommunication: this.item.pop1
        }
      ];
      let message = '';
      if (manualArrayOfArticle[0].mrp === '') {
        message = 'Please provide article MRP';
      } else if (manualArrayOfArticle[0].articleDescription === '') {
        message = 'Please provide a description';
      }
      if (manualArrayOfArticle.length > 0 && message !== '') {
        Vue.swal({
          title: 'Access Denied',
          text: `${message}`,
          type: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        message = '';
        return null;
      }
      if (printType === 'A7') {
        await Vue.swal({
          title: 'Number of copies you want',
          showCancelButton: true,
          input: 'number',
          inputPlaceholder: 'Number of copies',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Print',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.isDismissed) {
            return null;
          }
          if (result.value) {
            const numberOfCopies = Number(result.value);
            if (!numberOfCopies) {
              Vue.swal({
                title: 'Access Denied',
                text: `Please enter a number`,
                type: 'error',
                confirmButtonText: 'Ok',
                background: this.isDarkMode ? '#464d54' : '#ffffff',
                customClass: {
                  title: this.isDarkMode ? 'text-light' : 'text-dark',
                  content: this.isDarkMode ? 'text-light' : 'text-dark'
                }
              });
              return null;
            }

            const arrOfArticlesToPrint = manualArrayOfArticle
              .map(item => {
                return Array(numberOfCopies).fill(item);
              })
              .flat();

            this.templateDataA7 = PrintTemplate.divideArrInSubArr(arrOfArticlesToPrint);
            this.printCommand(printType);
          }
        });
      } else if (printType === 'A6') {
        await Vue.swal({
          title: 'Number of copies you want',
          showCancelButton: true,
          input: 'number',
          inputPlaceholder: 'Number of copies',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Print',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.isDismissed) {
            return null;
          }
          if (result.value) {
            const numberOfCopies = Number(result.value);
            if (!numberOfCopies) {
              Vue.swal({
                title: 'Access Denied',
                text: `Please enter a number`,
                type: 'error',
                confirmButtonText: 'Ok',
                background: this.isDarkMode ? '#464d54' : '#ffffff',
                customClass: {
                  title: this.isDarkMode ? 'text-light' : 'text-dark',
                  content: this.isDarkMode ? 'text-light' : 'text-dark'
                }
              });
              return null;
            }

            const arrOfArticlesToPrint = manualArrayOfArticle
              .map(item => {
                return Array(numberOfCopies).fill(item);
              })
              .flat();

            this.templateDataA6 = PrintTemplate.divideArrInSubArr(arrOfArticlesToPrint);
            this.printCommand(printType);
          }
        });
      } else {
        this.templateDataA4 = manualArrayOfArticle;
        this.printCommand(printType);
      }
    },
    printCommand(printType) {
      const stylesHtml =
        printType === 'A7' ? PrintTemplate.getTemplatePrintCss('A7') : PrintTemplate.getTemplatePrintCss('A4');
      setTimeout(() => {}, 2000);
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      setTimeout(() => {
        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
            </head>
            <body>
              ${
                printType === 'A7' ? this.$refs.promoTemplateA7.$el.innerHTML : this.$refs.promoTemplateA4.$el.innerHTML
              }
            </body>
          </html>`);
      }, 1000);
      setTimeout(() => {
        WinPrint.document.close();
      }, 1000);
      setTimeout(() => {
        WinPrint.focus();
      }, 1000);
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 2000);
    },
    async onManualCompare(printTypeCompare) {
      const manualArrayOfArticleCompare = [
        {
          index: '1',
          mrp: this.mrpSpencers,
          articleDescription: this.itemSpencers.articleDescription,
          finalPriceSpencer:
            this.itemSpencers.offerPrice > 0
              ? this.itemSpencers.offerPrice
              : this.itemSpencers.percentageOff > 0
              ? this.itemSpencers.save
              : this.itemSpencers.rupeesOff
              ? this.itemSpencers.save
              : this.mrpSpencers,

          mrpCompare: this.mrpLeadingBrand,
          articleDescriptionCompare: this.itemLeadingBrand.articleDescription,
          finalPriceBrand:
            this.itemLeadingBrand.offerPrice > 0
              ? this.itemLeadingBrand.offerPrice
              : this.itemLeadingBrand.percentageOff > 0
              ? this.itemLeadingBrand.save
              : this.itemLeadingBrand.rupeesOff
              ? this.itemLeadingBrand.save
              : this.mrpLeadingBrand
        }
      ];
      let messageCompare = '';
      if (manualArrayOfArticleCompare[0].mrp === '' || manualArrayOfArticleCompare[0].mrpCompare === '') {
        messageCompare = 'Please provide article MRP';
      }
      if (
        manualArrayOfArticleCompare[0].articleDescription === '' ||
        manualArrayOfArticleCompare[0].articleDescriptionCompare === ''
      ) {
        messageCompare = 'Please provide article description';
      }

      if (
        Number(manualArrayOfArticleCompare[0].finalPriceSpencer) >
        Number(manualArrayOfArticleCompare[0].finalPriceBrand)
      ) {
        messageCompare = 'Spencers Selling Price must be Lesser than Leading Brand Selling Price';
      }

      if (manualArrayOfArticleCompare.length > 0 && messageCompare !== '') {
        Vue.swal({
          title: 'Access Denied',
          text: `${messageCompare}`,
          type: 'error',
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark',
            color: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        messageCompare = '';
        return null;
      }
      if (printTypeCompare === 'A6compare') {
        await Vue.swal({
          title: 'Number of copies you want',
          showCancelButton: true,
          input: 'text',
          inputPlaceholder: 'Number of copies',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Print',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark',
            input: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.isDismissed) {
            return null;
          }
          if (result.value) {
            const numberOfCopies = Number(result.value);
            if (!numberOfCopies) {
              Vue.swal({
                title: 'Access Denied',
                text: `Please enter a number`,
                type: 'error',
                confirmButtonText: 'Ok',
                background: this.isDarkMode ? '#464d54' : '#ffffff',
                customClass: {
                  title: this.isDarkMode ? 'text-light' : 'text-dark',
                  content: this.isDarkMode ? 'text-light' : 'text-dark'
                }
              });
              return null;
            }
            const arrayOfArticlesToPrint = manualArrayOfArticleCompare
              .map(item => {
                return Array(numberOfCopies).fill(item);
              })
              .flat();
            this.templateCombinedDataA6 = PrintCompareTemplate.divideArrInSubArr(arrayOfArticlesToPrint);
            this.printCommandCompare(printTypeCompare);
          }
        });
      }
      if (printTypeCompare === 'A7compare') {
        await Vue.swal({
          title: 'Number of copies you want',
          showCancelButton: true,
          input: 'text',
          inputPlaceholder: 'Number of copies',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Print',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        }).then(result => {
          if (result.isDismissed) {
            return null;
          }
          if (result.value) {
            const numberOfCopies = Number(result.value);
            if (!numberOfCopies) {
              Vue.swal({
                title: 'Access Denied',
                text: `Please enter a number`,
                type: 'error',
                confirmButtonText: 'Ok',
                background: this.isDarkMode ? '#464d54' : '#ffffff',
                customClass: {
                  title: this.isDarkMode ? 'text-light' : 'text-dark',
                  content: this.isDarkMode ? 'text-light' : 'text-dark'
                }
              });
              return null;
            }
            const arrayOfArticlesToPrint = manualArrayOfArticleCompare
              .map(item => {
                return Array(numberOfCopies).fill(item);
              })
              .flat();
            this.templateCombinedDataA7 = PrintCompareTemplate.divideArrInSubArr(arrayOfArticlesToPrint);
            this.printCommandCompare(printTypeCompare);
          }
        });
      } else {
        this.templateCombinedDataA4 = manualArrayOfArticleCompare;
        this.printCommandCompare(printTypeCompare);
      }
    },
    printCommandCompare(printTypeCompare) {
      const stylesHtml =
        printTypeCompare === 'A7compare'
          ? PrintCompareTemplate.getTemplatePrintCss('A7compare')
          : printTypeCompare === 'A6compare'
          ? PrintCompareTemplate.getTemplatePrintCss('A6compare')
          : PrintCompareTemplate.getTemplatePrintCss('A4compare');
      setTimeout(() => {}, 2000);
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      setTimeout(() => {
        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
            </head>
            <body>
              ${
                printTypeCompare === 'A7compare'
                  ? this.$refs.promoCompareTemplateA7.$el.innerHTML
                  : printTypeCompare === 'A6compare'
                  ? this.$refs.promoCompareTemplateA6.$el.innerHTML
                  : this.$refs.promoCompareTemplateA4.$el.innerHTML
              }
            </body>
          </html>`);
      }, 1000);
      setTimeout(() => {
        WinPrint.document.close();
      }, 1000);
      setTimeout(() => {
        WinPrint.focus();
      }, 1000);
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 2000);
    }
  }
};
</script>

<style>
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.custom-width {
  max-width: 1200px;
  margin: 0 auto 10px auto;
}
</style>
